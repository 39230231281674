<template>
  <v-menu
    :return-value="stockage_id"
    :open-on-hover="true"
    :close-on-click="true"
    :close-on-content-click="true"
    :offset="0"
      v-model='isOpened'
  >
    <template v-slot:activator="{props}">
      <v-btn
        :x-small="smAndDown"
        color="primary"
        dark
        v-bind="props"
      >
        <div>
          {{ currentStockage }}
        </div>
        <v-progress-circular
          class="ml-2"
          v-show="loading_"
          :indeterminate="true"
        ></v-progress-circular>
        <v-icon>mdi-menu-down</v-icon>
      </v-btn>
    </template>

    <v-card scrollable>
      <v-card-text style="max-height: 50vh; overflow-y: scroll">
        <v-list>
          <v-list-item
            v-for="item in stockage_items"
            :key="item.item_id"
            @click="chooseStockage(item)"
          >
            <v-list-item-title>{{ item.full_name }}</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-card-text>
    </v-card>
  </v-menu>
</template>

<script>
import { isNone } from "@/functions.js";
import { useDisplay } from "vuetify";
import { mapState } from "vuex";

export default {
  props: {
    stockage_id: { type: [Number, String], required: false, default: "" },
    producer_type_id: { type: [Number, String], required: false, default: "" },
    loading: { type: Boolean, required: false, default: false },
  },
  computed: {
    smAndDown(){ return useDisplay().smAndDown.value},
    ...mapState({
      stockage_items: state => state.stockages.x_prod_type
    })
  },

  methods: {
    updateCurrentStockage(stockage_id, producer_type_id) {
      // update selected stockage/producer type label
      let index = this.stockage_items.findIndex(
        (i) =>
          i.stockage_id === stockage_id &&
          i.producer_type_id === producer_type_id,
      );
      if (index !== -1) {
        this.currentStockage = this.stockage_items[index].full_name;
      } else {
        this.currentStockage = this.$t("actions.choose_stock");
      }
    },

    chooseStockage(select) {
      // on select
      this.updateCurrentStockage(select.stockage_id, select.producer_type_id);
      this.$emit("chooseStock", {
        stockage_id: select.stockage_id,
        producer_type_id: select.producer_type_id,
      });
    },
    loadStockagesIfEmpty() {
      if (this.stockage_items?.length == 0) {
        this.$store.dispatch("stockages/getXProdType").then (()=> {
            this.updateCurrentStockage(this.stockage_id, this.producer_type_id);
        })
      }
    },
  },

  mounted() {
    this.loading_ = this.loading;
    this.loadStockagesIfEmpty();
    if (!isNone(this.stockage_id) && !isNone(this.producer_type_id)) {
      this.updateCurrentStockage(this.stockage_id, this.producer_type_id);
    }
  },

  watch: {
    loading(newValue) {
      // changed in parent
      // https://stackoverflow.com/questions/46425633/how-to-send-updated-values-from-parent-component-to-child-component-in-vue-js
      this.loading_ = newValue;
    },
    isOpened(val) {
      if (val) this.loadStockagesIfEmpty();
    },
  },

  data() {
    return {
      moduleName: "stockages",
      // stockage_items: [],
      loading_: false,
      isOpened: false,
      currentStockage: this.$t("actions.choose_stock"),
    };
  },
};
</script>
