import crud from "./crud";
import crud_service from "@/services/crud";

const path = "verification_dones";
const struct = {
  verif_id: '',
  producer_id: undefined,
  comment: '',
  partners_ids: [],
  done_at: '',
  keep_info_after_closure: false,
  intervention_report_id: undefined,
  closure_report_id: undefined,
};

const generic = crud(path, struct);

const state = {
  ...generic.state,
};

const getters = {
  ...generic.getters,
};

const mutations = {
  ...generic.mutations,
};

const actions = {
  ...generic.actions,
  assignIntRep({ commit }, payload) {
    // payload has { verif_list, int_rep_id}
    return new Promise((resolve, reject) => {
      crud_service.post(`${path}/assignIntRep`, payload)
        .then((resp) => {
          resolve(resp)
        })
        .catch((error) => {
          reject(error)
        })
    })
  }, 
  getHistory({ commit }, payload){
    commit("setIsLoading", true);
    return new Promise((resolve, reject) => {
      crud_service
        .fetch(`${path}/getHistory`, payload)
        .then((items) => {
          commit("setItems", items);
          commit("setIsLoading", false);
          resolve(items);
        })
        .catch(() => {
          commit("setIsLoading", false);
          reject();
        });
    });
  },
  clearComment({ commit}, payload){
    return new Promise((resolve, reject) => {
      crud_service
        .post(`${path}/clearComment`, payload)
        .then((response) => {
          resolve(response)
        })
    })
      .catch(()=>{
        reject()
      });
  },
  editMultiple({ commit }, payload){
    return new Promise((resolve, reject) => {
      crud_service
        .post(`${path}/editMultiple`, payload)
        .then((response) => {
          resolve(response)
        })
    })
      .catch(()=>{
        reject()
      });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
