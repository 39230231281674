import crud from "./crud";
import crud_service from "@/services/crud";
import { isNone } from "@/functions";

const path = "appointments";
const struct = {
  name: "",
  description: "",
  ilk: "",
  start: "",
  end: "",
  is_locked: "",
  is_remote: null,
  user_id: null,
  producer_id: null,
};
const generic = crud(path, struct);

const state = {
  ...generic.state,
  schedules: {},
  user_stats: {
    days: [],
    number_of_days: undefined,
    sat_triggers: [],
    sat_triggers_total: 0,
    sun_triggers: [],
    sun_triggers_total: 0,
  },
  user_stats_state: false,
};

const getters = {
  ...generic.getters,

  calculateDaysRemaining: () => (start) => {
    const today = new Date();
    const startDate = new Date(start);
    startDate.setDate(startDate.getDate() + 15);

    return startDate < today ? "Past Due" : Math.ceil((startDate - today) / (1000 * 60 * 60 * 24));
  },
};

const mutations = {
  ...generic.mutations,

  setUserStats(state, stats) {
    state.user_stats = stats;
  },
  setUserStatsState(state, value) {
    state.user_stats_state = value;
  },
};

const actions = {
  ...generic.actions,

  getUserStats({ commit }) {
    return new Promise((resolve) => {
      crud_service
        .fetch(`${path}/user_stats`)
        .then((item) => {
          if (isNone(item)) {
            commit("setUserStatsState", false);
          } else {
            commit("setUserStats", item);
            commit("setUserStatsState", true);
          }
          resolve();
        })
        .catch(() => {});
    });
  },

  getDispoAnticipatedRest({ commit }, payload) {
    return new Promise((resolve, reject) => {
      crud_service
      .fetch(`${path}/get_dispo_anticipated_rest`, payload)
      .then((item) => {
        resolve(item);
      })
      .catch((error) => {
        reject(error);
      });
    });
  },
  getPendingRest({ commit }, payload) {
    return new Promise((resolve, reject) => {
      crud_service
      .fetch(`${path}/get_pending_rest`, payload)
      .then((item) => {
        resolve(item);
      })
      .catch((error) => {
        reject(error);
      });
    });
  },
  addPendingRest({ commit }, payload) {
    return new Promise((resolve, reject) => {
      crud_service
        .post(`${path}/add_pending_rest`, payload)
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
