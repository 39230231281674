<template>
  <v-container class='mb-5'>
    <v-toolbar-title style="font-weight: bolder" class="ml-2 mt-2">{{$filters.capitalize(
      $t(page_title, 10, { target: this.entity_name }))
      }} {{this.entity_name}}</v-toolbar-title>
    <v-row align="center" justify="center">
      <v-col cols="12" md="6">
        <v-table class="my-2">
          <template v-slot:default>
            <thead>
              <tr>
                <!--- what field for the other types? --->
                <th class="text-left">
                  {{ $filters.capitalize($t("preventive_maintenances.period.label") ) }}
                </th>
                <th class="text-left">
                  {{ $filters.capitalize($t("preventive_maintenances.done_at") ) }}
                </th>
                <th class="text-left">
                  {{ $filters.capitalize($t("preventive_maintenances.report") ) }}
                </th>
                <th class="text-left">
                  {{ $filters.capitalize($t("actions.label") ) }}
                </th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="maint in maintenances" :key="maint.pk">
                <!--- what field for the other types? --->
                <td>{{$filters.capitalize($filters.formatPeriod( maint.period ) ) }}</td>
                <td>{{$filters.formatDate( maint.done_at ) }}</td>
                <td>
                  <v-tooltip location="top" :text= "$filters.capitalize($t('actions.download') )">
                    <template v-slot:activator="{ props }">
                      <v-icon
                          v-show="maint.report !== null"
                          v-bind="props"
                          class="mr-auto"
                          @click="openLink(maint.report)"
                          >mdi-file-outline</v-icon
                        >
                    </template>
                  </v-tooltip>
                </td>
                <td>
                  <v-tooltip location="top" :text="$filters.capitalize($t('actions.delete') )">
                    <template v-slot:activator="{ props }">
                      <v-icon
                          v-bind="props"
                          class="mr-2"
                          @click="deleteItemBtn(maint)"
                          >mdi-delete</v-icon>
                    </template>
                  </v-tooltip>
                </td>
              </tr>
            </tbody>
          </template>
        </v-table>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  import { alertPopup } from "@/functions";

  export default {
    mounted() {
      // different route for others?
      this.$store
        .dispatch(`${this.module_name}/fromPowerStation`, this.entity_id)
        .then((items) => {
          this.maintenances = items;
        });
    }, 
    props: {
      // entity being power_station for regulatory or producer for the rest
      entity_id: {type: Number, required : true},
      entity_name: {type: String, required : true},
      //    report_type: {type: String, required : true},
      page_title: {type: String, required: true},
      module_name: {type: String, required: true},
    },

    methods: {
      deleteItem(id_) {
        return this.$store.dispatch(`${this.module_name}/deleteItem`, id_);
      },

      deleteItemBtn(item) {
        var index = this.maintenances.findIndex(
          (i) => i.pk === item.pk,
        );
        alertPopup(this.$t("dialog.delete_confirm")).then((result) => {
          if (result && this.maintenances.splice(index, 1)) {
            this.deleteItem(item.pk);
          }
        });
      },
      openLink(url) {
        window.open(url, "_blank");
      },
    },

    data() {
      return {
        showAddReport: false,
        maintenances: [],
      };
    },
  };
</script>
