<template>
  <v-container>
    <v-row class="text-center">
      <v-col class="mb-5" cols="12">
        <Crud
            :headers="headers"
            :moduleName="moduleName"
            :deleteOverride="disableVerif"
            >
            <template v-slot:edit_content>
              <v-row>
                <v-col cols="12" sm="8" md="6">
                  <v-autocomplete
                      v-model="editedItem.producer_type_id"
                      :items="$store.state.producer_types.items"
                      item-title="name"
                      item-value="pk"
                      :label="$filters.capitalize($t('producer_types.label'))"
                      :loading="$store.state.producer_types.isSearching"
                      :clearable="true"
                      variant="solo"
                      >
                  </v-autocomplete>
                </v-col>
        <v-col cols="12" sm="8" md="6">
          <v-autocomplete
              v-model="editedItem.verification_category"
              :items="$store.state.verif_categories.items"
              item-title="name"
              item-value="pk"
              :label="$filters.capitalize($t('verif_machines.category'))"
              :loading="$store.state.verif_categories.isSearching"
              :clearable="true"
              @update:modelValue='handleVerifClassChange'
              variant="solo"
              >
          </v-autocomplete>
        </v-col>
        <v-col cols="12" sm="8" md="6">
          <v-text-field
              v-model="editedItem.verification_title"
              :label="$filters.capitalize($t('verif_machines.title') )"
              ></v-text-field>
        </v-col>
        <v-col cols="12" sm="8" md="6">
          <v-text-field
              v-model="editedItem.indications"
              :label="$filters.capitalize($t('verif_machines.indications') )"
              ></v-text-field>
        </v-col>
              </v-row>
            </template>
        </Crud>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
  import Crud from "@/components/Crud";
  export default {
    components: {
      Crud: Crud,
    },
    computed: {
      editedItem: function() {
        return this.$store.getters.dataEdit(this.moduleName);
      },
    },
    mounted() {
      this.$store.dispatch('producer_types/getItems')
      this.$store.dispatch('verif_categories/getItems')
    },
    methods: {
      handleVerifClassChange(thing) {
        // object vs id #notProud
        this.editedItem.verification_category_id = thing
      },
      disableVerif(pk) {
        this.$store.dispatch('verif_machines/disableItem', pk)
      },

    },
    data() {
      return {
        moduleName: "verif_machines",
        headers: [
          { title: this.$t("producer_types.label"), key: "producer_type.name" },
          //        { title: this.$t("producers.label"), key: "producer.display_name" },
          { title: this.$t("verif_machines.category"), key: "verification_category.name" },
          { title: this.$t("verif_machines.title"), key: "verification_title" },
          { title: this.$t("verif_machines.indications"), key: "indications" },
          {
            title: this.$t("actions.label"),
            key: "actions",
            sortable: false,
          }
        ],
      }
    },
  }
</script>
