<template>
  <v-container fluid>
    <v-toolbar elevation="1" color="white" class="ma-0 pa-0">
      <v-toolbar-title style="font-weight: bolder" class="ma-0 pa-0">
        <v-btn
            id="menu-activator"
            block
            >
            {{$filters.capitalize($t(pageTitle,1))}}: 
            {{viewTitle}}
        </v-btn>
          <v-menu activator="#menu-activator">
            <v-list class="d-flex flex-column">
              <template v-for="button in buttons" :key="button.viewName">
                <v-btn
                    color="primary"
                    class="mb-2 mx-2 pa-0"
                    :disabled="view === button.viewName"
                    @click="toggleView(button)"
                    >
                    {{ $filters.capitalize($t(button.title)) }}
                </v-btn>
              </template>
            </v-list>
          </v-menu>
      </v-toolbar-title>
    </v-toolbar>
    <v-card
        :class='selected_filters.length <= 0? "bg-blue-lighten-5" : "bg-blue-accent-4"'
        v-show='this.view != "doc"'
        >
        <v-card-title
            @click="toggleFilterCard"
            class="d-flex justify-space-between align-center"
            style="font-size: small"
            >
            <span>{{dynamicFilterTitle}}
            </span>
          <v-icon>{{ filterCardState ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon>
        </v-card-title>

        <!-- Card content (v-select inside) -->
        <v-expand-transition>
          <div v-show="filterCardState">
            <v-select
                label = "region"
                :disabled="!isNone(powerStationFilter)"
                v-model = "regionFilter"
                :items = "regions"
                clearable
                density="compact"
                ></v-select>
            <v-select
                v-show='this.view != "regulatory"'
                label = "powerStation"
                v-model = "powerStationFilter"
                :items = "power_stations"
                item-title="name"
                item-value="pk"
                clearable
                density='compact'
                ></v-select>
            <v-select
                v-show='this.view != "regulatory"'
                label="producerType"
                v-model='producerTypeFilter'
                :items='producerTypes'
                clearable
                density='compact'
                ></v-select>
          </div>
        </v-expand-transition>
    </v-card>
    <!-- views  -->
    <component
        :is="currentComponent"
        v-if="currentComponent"
        v-bind="view === 'doc' ? {} : { regionFilter, powerStationFilter, producerTypeFilter }"
        />
  </v-container>
</template>

<script>
  import { defineComponent } from 'vue';
  import { markRaw } from "vue";
  import _ from 'lodash';
  import { isNone } from "@/functions";
  import moment from "moment";
  import ValidationList from "@/components/maintenance/ValidationList";
  import MaintenanceView from "@/components/maintenance/MaintenanceRegulatory";
  import MaintenanceAnnual from "@/components/maintenance/MaintenanceAnnual";
  import SummaryView from "@/components/maintenance/MaintenanceSummary";
  import DocumentationView from "@/components/maintenance/DocumentationView";
  import WindTurbinesLinks from "@/components/maintenance/WindTurbinesLinks";

  export default defineComponent({
    components: {
      MaintenanceView: MaintenanceView,
      ValidationList: ValidationList,
      SummaryView: SummaryView,
      DocumentationView: DocumentationView,
      WindTurbinesLinks: WindTurbinesLinks,
    },
    mounted(){
      this.$store.dispatch('power_stations/getItems')
    },

    computed: {
      power_station_objects: function(){
        return this.$store.state.power_stations.items
      },

      power_stations: function() {
        let ret_ = this.power_station_objects
        if (this.regionFilter) ret_ = ret_.filter(
          (i)=>i.stockage.region==this.regionFilter)
        return ret_
      },
      regions: function () {
        return [...new Set(this.power_station_objects.map(item => item.stockage.region))]
      },
      // producerTypes is fixed in data
      selected_filters: function() {
        let ret = []
        if (this.regionFilter) ret.push(this.regionFilter)
        if (this.powerStationFilter) ret.push(this.powerStationFilter)
        if (this.producerTypeFilter) ret.push(this.producerTypeFilter)
        return ret
      },
      currentComponent() {
        const currentButton = this.buttons.find(button => button.viewName === this.view);
        return currentButton ? currentButton.component : null;
      },
      dynamicFilterTitle() {
        let text = this.$t('generic.filter')
        if (this.regionFilter || this.powerStationFilter || this.producerTypeFilter) text += ':'
        if (this.regionFilter) text += ' ' + this.regionFilter
        if (this.powerStationFilter) text +=  ' ' + this.power_stations.find((p) => p.pk == this.powerStationFilter).name
        if (this.producerTypeFilter) text +=  ' ' + this.producerTypes.find((p) => p.value == this.producerTypeFilter).title
        return this.$filters.capitalize(text)
      },
    },
    methods: {
      toggleView(button){
        this.view = button.viewName
        this.viewTitle = this.$t(button.title)
        this.filterCardState = false
      },
      toggleFilterCard(){
        this.filterCardState = !this.filterCardState
      },
      isNone,
    },

    data() {
      return {
        pageTitle: "maintenances.label",
        filterCardState: true,
        regionFilter: undefined,
        powerStationFilter: undefined,
        producerTypeFilter: undefined,
        producerTypes: [
          // TODO: get list from back
          //{ value: 9, title: "WWD3" },
          { value: 8, title: "WWD1" },
          { value: 2, title: "Enercon82" },
          //{ value: 11, title: "XEMC (XV93 / XV116)" },
        ],
        view: 'summary',
        viewTitle: this.$t('maintenances.summary'),
        buttons: [
          { viewName: "summary", title: "maintenances.summary", component: markRaw(SummaryView) },
          { viewName: "regulatory", title: "maintenances.type.regulatory", component: markRaw(MaintenanceView) },
          //  { viewName: "validation", title: "maintenances.validation", component: markRaw(ValidationList) }, // to be implemented later
          //          { viewName: "annual", title: "maintenances.type.annual", component: markRaw(MaintenanceAnnual) }, // Placeholder
          { viewName: "links", title: "menu.windturbines_links", component: markRaw(WindTurbinesLinks) },
          { viewName: "doc", title: "menu.documentation", component: markRaw(DocumentationView) },
        ],
      };
    },
  });
</script>
