<template>
  <v-card
    title="">
      <v-card-text>
        <v-row>
          <v-col
              v-for="header in headers"
              :key="header.key"
              :cols="header.cols">
            <v-sheet class="pa-4" outlined>
              <v-row>
                <v-col cols="12">
                  <strong>{{ header.title }}:</strong>
                </v-col>
                <v-col cols="12" v-if="header.key=='producer'">
                  <span>{{ intRepEdit[header.key]['display_name'] }}</span>
                </v-col>
                <v-col cols="12" v-else-if="header.key=='partners'">
                  <ul>
                    <li v-for="partner in intRepEdit['partners']" :key="partner.pk">
                      <span>{{ partner.name }}</span>
                    </li>
                  </ul>
                </v-col>
                <v-col cols="12" v-else>
                  <span>{{ intRepEdit[header.key] }}</span>
                </v-col>
              </v-row>
            </v-sheet>
          </v-col>
          <!--- TODO: add consommation / affectation --->
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-btn
            v-if='isFromStaff'
            color="primary"
            text="edit"
            @click="openIntRepForEdit"/>
      </v-card-actions>
  </v-card>
</template>
<script>
  import utils from '@/mixins/utils'

  export default {
    mixins: [utils],
    computed: {
      ...utils.isFromStaff,
      intRepEdit() {
        return this.$store.state.intervention_reports.edit
      },
    },
    methods: {
      openIntRepForEdit() {
        const routeData = this.$router.resolve({
          name: "intervention_reports_edition",
          params: {pk:this.intRepEdit.pk}
        });
        window.open(routeData.href, "_blank");
      },
    },
    data() {
      return {
        headers: [
          {title: this.$t('producers.label', 1), key:"producer", cols:4 },
          {title: this.$t('intervention_reports.date'), key:"date", cols:4 },
          // TODO: filter status into locale
          {title: this.$t('intervention_reports.partner', 10), key:"partners", cols:4 },
          {title: this.$t('intervention_reports.cause'), key:"cause", cols:6 },
          {title: this.$t('intervention_reports.action'), key:"action", cols:6 },
        ],
      }
    }
  }
</script>
