import crud from "./crud";
import crud_service from "@/services/crud";

const path = "verification_machines";
const struct = {
  producer_type: '',
  verification_category: '',
  verification_title: '',
  indications: '',
  periodicity: 'annual',
};
const generic = crud(path, struct);

const state = {
  ...generic.state,
};

const getters = {
  ...generic.getters,
};

const mutations = {
  ...generic.mutations,
};

const actions = {
  getItemsPerProducer({ commit }, {producer_id, verif_category}) {
    var payload = {};
    payload['producer_id'] = producer_id;
    payload['verif_category'] = verif_category;
    commit("setIsLoading", true)
        return new Promise((resolve, reject) => {
          crud_service.fetch(`${path}/producer`, payload)
            .then((items) => {
              commit("setItems", items);
              commit("setIsLoading", false);
              resolve(items.results);
            })
            .catch(() => {
              commit("setIsLoading", false);
              reject();
            });
        });
      },
  disableItem({ commit }, pk) {
    return new Promise ((resolve, reject) => {
      crud_service.post(`${path}/disableItem`, {'pk': pk})
      .then((item) => {
        resolve(item)
      })
      .catch(()=>{
        reject()
      })
    })
  },
  ...generic.actions,
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
