<template>
  <div>
    <v-btn
      @click="handleClick"
      size="small"
      variant="flat"
      :text="xs && iconXS ? null : value"
      :prepend-icon="iconXS"
      :disabled="disableBtn"
      />

    <v-dialog
      v-model="isActive"
      :fullscreen="xs"
      @click:outside="handleClose"
    >
      <template v-slot:default>
        <v-card>
          <v-container>
            <slot
              name="content"
              v-bind="$props"
              @close="handleClose"
              @save="handleSave"
            />
          </v-container>
          <v-spacer></v-spacer>

          <v-card-actions>
            <v-btn :text="$t('close')" @click="handleClose"></v-btn>
            <v-btn v-show="withSave" :text="$t('save')" @click="handleSave"></v-btn>
          </v-card-actions>
        </v-card>
      </template>
    </v-dialog>
  </div>
</template>

<script>
  import { useDisplay } from "vuetify";
  import { isNone } from "@/functions.js";

  export default {
    props: {
      value: {
        type: String,
        required: false,
      },
      title: {
        type: String,
        required: false,
        default: 'Dialog',
      },
      iconXS: {
        type: String,
        required: false,
      },
      disableBtn: {
        type: Boolean,
        required: false,
        default: false,
      },
      withSave: {
        type: Boolean,
        required: false,
        default: false,
      },
    },
    computed: {
      xs() {
        return useDisplay().xs.value
      },
    },
    data() {
      return {
        isActive: false
      };
    },
    methods: {
      handleClick() {
        this.isActive = true
        this.$emit('click');
      },
      handleClose() {
        this.isActive = false;
        this.$emit('close');
      },
      handleSave(){
        this.$emit('save')
        this.isActive = false;
      },
    },
  };
</script>
