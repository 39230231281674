
import crud from "./crud";
import crud_service from "@/services/crud";

const path = "verification_reports";
const struct = {
  producer_id: null,
  verif_type: '',
  verif_subtype: '',
  done_at: null,
  report: null,
  link: null,
};

const generic = crud(path, struct);

const state = {
  summary: [],
  ...generic.state,
};

const getters = {
  ...generic.getters,
};

const mutations = {
  setSummary(state,items){
    state.summary = items
  },
  ...generic.mutations,
};

const actions = {
  getSummary({commit}, options){
    var payload = {};
    if (options) {
      if (options.producer_id !== undefined) {
        payload["producer_id"] = options.producer_id;
      }
      if (options.power_station_id !== undefined) {
        payload["power_station_id"] = options.power_station_id;
      }
    }
    commit("setIsLoading", true);

    return new Promise((resolve) => {
      crud_service
        .fetch(`${path}/summary`, payload)
        .then((items) => {
          commit("setSummary", items)
          commit("setIsLoading", false);
          resolve(items);
        })
        .catch(() => {
          commit("setIsLoading", false);
        });
    });
  },
  closeForYear({ commit }, producer_id) {
    var payload = { "producer_id": producer_id }
    return new Promise((resolve) => {
      crud_service
      .post(`${path}/close_for_year`, payload)
      .then((report) => {
        resolve(report);
      })
    })
  },
  ...generic.actions,
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
