<template>
  <v-container>
    <v-toolbar text color="white" elevation="1" class="mt-3">
          <v-icon
            class="mx-4"
            @click="backBtn()"
          >mdi-arrow-left
          </v-icon>
      <v-spacer></v-spacer>
      <v-toolbar-title style="font-weight: bolder">{{
        $filters.capitalize($t(pageTitle, 1))
        }}
      </v-toolbar-title>
      <v-spacer></v-spacer>
    </v-toolbar>

    <StockPartList
        v-show="showStockPartSelection"
        v-bind:edition="edition"
        @chooseProdType="chooseProdTypeEvent"
        @choosePartCategory="choosePartCategoryEvent"
        @chooseStockPart="chooseStockPartEvent"
        @newStockPart="newStockPartEvent"
        />
    <StockPartForm
        v-show="showStockPartEdition"
        v-bind:edition="edition"
        v-bind:isItNewPart="isItNewPart"
        @chooseProdType="chooseProdTypeEvent"
        @choosePartCategory="choosePartCategoryEvent"
        @chooseStockPart="chooseStockPartEvent"
        @clearStockPart="clearStockPartEvent"
        />
    <EditPartForm
        v-show="showPartEdition"
        v-bind:edition="edition"
        v-bind:showSPS="showStockPartSelection"
        @chooseStockage="chooseStockageEvent"
        />
    <AffectedUnitsList
        :v-show="showPartEdition"
        v-bind:stock_part_id="edition.stock_part_id"
        />
    <PartHistoryList
        v-bind:stock_part_id="edition.stock_part_id"
        v-bind:stockage_id="edition.stockage_id"
        />
  </v-container>
</template>

<script>
  import StockPartList from "@/components/part/StockPartList";
  import StockPartForm from "@/components/part/StockPartForm";
  import EditPartForm from "@/components/part/EditPartForm";
  import AffectedUnitsList from "@/components/part/AffectedUnitsList.vue";
  import PartHistoryList from "@/components/part/PartHistoryList.vue";
  import filters from "@/mixins/filters";
  import { isNone } from "@/functions.js";

  export default {
    mixins: [filters],

    components: {
      StockPartList,
      StockPartForm,
      EditPartForm,
      AffectedUnitsList,
      PartHistoryList,
    },

    props: {
      part: { type: String, required: false }
    },

    computed: {
      editMode: function() {
        return !isNone(this.part);
      }
    },

    methods: {
      backBtn() {
        this.$router.go(-1)
        this.clearEdition();
        this.showPartEdition = false;
        this.showStockPartEdition = false;
        this.showStockPartSelection = true;
      },
      chooseProdTypeEvent(value) {
        if (
          !isNone(this.edition.part_category_id) &&
          this.edition.producer_type_id != value.pk
        ) {
          this.edition.part_category_id = undefined;
        }
        if (!isNone(value)) {
          this.edition.producer_type_id = value.pk;
          this.$store.dispatch("stockages/getByProducerTypes", {
            producer_types: [this.edition.producer_type_id]
          });
        }
        this.showStockPartEdition = false;
        this.showStockPartSelection = true;
        this.showPartEdition = false;
      },
      choosePartCategoryEvent(value) {
        this.edition.part_category_id = value;
      },
      newStockPartEvent() {
      this.clearEdition();
      this.showPartEdition = false;
      this.showStockPartEdition = true;
      this.showStockPartSelection = false;
      this.isItNewPart = true;
        },
      chooseStockPartEvent(value) {
        const query = { ...this.$route.query, stock_part_id: value.pk };
        this.$router.replace({ query });
//        this.getRelevantStockages(value);
        this.updateEdition(value);
        this.showPartEdition = true;
        this.showStockPartEdition = true;
        this.showStockPartSelection = false;
        this.isItNewPart = false;
        if (this.$route.query?.stockage_id){
          this.edition.stockage_id = parseInt(this.$route.query.stockage_id);
        }
      },
      clearStockPartEvent() {
        this.clearEdition();
        this.showPartEdition = false;
        this.showStockPartEdition = false;
        this.showStockPartSelection = true;
        this.isItNewPart = false;
      },
      chooseStockageEvent(value) {
        const query = { ...this.$route.query, stockage_id: value };
        this.$router.replace({ query });
        this.edition.stockage_id = value;
      },
      clearEdition() {
        this.edition.stock_part_id = undefined;
        this.edition.part_category_id = undefined;
        this.edition.stockage_id = undefined;
        this.edition.producer_type_id = undefined;
      },
      updateEdition(item) {
        this.edition.stock_part_id = item.pk;
        this.edition.part_category_id = item.category_id;
      },
      getRelevantStockages(value) {
        let p_ = value.category.producer_type_ids;
        if (p_.length == 0) {
          this.$store.dispatch("stockages/getItems");
        } else {
          this.$store.dispatch("stockages/getByProducerTypes", {
            producer_types: p_
          });
        }
      }
    },
    mounted() {
      this.edition.stockage_id = parseInt(this.$route.query.stockage_id) || undefined;
      this.edition.stock_part_id = parseInt(this.$route.query.stock_part_id) || undefined;
      this.edition.producer_type_id = parseInt(
        this.$route.query.producer_type_id
      ) || undefined;

      // GET DATA FOR FORMS
      if (this.$store.state.producer_types.items.length<=0) {
      this.$store.dispatch("producer_types/getItems");
      }
      if (this.$store.state.stockages.items.length<=0) {
      this.$store.dispatch("stockages/getItems");
      }
      if (this.edition.stockage_id &&
        this.$store.state.stockages.edit.pk != this.edition.stockage_id) {
        this.$store.dispatch("stockages/getItem", this.edition.stockage_id)
        this.$store.dispatch("places/getItems", {
          size:-1, 
          stockage_id:this.edition.stockage_id
        }
        )
      }
      if (this.edition.stock_part_id) {
        this.$store.dispatch('stock_parts/getItem', this.edition.stock_part_id).then(
          (item) => {
            this.$store.dispatch("stock_parts/updateEdit", item);
            this.edition.part_category_id = item.category_id
            this.$store.dispatch('part_categories/updateEdit', item.category)
//            this.getRelevantStockages(item)
          })
      }

      // SET STATUS FOR COMPONENTS
      if (!isNone(this.edition.stock_part_id)) {
        // loading from partView > edit existing
        this.showStockPartSelection = false;
        this.showStockPartEdition = true;
        this.showPartEdition = true;
        this.isItNewPart = false;
      } else if (this.editMode) {
        // loading from partView > edit existing
        this.showStockPartSelection = false;
        this.showStockPartEdition = true;
        this.showPartEdition = true;
        this.isItNewPart = false;
      } else if (this.edition.stockage_id) {
        // loading from partView > create new
        this.showStockPartSelection = false;
        this.showStockPartEdition = true;
        this.showPartEdition = false;
      } else {
        // loading from navBar
        this.showStockPartSelection = true;
        this.showStockPartEdition = false;
        this.showPartEdition = false;
      }
    },

    unmounted() {
      this.$store.dispatch("parts/cleanupItems");
      this.$store.dispatch("real_parts/cleanupItems");
      this.$store.dispatch("stock_parts/cleanupItems");
      this.$store.dispatch("stock_parts/cleanupEdit");
      this.$store.dispatch("affectations/cleanupItems");
    },

    data() {
      return {
        pageTitle: "part_edition.label",
        moduleName: "parts",
        edition: {},
        showPartEdition: false,
        showStockPartEdition: false,
        showStockPartSelection: false,
        isItNewPart: true
    };
    }
  };
</script>

<style scoped>
.service_message {
  font-style: italic;
}
</style>
