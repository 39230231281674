// import Vue from "vue";
import Vuex from "vuex";
import axios from "axios";
import moment from "moment";

import snackbar from "./modules/snackbar";
import affectations from "./modules/affectations";
import alarms from "./modules/alarms";
import alarm_codes from "./modules/alarm_codes";
import performance_ratio_solar_panel from "./modules/performance_ratio_solar_panel";
import alarm_count from "./modules/alarm_count";
import analytics from "./modules/analytics";
import appointments from "./modules/appointments";
import automatons from "./modules/automatons";
import consumables from "./modules/consumables";
import external_repairs from "./modules/external_repairs";
import internal_repairs from "./modules/internal_repairs";
import intervention_reports from "./modules/intervention_reports";
import limitations from "./modules/limitations";
import part_categories from "./modules/part_categories";
import parts from "./modules/parts";
import real_parts from "./modules/real_parts";
import places from "./modules/places";
import power_curves from "./modules/power_curves";
import power_stations from "./modules/power_stations";
import preventive_maintenances from "./modules/preventive_maintenances";
import prod_availabilities from "./modules/prod_availabilities";
import prod_availability_months from "./modules/prod_availability_months";
import producers from "./modules/producers";
import producer_types from "./modules/producer_types";
import purchases from "./modules/purchases";
import purchase_lines from "./modules/purchase_lines";
import risks from "./modules/risks";
import scheduler from "./modules/scheduler";
import stock_parts from "./modules/stock_parts";
import stock_part_limits from "./modules/stock_part_limits";
import stockages from "./modules/stockages";
import spot_prices from "./modules/spot_prices";
import hourly_production from "./modules/hourly_production";
import spot_price_contracts from "./modules/spot_price_contracts";
import suppliers from "./modules/suppliers";
import telemetries from "./modules/telemetries";
import todos from "./modules/todos";
import units from "./modules/units";
import unit_codes from "./modules/unit_codes";
import users from "./modules/users";
import verif_dones from "./modules/verif_dones";
import verif_categories from "./modules/verif_categories";
import verif_machines from "./modules/verif_machines";
import verif_reports from "./modules/verif_reports";
import machines from "./modules/machines";

import { api, cancelSource } from "@/services/api";
import i18n from "@/i18n.js";
import { isNone } from "@/functions";
import crud_service from "@/services/crud";
import { useI18n } from 'vue-i18n';

// Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    status: "",
    loading: false,
    token: localStorage.getItem("token") || undefined,
    auth_token: undefined,
    user: JSON.parse(localStorage.getItem("user")) || undefined,
    editedItem: {},
    varietyItems: [
      { name: i18n.global.t("variety.solar"), id: "s" },
      { name: i18n.global.t("variety.wind"), id: "w" },
    ],
    dateRules: [
      (v) =>
        /^\d{4}-\d{2}-\d{2}[\sT]{1}\d{2}:\d{2}.*$/.test(v) ||
        i18n.global.t("rule.date"),
    ],
    consumableItems: [
      { name: i18n.global.t("unity.piece", 1), id: "pi" },
      { name: i18n.global.t("unity.box", 1), id: "box" },
      { name: i18n.global.t("unity.kg", 1), id: "kg" },
      { name: i18n.global.t("unity.liter", 1), id: "l" },
      { name: i18n.global.t("unity.meter", 1), id: "m" },
    ],
    consumableItems_: ["pi", "box", "kg", "l", "m"],
    unitItems: [{ name: i18n.global.t("unity.unit", 1), id: "un" }],
    unitItems_: ["un"],
    partItems: [
      { name: i18n.global.t("unity.piece", 1), id: "pi" },
      { name: i18n.global.t("unity.box", 1), id: "box" },
      { name: i18n.global.t("unity.kg", 1), id: "kg" },
      { name: i18n.global.t("unity.liter", 1), id: "l" },
      { name: i18n.global.t("unity.meter", 1), id: "m" },
      { name: i18n.global.t("unity.unit", 1), id: "un" },
    ],
    partItems_: ["pi", "box", "kg", "l", "m", "un"],
    preventiveMaintenancePeriods: [
      { name: i18n.global.t("preventive_maintenances.period.annual"), id: "an" },
      { name: i18n.global.t("preventive_maintenances.period.biannual"), id: "bian" },
      { name: i18n.global.t("preventive_maintenances.period.quarterly"), id: "quart" },
      { name: i18n.global.t("preventive_maintenances.period.four"), id: "four" },
    ],
    criticalityItems: [
      { name: i18n.global.t("alarm_codes.criticality.critical"), id: "crit" },
      { name: i18n.global.t("alarm_codes.criticality.warning"), id: "warn" },
      { name: i18n.global.t("alarm_codes.criticality.ok"), id: "ok" },
    ],
  },

  mutations: {
    auth_request(state) {
      state.status = "loading";
    },
    auth_success(state, { token }) {
      state.status = "success";
      localStorage.setItem("token", token);
      state.token = token;
    },
    auth_set_user(state, user) {
      state.user = user;
      localStorage.setItem("user", JSON.stringify(user));
    },
    auth_error(state) {
      state.status = "error";
      localStorage.removeItem("token");
    },
    auth_update_token(state, token) {
      state.token = token;
    },
    auth_update_auth_token(state, auth_token) {
      state.status = "auth_token";
      localStorage.setItem("auth_token", auth_token);
      state.auth_token = auth_token;
    },
    logout(state) {
      state.status = undefined;
      state.token = undefined;
      state.auth_token = undefined;
      state.user = undefined;
      localStorage.removeItem("token");
      localStorage.removeItem("auth_token");
      localStorage.removeItem("user");
    },

    updateAffectationTarget(state, item) {
      state.affectations.edit.external_repair_id = null;
      state.affectations.edit.internal_repair_id = null;
      state.affectations.edit.stock_state_id = null;
      state.affectations.edit.trash_state_id = null;
      state.affectations.edit.producer_id = null;
      state.affectations.kindId = item.id;
      switch (item.type) {
        case "er":
          state.affectations.edit.external_repair_id = item.id;
          break;
        case "ir":
          state.affectations.edit.internal_repair_id = item.id;
          break;
        case "ss":
          state.affectations.edit.stock_state_id = item.id;
          break;
        case "ts":
          state.affectations.edit.trash_state_id = 1;
          break;
        case "p":
          state.affectations.edit.producer_id = item.id;
          break;
        default:
        //console.error(`Unknown affectation type ${item.type}.`);
      }
    },

    setCurrentUserLang(state, value) {
      state.user.lang = value;
      localStorage.setItem(`lang_currentUser`, JSON.stringify(value));
      i18n.global.locale.value = value;
      // to be sure....
      i18n.locale = value
    },
    updateLoading(state, value) {
      state.loading = value;
    },
  },

  actions: {
    login({ commit }, user) {

      localStorage.setItem("cancelSource", axios.CancelToken.source());
      return new Promise((resolve, reject) => {
        commit("auth_request");
        api
          .post(`${process.env.VUE_APP_API_URL}/api/token/`, user)
          .then((resp) => {
            const token = resp.data.access;
            commit("auth_success", { token });
            api.defaults.headers.common["Authorization"] = `Bearer ${token}`;
            api
              .get(`${process.env.VUE_APP_API_URL}/api/users/me/`)
              .then((resp) => {
                commit("auth_set_user", resp.data);
                commit("setCurrentUserLang", resp.data.lang);
                resolve();
              })
              .catch((err) => {
                commit("auth_error");
                reject(err);
              });
            })
            .catch((err) => {
            commit("auth_error");
            reject(err);
          });
      });
    },

    to_login({ state, dispatch }) {
      // do redirection to login page
      return new Promise((resolve, reject) => {
        if (state.status !== "logout" && state.status !== undefined) {
          // undef because logout can be already done
          cancelSource.cancel("logging out");
          return dispatch("logout").then(() => {
            resolve();
          });
        }
        reject();
      });
    },

    ensure_connected({ commit }) {
      return new Promise((resolve, reject) => {
        const id_ = Math.trunc(Math.random() * 1000000000000000);
        crud_service
          .fetch(`${process.env.VUE_APP_API_URL}/api/time/?id=${id_}`)
          .then(() => {
            resolve(true);
          })
          .catch(error => {
            console.error("Failed to ensure connection:", error);
            reject(error);
          });
      });
    },

    logout({ commit }) {
      return new Promise((resolve) => {
        commit("logout");
        resolve();
      });
    },

    auth_update_token({ commit }, token) {
      commit("auth_update_token", token);
    },

    updateAffectationTarget({ commit }, item) {
      commit("updateAffectationTarget", item);
    },

    setCurrentUserLang({ commit }, lang) {
      return new Promise((resolve) => {
        crud_service.post(`users/lang`, { lang: lang }).then((user) => {
          commit("setCurrentUserLang", user.lang);
          let usr = JSON.parse(localStorage.getItem("user"));
          usr.lang = lang;
          localStorage.setItem("user", JSON.stringify(usr));
          resolve(user);
        });
      });
    },

    onLoading({ commit }) {
      commit("updateLoading", true);
    },
    offLoading({ commit }) {
      commit("updateLoading", false);
    },
  },

  modules: {
    affectations,
    alarms,
    alarm_codes,
    alarm_count,
    analytics,
    appointments,
    automatons,
    consumables,
    external_repairs,
    internal_repairs,
    intervention_reports,
    limitations,
    parts,
    part_categories,
    places,
    performance_ratio_solar_panel,
    power_curves,
    power_stations,
    preventive_maintenances,
    prod_availabilities,
    prod_availability_months,
    producers,
    producer_types,
    purchases,
    purchase_lines,
    real_parts,
    risks,
    scheduler,
    stock_parts,
    stock_part_limits,
    stockages,
    spot_prices,
    hourly_production,
    spot_price_contracts,
    suppliers,
    telemetries,
    todos,
    units,
    unit_codes,
    users,
    verif_categories,
    verif_dones,
    verif_machines,
    verif_reports,
    snackbar,
    machines
  },

  getters: {
    isLoggedIn: (state) => !!state.token,
    token: (state) => state.token,
    auth_token: (state) => state.auth_token,
    authStatus: (state) => state.status,
    user: (state) => state.user,
    language: (state) => {
      if (!isNone(state.user)) {
        return state.user.lang;
      }
    },
    getStockPartDisplayName: (state) => (stock_part) => {
      return state.language === 'fr'
      ? stock_part.name
      : (stock_part.name_en !== ''
        ? stock_part.name_en : stock_part.name
        )
    },
    getStockPartDisplayNameFull: (state) => (stock_part) => {
      const baseName = state.language === 'fr'
      ? stock_part.name
      : (stock_part.name_en !== ''
        ? stock_part.name_en : stock_part.name
        )
      return stock_part.ref? `${baseName} - ${stock_part.ref}` : baseName
    },
    firstName: (state) => {
      if (!isNone(state.user)) {
        return state.user.first_name;
      }
      return i18n.global.t("generic.default_name");
    },
    isUserFromStaff: (state) =>
      state.user !== undefined &&
      (state.user.is_staff || state.user.is_superuser),
    isSolarProfil: (state) =>
      state.user !== undefined &&
      (state.user.variety === "s" || isNone(state.user.variety)),
    isWindProfil: (state) =>
      state.user !== undefined &&
      (state.user.variety === "w" || isNone(state.user.variety)),
    //dataStruct: state => key => state[key].struct,
    dataEdit: (state) => (key) => state[key].edit,
    canSearch: (state, getters) => (module_) => getters[`${module_}/canSearch`],
    unityItems: (state) => state.consumableItems.concat(state.unitItems),
  },
});
