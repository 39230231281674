<template>
  <v-container>
    <highcharts :options="chartOptions"></highcharts>
  </v-container>
</template>

<script>
import exportingInit from "highcharts/modules/exporting";
import { capitalize, isNone } from "@/functions.js";

import Highcharts from "highcharts";
import HighchartsMore from 'highcharts/highcharts-more';
exportingInit(Highcharts);
HighchartsMore(Highcharts);

export default {
  props: {
    producer_ids: { type: Array, required: false, default: undefined },
    tickPositionsPower: { type: Array, required: false, default: [0,0.5,1,1.5,2,2.5,3,3.5]},
    height: { type: Number, required: false, default: 860 },
  },

  computed: {
    title() {
      return capitalize(this.$route.query.title);
    },

    producer_ids_() {
      if (this.producer_ids === undefined && !isNone(this.$route.query.id)) {
        return this.$route.query.id.split(",").map(Number);
      }
      return this.producer_ids;
    },
    tickPositionsPower() {
      return this.tickPositionsPower
    },
    labels() {
      return this.producers.map((a) => a.display_name);
    },
  },

  methods: {
    initializeChartOptions() {
      return {
        chart: {
          type: "column",
          height: this.height,
          spacingTop: 20,
          spacingBottom: 20,
          spacingLeft: 10,
          spacingRight: 10,
          backgroundColor: '#f4f4f4',
        },
        title: {
          text: "",
        },
        legend: {
          enabled: true,
          align: 'center',
          verticalAlign: 'bottom',
          layout: 'horizontal',
          itemStyle: {
            fontWeight: 'bold',
            fontSize: '14px',
            color: '#444444',
          },
        },
        plotOptions: {
          column: {
            pointPadding: 0,
            borderWidth: 0,
            groupPadding: 0.05,
            borderRadius: 8,
            dataLabels: {
              enabled: true,
              style: {
                color: '#000',
                textOutline: 'none',
                fontWeight: 'bold',
              },
              formatter: function () {
                let unit = this.series.name === 'Wind' ? ' m/s' : ' MW';
                return this.y.toFixed(2) + unit;
              },
              y: -10
            },
            shadow: {
              color: 'rgba(0,0,0,0.1)',
              offsetX: 1,
              offsetY: 2,
              width: 3,
            },
          },
          errorbar: {},
        },
        xAxis: {
          categories: [],
          labels: {
            style: {
              fontSize: '14px',
              color: '#555555',
            },
          },
          lineColor: '#cccccc',
          tickColor: '#cccccc',
        },
        yAxis: [
          {
            floor: 0,
            tickPositions: [0, 5, 10, 15, 20, 25],
            alignTicks: false,
            title: {
              text: "Wind Speed (m/s)",
              style: {
                color: '#3498db',
                fontSize: '15px',
                fontWeight: 'bold',
              },
            },
            plotBands: [
              {
                color: "#dceefb",
                from: 3,
                to: 4,
                label: {
                  text: "Cut-in",
                  align: "left",
                  y: 3,
                  x: -38,
                  style: { color: "#3498db", fontSize: '13px' },
                },
              },
            ],
            gridLineColor: '#e0e0e0',
            opposite: true,
          },
          {
            tickPositions: this.tickPositionsPower,
            floor: 0,
            title: {
              text: "Power (MW)",
              style: {
                color: '#e74c3c',
                fontSize: '15px',
                fontWeight: 'bold',
              },
            },
            labels: {
              style: {
                fontSize: '14px',
                color: '#555555',
              },
            },
            gridLineColor: '#e0e0e0',
          },
        ],
        tooltip: {
          shared: true,
          backgroundColor: '#ffffff',
          borderColor: '#dddddd',
          borderRadius: 12,
          shadow: true,
          style: {
            fontSize: '14px',
            color: '#444444',
          },
          headerFormat: "<b>{point.key}</b><br>",
          pointFormat:
            '<span style="color:{series.color}">\u25CF</span> {series.name}: {point.y} <br>',
        },
        series: [
          {
            name: "Wind",
            data: [],
            tooltip: {
              valueSuffix: " m/s",
            },

            color: "#3498db",
            yAxis: 0,
            pointPlacement: 0,
            zIndex: 2,
          },
          {
            name: "Power",
            data: [],
            tooltip: {
              valueSuffix: " MW",
            },
            color: "#e74c3c",
            yAxis: 1,
            pointPlacement: 0,
            zIndex: 2,
          },
          {
            name: "Theoretical Production",
            type: "errorbar",
            data: [],
            tooltip: {
              valueSuffix: " MW",
            },
            color: "black",
            yAxis: 1,
            pointPlacement: 0,
            zIndex: 3,
            grouping: false,
            borderColor: "#3498db",
            borderWidth: 1,
          },
        ],
      };
    },

    getOrderByProducer(array_) {
      if(array_ !== undefined){
        const prod_order = this.producers.map((p) => p.pk);
        var resp = [];
        for (var i = 0; i < prod_order.length; i++) {
          resp.push(array_.findIndex((d) => d.producer_id === prod_order[i]));
        }
        return resp;
      }
    },

    parseData(data) {
      var resp = [];

      for (var order in this.getOrderByProducer(data)) {
        var power = data[order].power;
        if (power > 0.0) {
          power = power / 1000.0;
        }

        var power_curve_upper_90 = 0.0;
        var power_curve_below_10 = 0.0;

        if (data[order].power_curve_upper_90 > 0.0){
          power_curve_upper_90 = ((data[order].power_curve_upper_90) / 1000);
        }


        if (data[order].power_curve_below_10 > 0.0){
          power_curve_below_10 = ((data[order].power_curve_below_10) / 1000);
        }

        resp.push({ power: power, wind: data[order].wind , theoretical_prod: [power_curve_below_10, power_curve_upper_90] });
      }
      
      return resp;
    },

    updateData() {
      const payload = { ids: this.producer_ids_ };
      this.$store
        .dispatch(`telemetries/windCurrent_producerItems`, payload)
        .then((data) => {
          const data_bis = this.parseData(data);
          this.chartOptions.series[0].data = data_bis.map((x) => x.wind);
          this.chartOptions.series[1].data = data_bis.map((x) => x.power);
          this.chartOptions.series[2].data = data_bis.map((x) => x.theoretical_prod);
        });
      },

    refreshAt(hours, minutes) {
      var now = new Date();
      var then = new Date();

      if (
        now.getHours() > hours ||
        (now.getHours() == hours && now.getMinutes() >= minutes)
      ) {
        then.setDate(now.getDate() + 1);
      }
      then.setHours(hours);
      then.setMinutes(minutes);
      then.setSeconds(0);

      var timeout = then.getTime() - now.getTime();
      setTimeout(function () {
        window.location.reload(true);
      }, timeout);
    },
  },

  mounted() {
    this.chartOptions.title.text = this.title;


    this.$store
        .dispatch('producers/getItems', { ids: this.producer_ids_ })
        .then((producers) => {
            this.producers = producers;
            this.updateData();
            this.chartOptions.xAxis.categories = this.labels;
        });

    this.$nextTick(() => {
        this.intervalId = window.setInterval(() => {
            this.updateData();
        }, 20 * 1000);
    });

    this.refreshAt(13, 30); // refresh every day at 13:30
  },

  beforeUnmount() {
      window.clearInterval(this.intervalId);
  },


  unmounted() {
    this.producers = [];
    window.clearInterval(this.intervalId);
  },

  data() {
    return {
      producers: [],
      intervalId: undefined,
      chartOptions: this.initializeChartOptions(),
  };
},

};

</script>

<style scoped>
div.highcharts-label .v-icon.mdi {
  color: #bbbbbb;
}
</style>