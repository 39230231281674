<template>
  <v-container>
    <div style="display: none">
      <EditDialog
        v-bind:model="editPerfRatioSolarPanel"
        v-bind:moduleName="'performance_ratio_solar_panel'"
        v-bind:btnName="''"
        @save="savePerformanceRatios"
        @close="cleanupDialog"
        ref="editDialog"
      >
        <template v-slot:edit_content>
          <v-row>
            <v-col cols="12" sm="6" md="6">
              <v-autocomplete
                v-model="selectedProducer"
                :items="$store.state.producers.solar"
                :item-value="'pk'"
                :item-title="'display_name'"
                :label="$filters.capitalize($t('performance_ratio_solar_panel.producer.label'))"
                :loading="$store.state.producers.isSearching"
                :clearable="true"
                hide-details
                return-object
              >
                <template v-slot:no-data>
                  <v-list-item>
                    <v-list-item-title>
                      {{
                        $filters.capitalize(
                          $t("search_for", { noun: $t("performance_ratio_solar_panel.producer.label") })
                        )
                      }}  
                    </v-list-item-title>
                  </v-list-item>
                </template>
              </v-autocomplete>
            </v-col>

            <v-col cols="12" sm="6" md="6">
              <v-text-field
                v-model="selectedYear"
                :label="$filters.capitalize($t('performance_ratio_solar_panel.year'))"
                type="number"
                min="2000"
                max="2100"
                placeholder="YYYY"
              ></v-text-field>
            </v-col>

            <v-col cols="12" sm="12" md="12">
              <v-row>
                <v-col v-for="(month, index) in months" :key="index" cols="12" sm="4" md="3">
                  <v-text-field
                    v-model="ratios[index]"
                    :label="month"
                    type="number"
                    step="0.01"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-col>

            <v-col cols="12" sm="12" md="12">
              <v-textarea
                v-model="description"
                :label="$filters.capitalize($t('performance_ratio_solar_panel.description'))"
                rows="3"
              ></v-textarea>
            </v-col>
          </v-row>
        </template>
      </EditDialog>
    </div>
    <v-btn @click="addYearPerfRatioSolarPanel" :text="$t('performance_ratio_solar_panel.add_year')">
    </v-btn>
    <v-row class="text-center">
      <v-col class="mb-5" cols="12">
        <Crud 
          :headers="headers"
          :moduleName="moduleName" 
        >
          <template v-slot:edit_content>
            <PerformanceRatioSolarPanelForm
              v-bind:perfRatioValue="perfRatioValue"
            ></PerformanceRatioSolarPanelForm>
          </template>
        </Crud>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import Crud from "@/components/Crud";
import filters from "@/mixins/filters";
import EditDialog from "@/components/EditDialog";
import PerformanceRatioSolarPanelForm from "../../components/PerformanceRatioSolarPanelForm.vue";

export default {
  mixins: [filters],

  components: {
    Crud,
    EditDialog,
    PerformanceRatioSolarPanelForm
  },

  computed: {
    editedItem() {
      return this.$store.getters.dataEdit(this.moduleName);
    },
  },

  data() {
    return {
      editPerfRatioSolarPanel: false,
      moduleName: "performance_ratio_solar_panel",
      selectedProducer: null,
      selectedYear: new Date().getFullYear(),
      ratios: Array(12).fill(0), // Default 0 ratio for each month
      description: "",
      months: [
        this.$t("performance_ratio_solar_panel.january"), 
        this.$t("performance_ratio_solar_panel.february"), 
        this.$t("performance_ratio_solar_panel.march"), 
        this.$t("performance_ratio_solar_panel.april"),
        this.$t("performance_ratio_solar_panel.may"), 
        this.$t("performance_ratio_solar_panel.june"), 
        this.$t("performance_ratio_solar_panel.july"), 
        this.$t("performance_ratio_solar_panel.august"),
        this.$t("performance_ratio_solar_panel.september"), 
        this.$t("performance_ratio_solar_panel.october"), 
        this.$t("performance_ratio_solar_panel.november"), 
        this.$t("performance_ratio_solar_panel.december")
      ],
      headers: [
        { title: this.$t("performance_ratio_solar_panel.producer.label"), key: "producer.display_name" },
        { title: this.$t("performance_ratio_solar_panel.year"), key: "year" },
        { title: this.$t("performance_ratio_solar_panel.month"), key: "month" },
        { title: this.$t("performance_ratio_solar_panel.performance_ratio"), key: "ratio" },
        { title: this.$t("performance_ratio_solar_panel.description"), key: "description" },
        {
          title: this.$t("performance_ratio_solar_panel.actions.label"), 
          key: "actions", 
          sortable: false,
        },
      ],
    };
  },

  created() {
    if (this.$store.state.producers.solar.length <= 0) {
      this.$store.dispatch('producers/getSolar');
    }
  },

  methods: {
    addYearPerfRatioSolarPanel() {
      this.editPerfRatioSolarPanel = true;
    },
    cleanupDialog() {
      this.editPerfRatioSolarPanel = false;
    },
    savePerformanceRatios(item) {
      return new Promise((resolve, reject) => {
        const payload = {
          producer_id: this.selectedProducer.pk,
          year: this.selectedYear,
          ratios: this.ratios,
          description: this.description,
        };

        this.$store
          .dispatch(`${this.moduleName}/addRatioYear`, payload)
          .then(() => {
            this.$store.dispatch("snackbar/showSuccess", "Saved successfully");
            resolve();
          })
          .catch((error) => {
            this.$store.dispatch("snackbar/showError", "Error saving item");
            reject(error);
          });
      });
    },
  },
};
</script>
