<template>
  <v-dialog v-model="internalDialog" max-width="600px">
    <div style="display:none">
      <v-dialog v-model="showPeekIntRep">
        <PeekIntRep/>
      </v-dialog>
      <v-dialog v-model="showHistory">
        <VerifDoneHistory
            :verif_id='verif_done.verif_id'
            :producer_id='producer_id'
            />
      </v-dialog>
    </div>
    <v-card>
      <v-card-item class='text-h4'>
        <span v-if="internalMode==='multiple'">{{$filters.capitalize($t('add_several'))}}
        </span>
        <span v-else>
          {{ $filters.capitalize(item.verification_title) }}
        </span>
      </v-card-item>
      <v-card-item v-if="internalMode !== 'multiple'">
        <!--- TODO: locale on all titles--->
        <div><strong>{{$filters.capitalize($t('verif_machines.indications'))}}:</strong> {{ item.indications }}</div>
        <div><strong>{{$filters.capitalize($t('verif_machines.periodicity'))}}:</strong> {{ item.periodicity }}</div>
        <div style="text-align: right">
          <v-btn
              @click='openHistory' 
              :text='$t("verif_machines.actions.view_history")'
              >
          </v-btn></div>
      </v-card-item>
      <v-list v-if="internalMode==='multiple'">
        <v-list-item v-for="thing in item" 
                     :key="thing.verification_title" 
                     :title="thing.verification_title" 
                     :subtitle="thing.commentaire"></v-list-item>
      </v-list>
      <v-card-text class="border-sm">
        <v-container>
          <v-row class='bg-yellow' v-if="!isNone(item.keeper_info)">
            <v-col>
              {{item.keeper_info}}
            </v-col>
            <v-col>
              <v-btn
                  @click='clearComment(item)'
                  color='yellow-lighten-3'
                  >
                  {{$filters.capitalize($t('verif_machines.actions.clear_info'))}}
              </v-btn>
            </v-col>
          </v-row>
          <v-row v-if="internalMode === 'view'" :class="verif_done.keep_info_after_closure ? 'bg-yellow' : 'bg-white'">
            <v-col cols="12">
              <p><strong>{{$filters.capitalize($t('intervention_reports.partner',10))}}:</strong></p>
              <p><strong>{{$filters.capitalize($t('verif_machines.last_done'))}}:</strong> {{ item.last_done_date }}</p>
              <ul>
                <li v-for="(partner, index) in verif_done.partners" :key="index">{{ partner.name }}</li>
              </ul>
              <p><strong>{{$filters.capitalize($t('verif_machines.comment'))}}:</strong> {{ verif_done.comment }}</p>
              <p><strong>{{$filters.capitalize($t('verif_machines.keep_info_after_closure'))}}:</strong>
              {{ $filters.capitalize(verif_done.keep_info_after_closure ? $t('generic.yes') : $t('generic.no')) }}</p>
              <v-icon
                  v-if="verif_done.intervention_report_id !== null"
                  class='mr-2'
                  @click="goToIntRep(verif_done.intervention_report_id)"
                  icon="mdi-clipboard-file-outline"
                  />
                <v-icon
                    icon='mdi-pencil'
                    @click="changeToEditMode"
                    />
            </v-col>
          </v-row>
          <v-row v-else>
            <v-col cols="12">
              <v-form ref='form'>
                <VueDatePicker
                    v-model="verif_done.done_at"
                    :teleport="true"
                    :enable-time-picker="false"
                    :rules="[v => !!v || $filters.capitalize($t('generic.required') )]"
                    placeholder="Date"
                    required
                    />
                    <v-autocomplete
                        v-model="verif_done.partner_ids"
                        :rules="[v => !!v || $filters.capitalize($t('generic.required') )]"
                        :items="$store.state.users.all"
                        item-title="name"
                        item-value="id"
                        :label="$filters.capitalize($t('intervention_reports.partner', 10) )"
                        append-icon="mdi-text-box-search"
                        clearable
                        variant="solo"
                        chips
                        deletable-chips
                        multiple
                        >
                        <template v-slot:no-data>
                          <v-list-item>
                            <v-list-item-title>
                              {{
                              $filters.capitalize($t("search_for", { noun: $t("users.label", 1) }) )
                              }}
                            </v-list-item-title>
                          </v-list-item>
                        </template>
                    </v-autocomplete>
                    <v-textarea
                        v-model="verif_done.comment"
                        :rules="[v => !!v || $filters.capitalize($t('generic.required') )]"
                        :label="$filters.capitalize($t('verif_machines.comment'))"
                        >
                    </v-textarea>
                    <v-checkbox
                        v-model="verif_done.keep_info_after_closure"
                        :label="$filters.capitalize($t('verif_machines.keep_info_after_closure'))"
                        >
                    </v-checkbox>
              </v-form>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-sheet class="d-flex flex-wrap justify-center align-center">
            <span
              v-show = "hasNoReport"
              class="ma-2 pa-2"
              >
              <ReusableDialog
                  :value='$t("verif_machines.actions.add_intervention_report")'
                v-bind:iconXS='"mdi-clipboard-file-outline"'
                  >
                  <template v-slot:content>
                    <InterventionReportForVerif
                        :verif='[this.verif_done]'
                        ></InterventionReportForVerif>
                  </template>
              </ReusableDialog>
            </span>
            <span
              class="ma-2 pa-2"
              >
            <ReusableDialog
                :value='$t("todos.actions.add_item")'
                :withSave='true'
                v-bind:iconXS='"mdi-clipboard-check-multiple"'
                @click='openTodo'
                @save='saveTodo'
                >
                <template v-slot:content>
                  <v-card
                      flat
                      :title='$filters.capitalize($t("todos.actions.add_item"))'
                      />
                    <TodoForm
                        :askProducer='false'
                        :askInterventionReport='false'
                        :askFullfilledAt='false'
                        >
                    </TodoForm>
                </template>
            </ReusableDialog>
            </span>
        <v-btn
            class="ma-2 pa-2"
            color="secondary"
            @click="closeDialog"
            >
            {{$filters.capitalize($t('close'))}}
        </v-btn>
          <v-btn
            class="ma-2 pa-2"
              v-if="internalMode !== 'view'"
              color="primary"
              @click="saveEntry"
              >
              {{$filters.capitalize($t('save'))}}
          </v-btn>
      </v-sheet>
      </v-card>
  </v-dialog>
</template>

<script>
  import ReusableDialog from "@/components/ReusableDialog";
  import PeekIntRep from "@/components/PeekIntRep";
  import InterventionReportForVerif from "@/components/maintenance/InterventionReportForVerif";
  import VerifDoneHistory from "@/components/maintenance/VerifDoneHistory";
  import TodoForm from '@/components/TodoForm';
  import { isNone, alertPopup } from "@/functions";

  export default {
    name: 'VerifDialog',
    components: {
      ReusableDialog,
      PeekIntRep,
      InterventionReportForVerif,
      TodoForm,
      VerifDoneHistory,
    },
    computed: {
      hasNoReport() {
        return isNone(this.verif_done.intervention_report) || isNone(this.verif_done.pk)
      },
    },
    props: {
      dialog: {
        type: Boolean,
        required: true,
      },
      item: {
        type: Object,
        required: true,
      },
      mode: {
        type: String,
        required: true,
      },
      producer_id: {},
    },
    data() {
      return {
        internalDialog: this.dialog,
        verif_done: this.$store.state.verif_dones.struct,
        showPeekIntRep: false,
        showHistory: false,
        internalMode: undefined,
      };
    },
    watch: {
      dialog(val) {
        this.internalDialog = val;
      },
      internalDialog(val) {
        this.$emit('update:dialog', val);
      },
      item(newItem) {
        if (newItem.last_done_id) {
          this.$store.dispatch('verif_dones/getItem', newItem.last_done_id).then((v_d)=> {
            this.verif_done = v_d
          }).catch((e)=>{
            this.$store.dispatch('snackbox/showError', e)})
        } else { 
          this.verif_done = {
            ...this.$store.state.verif_dones.struct,
            done_at: new Date(),
            partner_ids: [this.$store.state.user.id],
            verif: newItem,
            verif_id: newItem.pk,
            producer_id: this.producer_id
          }
        }
      },
    },
    methods: {
      isNone,
      closeDialog() {
        this.internalDialog = false;
      },
      openHistory() {
        this.showHistory = true;
      },
      async saveEntry() {
        const isValid = await this.validate();

        if (!isValid) return
        this.$emit('save', this.verif_done);
        this.closeDialog();
      },
      async validate () {
        const { valid } = await this.$refs.form.validate()
        return valid
      },
      goToIntRep(pk) {
        this.$store.dispatch('intervention_reports/getItem', pk).then(()=>{
          this.showPeekIntRep = true;
        })
      },
      openTodo() {
        let prefilled = this.$store.state.todos.struct
        prefilled.producer_id = this.producer_id
        prefilled.producer = this.$store.state.producers.all.find(a => a.pk==this.producer_id)
        this.$store.dispatch("todos/updateEdit", prefilled)
      },
      saveTodo(){
        this.$emit('saveTodo')
      },
      clearComment(item){
        const payload = {
          producer_id: this.producer_id,
          verif_machine: item.pk
        }
        alertPopup(this.$t('verif_machines.clear_comment_confirm')).then((result)=>{
          if(result) {
            this.$store.dispatch('verif_dones/clearComment', payload).then(()=>{
              this.$emit('clearKeeperInfo', this.item)
            })
          }
        })
      },
      changeToEditMode(){
        this.internalMode = "edit"
      },
    },
    //  computed(){
    //    availablePartners() {
    //      //TODO: filter by users in stockage -> p-station -> producer?
    //      return store.state.users.filter(
    //    },
    //  },
    mounted(){
      this.internalMode = this.mode
      if (this.$store.state.users.all.length <= 0 && !this.$store.state.users.isLoading) {
        this.$store.dispatch('users/getAll')
      }
    },
    updated() {
      this.internalMode = this.mode
    },
  };
</script>
