<template>
  <v-card>
    <div style="display:none">
      <v-dialog v-model="showPeekIntRep">
        <PeekIntRep/>
      </v-dialog>
    </div>
    <v-card-item class="word-break:break-word text-h6 bg-blue-lighten-5">
      {{ cardTitle }}
    </v-card-item>
    <v-card v-for="it in items" v-if='dataReady'>
      <v-card-item>
        <v-row :class="it.keep_info_after_closure ? 'bg-yellow' : 'bg-white'">
          <v-col cols="12">
            <p><strong>{{$filters.capitalize($t('generic.date'))}}:</strong> {{ $filters.formatDate(it.done_at) }}</p>
            <p><strong>{{$filters.capitalize($t('intervention_reports.partner'))}}:</strong></p>
            <ul>
              <li v-for="(partner, index) in it.partners" :key="index">{{ partner.name }}</li>
            </ul>
            <p><strong>{{$filters.capitalize($t('verif_machines.comment'))}}:</strong> {{ it.comment }}</p>
            <p><strong>{{$filters.capitalize($t('verif_machines.keep_info_after_closure'))}}:</strong>
            {{ it.keep_info_after_closure ? 'Yes' : 'No' }}</p>
          </v-col>
        </v-row>
      </v-card-item>
      <v-card-item>
        <v-icon
            v-show="it.intervention_report_id != null"
            @click="goToIntRep(it.intervention_report_id)"
            icon="mdi-clipboard-file-outline"
            class="mr-4"
            />
          <v-icon
              :disabled="true"
              v-show="it.intervention_report_id == null"
              icon="mdi-clipboard-off-outline"
              class="mr-4"
              />
            <v-icon
                v-show="it.closure_report_id != null"
                @click="goToClosureReport(it.closure_report.report)"
                icon="mdi-text-box-check-outline"
                />
      </v-card-item>
    </v-card>
  </v-card>
</template>
<script>
  import PeekIntRep from "@/components/PeekIntRep";

  export default {
    components: {
      PeekIntRep,
    },
    props: {
      verif_id: {},
      producer_id: {},
    },
    mounted() {
      this.loadHistory();
    },
    methods: {
      loadHistory() {
        this.$store.dispatch('verif_dones/getHistory', {
          producer_id: this.producer_id,
          verif_id: this.verif_id,
        }).then((result)=> this.items = result)
      },
      goToIntRep(pk) {
        this.$store.dispatch('intervention_reports/getItem', pk).then(()=>{
          this.showPeekIntRep = true;
        })
      },
      goToClosureReport(url) {
        window.open(url, "_blank");
      }
    },
    computed: {
      cardTitle() {
        if (this.$store.state.verif_dones.isLoading) return this.$t('loading')
        if (this.items.length <= 0 ) return this.$t('verif_machines.no_history')
        let t = this.items[0]
        return t.producer.display_name + ': ' + 
          t.verif.verification_category.name + ': ' +
          t.verif.verification_title
      },
      dataReady() {
        return !this.$store.state.verif_dones.isLoading && this.items.length >0
      },
    },
    data() {
      return {
        items: [],
        showPeekIntRep: false,
      }
    }
  }
</script>

