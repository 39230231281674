<template>
  <v-container fluid class='ma-0 pa-0'>
    <v-dialog
        v-model='showVerifMachine'
        :fullscreen="xs"
        >
        <v-card
            @click:outside="handleClose"
            >
            <VerifMachine
                :producer_id=selectedProd.id
                :producer_name=selectedProd.display_name
                :dialogMode=dialogMode
                @close="handleClose"
                ></VerifMachine>
            <v-btn class='super_footer' :text="$t('close')" @click="handleClose"></v-btn>
        </v-card>
    </v-dialog>
    <v-table
        density ='compact'
        fixed-header
        height='80vh'
        class="tight-table my-1 pa-0">
      <template v-slot:default>
        <thead>
          <tr>
            <th
                v-for='item in mainTitleItems'
                :key='item.text'
                @click='sortFilteredItems(item)'
                :class='item.class'
                :colspan='item.colspan'
                :rowspan='item.rowspan'
                >
                <v-icon
                    v-show="item.prepend_icon"
                    :icon="item.prepend_icon"
                  />
                {{ $filters.capitalize($t(item.text))}}
            </th>
          </tr>
          <tr>
            <th
                v-for='item in subTitleItems'
                :key='item.text'
                @click='sortFilteredItems(item)'
                :class='item.class'
                >
                <v-icon
                    v-show="item.prepend_icon"
                    :icon="item.prepend_icon"
                  />
                {{ $filters.capitalize($t(item.text)) }}
            </th>
          </tr>
        </thead>

        <tbody>
          <tr
              v-for="(prod, index) in filteredItems"
              :key="index"
              :class="{'grey-row': index % 2 === 0, 'white-row': index % 2 !== 0}"
              >
              <td class="sticky-column px-1 py-0 ma-0 bg-grey-lighten-4">
                {{ $filters.capitalize(prod.display_name) }}
              </td>
            <td :class="itemClass">
              <ReusableDataDialog
                  @close='loadSummary'
                  :value="prod.annual"
                  >
                  <template v-slot:content>
                    <MaintenanceReportList
                        :producer_id='prod.id'
                        :producer_name='prod.display_name'
                        :pageTitle = "'verif_reports.annual'"
                        reportType = 'ac'
                        />
                  </template>
              </ReusableDataDialog>
            </td>
            <td :class="itemClass"> 
              <v-btn
                  @click='handleVerifMachine(prod, "")'
                  variant="flat"
                  >
                  {{ prod.ac_done }} / {{ prod.ac_total }}
              </v-btn>
                <v-progress-linear
                    :model-value='100 * prod.ac_done / prod.ac_total'
                    :color='prod.ac_done / prod.ac_total <= 0.5 ? "blue" : "blue-darken-3"'
                    >
                </v-progress-linear>
            </td>
            <td :class="itemClass">
              <v-btn
                  @click='handleVerifMachine(prod, "gr")'
                  variant="flat"
                  >
                  {{ prod.ac_done_gr }} / {{ prod.ac_total_gr }}
              </v-btn>
                <v-progress-linear
                    :model-value='100 * prod.ac_done_gr / prod.ac_total_gr'
                    :color='prod.ac_done_gr / prod.ac_total_gr <= 0.5 ? "blue" : "blue-darken-3"'
                    >
                </v-progress-linear>
            </td>
            <td :class="itemClass">
              <v-btn
                  @click='handleVerifMachine(prod, "tr")'
                  variant="flat"
                  >
                  {{ prod.ac_done_tr }} / {{ prod.ac_total_tr }}
              </v-btn>
                <v-progress-linear
                    :model-value='100 * prod.ac_done_tr / prod.ac_total_tr'
                    :color='prod.ac_done_tr / prod.ac_total_tr <= 0.5 ? "blue" : "blue-darken-3"'
                    >
                </v-progress-linear>
            </td>
            <td :class="itemClass">
              <ReusableDataDialog
                  :value="prod.blades"
                  @close='loadSummary'
                  :limit_red="6"
                  :limit_orange="2"
                  >
                  <template v-slot:content>
                    <MaintenanceReportList
                        :producer_id='prod.id'
                        :producer_name='prod.display_name'
                        :pageTitle = "'verif_reports.blades'"
                        reportType = 'bl'
                        />
                  </template>
              </ReusableDataDialog>
            </td>
            <td :class="itemClass">
              <ReusableDataDialog
                  :value="prod.thermal"
                  :limit_red="12"
                  :limit_orange="6"
                  @close='loadSummary'
                  >
                  <template v-slot:content>
                    <MaintenanceReportList
                        :producer_id='prod.id'
                        :producer_name='prod.display_name'
                        :pageTitle = "'verif_reports.thermal'"
                        reportType = 'th'
                        />
                  </template>
              </ReusableDataDialog>
            </td>
            <td :class="itemClass">
              <ReusableDataDialog
                  :value="prod.regulatory"
                  @close='loadSummary'
                  >
                  <template v-slot:content>
                    <MaintenanceRegulatoryList
                        v-bind:entity_id='prod.power_station_id'
                        v-bind:entity_name='prod.power_station_name'
                        v-bind:page_title= "'preventive_maintenances.edition.label'"
                        v-bind:module_name= "'preventive_maintenances'"
                        ></MaintenanceRegulatoryList>
                  </template>
              </ReusableDataDialog>
            </td>
          </tr>
        </tbody>
      </template>
    </v-table>
  </v-container>
</template>

<script>
  import { useDisplay } from "vuetify";
  import { isNone } from "@/functions.js";
  import ReusableDataDialog from "@/components/maintenance/ReusableDataDialog";
  import VerifMachine from "@/components/maintenance/VerifMachine";
  import MaintenanceReportList from "@/components/maintenance/MaintenanceReportList";
  import MaintenanceRegulatoryList from "@/components/maintenance/MaintenanceRegulatoryList";

  export default {
    components: {
      MaintenanceReportList,
      MaintenanceRegulatoryList,
      VerifMachine,
      ReusableDataDialog,
    },
    mounted(){
      this.loadSummary();
    },
    props: {
      regionFilter: {},
      powerStationFilter:{},
      producerTypeFilter:{},
    },
    computed: {
      editedItem: function () {
        return this.$store.getters.dataEdit(this.moduleName);
      },
      items: function() {
        return this.$store.state.verif_reports.summary;
      },
      filteredItems: function() {
        let ret_ = this.items
        if (!isNone(this.regionFilter)) ret_ = _.filter(ret_, ['region', this.regionFilter]);
        if (!isNone(this.powerStationFilter)) ret_ = _.filter(ret_, ['power_station_id', this.powerStationFilter]);
        if (!isNone(this.producerTypeFilter)) ret_ = _.filter(ret_, ['producer_type_id', this.producerTypeFilter]);
        ret_ = _.orderBy(ret_, [this.sortBy], [this.order])
        return ret_
      },
      xs() {
        return useDisplay().xs.value
      },
    },
    methods: {
      loadSummary() {
        this.$store.dispatch("verif_reports/getSummary")
      },
      sortFilteredItems(item){
        const sortOrder = item.sortOrder
        if (this.sortBy != sortOrder) {
          this.sortBy = sortOrder
          this.order = 'desc';
          this.clearTitleIcons();
          item.prepend_icon = 'mdi-triangle-small-down';
        }
        else if (this.order == 'desc') {
          this.order = 'asc';
          item.prepend_icon = 'mdi-triangle-small-up'
        } else {
          this.order = 'desc';
          item.prepend_icon = 'mdi-triangle-small-down'
        }
      },
      clearTitleIcons() {
        for (let item of this.mainTitleItems) {
            item.prepend_icon = null;
        }
        for (let item of this.subTitleItems) {
            item.prepend_icon = null;
        }
      },
      handleVerifMachine(item, dialogMode){
        this.selectedProd = item
        this.dialogMode = dialogMode
        this.$store.dispatch('verif_machines/getItemsPerProducer',
          {
            producer_id: item.id,
            verif_category: dialogMode
          }
        )
        this.showVerifMachine = true;
      },
      handleClose(){
        this.loadSummary();
        this.showVerifMachine = false;
      },
    },
    data() {
      return {
        moduleName: "verif_reports",
        selectedProd: null,
        showVerifMachine: false,
        dialogMode: undefined,
        sortBy: 'producer',
        order: 'desc',
        power_station_id: undefined,
        itemClass: "pa-0 ma-0 text-center",
        mainTitleItems: [
          {
            "text": 'producers.label',
            "sortOrder": "display_name",
            "prepend_icon": null,
            "colspan": 1,
            "rowspan": 2,
            "class": "text-left sticky-column pl-1 pa-0 ma-0 bg-grey-lighten-4 sticky-thead",
          },
          {
            "text": 'maintenances.annual_closure',
            "sortOrder": "annual",
            "prepend_icon": null,
            "colspan": 1,
            "rowspan": 2,
            "class": "text-center  pa-0 ma-0 sticky-thead",
          },
          {
            "text": 'maintenances.annual_current',
            "prepend_icon": null,
            "colspan": 3,
            "rowspan": 1,
            "class": "text-center  pa-0 ma-0 sticky-thead",
          },
          {
            "text": 'maintenances.type.blades',
            "sortOrder": "blades",
            "prepend_icon": null,
            "colspan": 1,
            "rowspan": 2,
            "class": "text-center  pa-0 ma-0 sticky-thead",
          },
          {
            "text": 'maintenances.type.thermal',
            "sortOrder": "thermal",
            "prepend_icon": null,
            "colspan": 1,
            "rowspan": 2,
            "class": "text-center  pa-0 ma-0 sticky-thead",
          },
          {
            "text": 'maintenances.type.regulatory',
            "sortOrder": "regulatory",
            "prepend_icon": null,
            "colspan": 1,
            "rowspan": 2,
            "class": "text-center  pa-0 ma-0 sticky-thead",
          },
        ],
        subTitleItems: [
          {
            "text": 'generic.all',
            "sortOrder": "ac_done",
            "prepend_icon": null,
            "class": "text-center  pa-0 ma-0 sticky-thead",
          },
          {
            "text": 'maintenances.type.grease',
            "sortOrder": "ac_done_gr",
            "prepend_icon": null,
            "class": "text-center  pa-0 ma-0 sticky-thead",
          },
          {
            "text": 'maintenances.type.transformer',
            "sortOrder": "ac_done_tr",
            "prepend_icon": null,
            "class": "text-center  pa-0 ma-0 sticky-thead",
          },
        ],

      };},
  }
</script>


<style scoped>

table {
  table-layout: auto;
  border-spacing: 0;
  width: 100%;
}

.sticky-column {
  position: -webkit-sticky; /* For Safari */
  position: sticky;
  width: 10em;
  left: 0;
  background: white; /* Ensure background matches table */
  z-index: 1; /* Ensure it is above other cells */
}

.tight-table {
  width: 100%;
  border-collapse: collapse;
  margin: 0;
  padding: 0;
}

.tight-table th,
.tight-table td {
  margin: 0;
  padding: 2px 4px; /* Adjust padding as needed */
}

.grey-row {
  background-color: #f2f2f2;
}

.white-row {
  background-color: #ffffff;
}


</style>
