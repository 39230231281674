<template>
  <v-container>
    <v-row>
      <v-col cols="12" md="12">
        <v-card class="mx-auto my-3">
          <v-toolbar flat color="primary" dark>
            <v-spacer></v-spacer>

            <v-toolbar-title class="font-weight-bold justify-center text-center">{{$filters.capitalize($t(`telemetry.label`, 10) )}}</v-toolbar-title>

            <v-spacer></v-spacer>
          </v-toolbar>
          <TelemetryChart class="pa-2"></TelemetryChart>
        </v-card>
      </v-col>

      <v-col cols="12" md="12">
        <v-card class="mx-auto my-3">
          <v-toolbar flat color="primary" dark>
            <v-spacer></v-spacer>

            <v-toolbar-title class="font-weight-bold justify-center text-center">Oualidia</v-toolbar-title>

            <v-spacer></v-spacer>
          </v-toolbar>
          <TelemetryChartWind
            class="pa-2"
            v-bind:producer_ids="[
              67, 68, 69, 70, 71, 72, 73, 74, 75, 76, 77, 78,
            ]"
          ></TelemetryChartWind>
        </v-card>
      </v-col>

      <v-col cols="12" md="12">
        <v-card class="mx-auto my-3">
          <v-toolbar flat color="primary" dark>
            <v-spacer></v-spacer>

            <v-toolbar-title class="font-weight-bold justify-center text-center">DEIF</v-toolbar-title>

            <v-spacer></v-spacer>
          </v-toolbar>
          <TelemetryChartWind
            class="pa-2"
            :producer_ids="[97, 113, 114, 115, 116, 117]"
            :tickPositionsPower="[0,0.5,1,1.5,2]"
          ></TelemetryChartWind>
        </v-card>
      </v-col>

      <v-col cols="12" md="12">
        <v-card class="mx-auto my-3" elevation="2">
          <v-toolbar flat color="primary" dark>
            <v-spacer></v-spacer>
            
            <v-toolbar-title class="font-weight-bold justify-center text-center">WWD1</v-toolbar-title>
            
            <v-spacer></v-spacer>
          </v-toolbar>
          
          <v-divider></v-divider>
          
          <v-card-text class="pa-4">
            <TelemetryChartWind
              :producer_ids="[1,2,6,7,46,47,48,49,50,51,52,53,54,55,56,57,58,59,60,61,62,63,80,81,82,83,96]"
              class="telemetry-chart"
            ></TelemetryChartWind>
          </v-card-text>
        </v-card>
      </v-col>

      <v-col cols="12" md="12">
        <v-card class="mx-auto my-3" elevation="2">
          <v-toolbar flat color="primary" dark>
            <v-spacer></v-spacer>
            
            <v-toolbar-title class="font-weight-bold justify-center text-center">2MW</v-toolbar-title>
            
            <v-spacer></v-spacer>
          </v-toolbar>
          
          <v-divider></v-divider>
          
          <v-card-text class="pa-4">
            <TelemetryChartWind
              :producer_ids="[3, 4, 64, 65, 66, 31, 32, 33, 34, 35, 36]"
              :tickPositionsPower="[0,0.5,1,1.5,2]"
              class="telemetry-chart"
            ></TelemetryChartWind>
          </v-card-text>
        </v-card>
      </v-col>

      <v-col cols="12" md="12">
        <v-card class="mx-auto my-3" elevation="2">
          <v-toolbar flat color="primary" dark>
            <v-spacer></v-spacer>
            
            <v-toolbar-title class="font-weight-bold justify-center text-center">Eoliennes GE 2,85MW</v-toolbar-title>
            
            <v-spacer></v-spacer>
          </v-toolbar>
          
          <v-divider></v-divider>
          
          <v-card-text class="pa-4">
            <TelemetryChartWind
              :producer_ids="[43, 44, 45, 88, 93, 94, 95, 89, 90, 91, 92 ]"
              class="telemetry-chart"
            ></TelemetryChartWind>
          </v-card-text>
        </v-card>
      </v-col>

      <v-col cols="12" md="12">
        <v-card class="mx-auto my-3" elevation="2">
          <v-toolbar flat color="primary" dark>
            <v-spacer></v-spacer>
            
            <v-toolbar-title class="font-weight-bold justify-center text-center">Eoliennes 3MW</v-toolbar-title>
            
            <v-spacer></v-spacer>
          </v-toolbar>
          
          <v-divider></v-divider>
          
          <v-card-text class="pa-4">
            <TelemetryChartWind
              :producer_ids="[103, 104, 105, 79, 37, 38, 39, 40, 41, 42]"
              class="telemetry-chart"
            ></TelemetryChartWind>
          </v-card-text>
        </v-card>
      </v-col>

      <v-col cols="12" md="12">
        <v-card class="mx-auto my-3" elevation="2">
          <v-toolbar flat color="primary" dark>
            <v-spacer></v-spacer>
            
            <v-toolbar-title class="font-weight-bold justify-center text-center">Eoliennes 3MW - Buire le Sec</v-toolbar-title>
            
            <v-spacer></v-spacer>
          </v-toolbar>
          
          <v-divider></v-divider>
          
          <v-card-text class="pa-4">
            <TelemetryChartWind
              :producer_ids="[8, 9, 12, 13, 14, 15, 16, 17, 18, 19]"
              class="telemetry-chart"
            ></TelemetryChartWind>
          </v-card-text>
        </v-card>
      </v-col>

      <v-col cols="12" md="12">
        <v-card class="mx-auto my-3" elevation="2">
          <v-toolbar flat color="primary" dark>
            <v-spacer></v-spacer>
            
            <v-toolbar-title class="font-weight-bold justify-center text-center">Eoliennes 3MW - Eplessier</v-toolbar-title>
            
            <v-spacer></v-spacer>
          </v-toolbar>
          
          <v-divider></v-divider>
          
          <v-card-text class="pa-4">
            <TelemetryChartWind
              :producer_ids="[20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30]"
              class="telemetry-chart"
            ></TelemetryChartWind>
          </v-card-text>
        </v-card>
      </v-col>

      <v-col cols="12" md="12">
        <v-card class="mx-auto my-3" elevation="2">
          <v-toolbar flat color="primary" dark>
            <v-spacer></v-spacer>
            
            <v-toolbar-title class="font-weight-bold justify-center text-center">Eoliennes Suède</v-toolbar-title>
            
            <v-spacer></v-spacer>
          </v-toolbar>
          
          <v-divider></v-divider>
          
          <v-card-text class="pa-4">
            <TelemetryChartWind
              :producer_ids="[98, 99, 100, 101, 102, 139, 140, 141, 58, 59, 60, 61, 62]"
              class="telemetry-chart"
            ></TelemetryChartWind>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12" md="12">
        <v-card class="mx-auto my-3" elevation="2">
          <v-toolbar flat color="yellow" dark>
            <v-spacer></v-spacer>
            
            <v-toolbar-title class="font-weight-bold justify-center text-center">Parc Solaire</v-toolbar-title>

            
            <v-spacer></v-spacer>
          </v-toolbar>
          
          <v-divider></v-divider>
          
          <v-card-text class="pa-4">
            <TelemetryChartSolar
              :producer_ids="[144, 143, 142, 145, 152]"
              class="telemetry-chart"
            ></TelemetryChartSolar>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12" md="12">
        <v-card class="mx-auto my-3" elevation="2">
          <v-toolbar flat color="yellow" dark>
            <v-spacer></v-spacer>
            
            <v-toolbar-title class="font-weight-bold justify-center text-center">Parc Solaire</v-toolbar-title>
            
            <v-spacer></v-spacer>
          </v-toolbar>
          
          <v-divider></v-divider>
          
          <v-card-text class="pa-4">
            <TelemetryChartSolarGrafana
              :producer_ids="[196, 193, 197]"
              class="telemetry-chart"
            ></TelemetryChartSolarGrafana>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import TelemetryChart from "@/components/TelemetryChart";
import TelemetryChartWind from "@/components/TelemetryChartWind";
import TelemetryChartSolar from "@/components/TelemetryChartSolar";
import TelemetryChartSolarGrafana from "@/components/TelemetryChartSolarGrafana";

export default {
  components: {
    TelemetryChart,
    TelemetryChartWind,
    TelemetryChartSolar,
    TelemetryChartSolarGrafana
  },

  data() {
    return {};
  },
};
</script>
