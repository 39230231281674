<template>
  <div class= "toolbar-scroll-group ma-0 pa-0">
    <verif-dialog
        :dialog="verifDialogIsOpen"
        :item="selectedItem"
        :mode="verifDialogMode"
        :producer_id="producer_id"
        @update:dialog="verifDialogIsOpen = $event"
        @save="saveItem"
        @saveTodo="saveTodo"
        @clearKeeperInfo="clearKeeperInfo"
        ></verif-dialog>
    <div class="toolbar bg-blue-lighten-5">
      <v-card flat class="bg-blue-lighten-5 mx-2">
        <v-card-title class="title-text ma-1 pa-0">
          {{producer_name}}
        </v-card-title>
        <v-card-subtitle class="ma-2 pa-0">
          {{percentage}}% {{$t('verif_machines.complete')}} - {{completed}}/{{total}}
          <v-progress-linear
              bg-color="surface-variant"
              color="primary"
              height="10"
              :model-value="percentage"
              rounded="pill"
              />
        </v-card-subtitle>
      </v-card>
    </div>
    <div class="scrollable-content">
      <v-row
          >
          <v-col
              class='bg-blue-lighten-4 ml-3 mt-2 text-decoration-underline'
              @click='toggleShow'
              >
              <v-hover>
                <template v-slot:default="{ isHovering, props }">
                  <span 
                          v-bind="props"
                          :class="isHovering ? 'bg-blue-lighten-3 ml-3 mt-2' : 'bg-blue-lighten-4 ml-3 mt-2'"
                          >
                          {{showText}}
                  </span>
                </template>
              </v-hover>
          </v-col>
      </v-row>
      <v-row v-for="(group, verifCategory) in groupedData" :key="verifCategory">
        <v-col cols="12">
          <v-card class="mb-0">
            <v-card-text
                class="d-flex align-center pa-0"
                @click="toggleCollapse(verifCategory)"
                >
                <v-icon class="mr-2">
                  {{ isCollapsed(verifCategory) ? 'mdi-chevron-down' : 'mdi-chevron-up' }}
                </v-icon>
              <div class="group-text-container">
                <span class="title-text">{{ verifCategory }}</span>
                <span class="subtitle-text">{{ getCompleteness(verifCategory) }}</span>
              </div>
            </v-card-text>
            <v-card-text v-show="isCollapsed(verifCategory)">
              <div class="table-container">
                <table class="table width:100%">
                  <thead>
                    <tr>
                      <th>{{$t('verif_machines.title')}}</th>
                      <th v-show="!isMobile">{{$t('verif_machines.indications')}}</th>
                      <th v-show="!isMobile">{{$t('verif_machines.comment')}}</th>
                      <th v-show="!isMobile">{{$t('verif_machines.periodicity')}}</th>
                      <th>{{$t('verif_machines.last_done')}}</th>
                      <th>{{$t('intervention_reports.partner')}}</th>
                      <th class="width=10%"></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(item, index) in group.items" 
                        :key="index" 
                        @click="handleRowClick(item, index, group)"
                        :class="getRowClass(item, index, group)"
                        >
                        <td>
                          {{ item.verification_title }} 
                          <v-tooltip location="top" :text="item.keeper_info">
                            <template v-slot:activator="{ props }">
                              <v-icon
                                  v-bind="props"
                                  v-if="item.keeper_info"
                                  class="ml-1"
                                  icon="mdi-information"
                                  />
                            </template>
                          </v-tooltip>
                        </td>
                        <td v-show="!isMobile">{{ item.indications }}</td>
                        <td v-show="!isMobile">{{ item.comment }}</td>
                        <td v-show="!isMobile">{{ item.periodicity }}</td>
                        <td>{{ item.last_done_date }}</td>
                        <td>{{formatList(item.last_done_by)}}</td>
                        <td class="text-center">
                          <v-icon 
                            :icon="isSelected(index, group) ? 'mdi-checkbox-outline' : 'mdi-checkbox-blank-outline'"
                            @click.stop="toggleSelect(index, group)"
                            v-show="!Boolean(item.last_done_id) && Boolean(selectMultiple === 'selectionMultiple')"
                            ></v-icon>
                          <v-icon
                              v-show="Boolean(item.last_done_id) && !Boolean(item.intervention_report)"
                              icon="mdi-check-circle-outline"
                              ></v-icon>
                          <v-icon
                              v-show="Boolean(item.last_done_id) && Boolean(item.intervention_report)"
                              icon="mdi-clipboard-check-outline"
                              ></v-icon>
                        </td>
                    </tr>
                  </tbody>
                </table></div>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </div>
    <div class="footer">
      <AnnualActions
          :verifWithoutReport='verifWithoutReport'
          :areItemsSelected='areItemsSelected'
          :producer_id='producer_id'
          :someDone='someDone'
          @addItems='addItems'
          @select_multiple='handleSelectMultiple'
          @reloadVerifDone='reloadVerifDone'
          @close="close"
          @saveTodo="saveTodo"
          />
    </div>
  </div>
</template>

<script>
  import AnnualActions from "@/components/maintenance/AnnualActions";
  import VerifDialog from '@/components/maintenance/VerifDialog';
  import { isNone } from "@/functions.js";


  export default {
    name: 'VerifList',
    components: {
      AnnualActions,
      VerifDialog,
    },
    props: {
      producer_id: {},
      producer_name: {},
      dialogMode: {},
    },

    computed: {
      items(){
        return this.$store.state.verif_machines.items
      },
      groupedData(){
        let filteredList = this.items
        if (this.showMode === 'done') {
          filteredList = this.items.filter(item => item.last_done_id);
        } else if (this.showMode === 'pending') {
          filteredList = this.items.filter(item => !item.last_done_id);
        }
        const grouped = filteredList.reduce((acc, item) => {
          if (!acc[item.category_name]) {
            acc[item.category_name] = { items: []};
          }
          acc[item.category_name].items.push(item);
          return acc;
        }, {});
        return grouped;
      },
      someDone(){
        return this.items.filter(item => item.last_done_id).length > 0;
      },
      areItemsSelected(){
        return this.selectedItems.length > 0 && !isNone(this.selectedItems);
      },
      verifWithoutReport(){
        return this.items.filter(item => isNone(item.intervention_report_id) &&
          !isNone(item.last_done_id));
      },
      completed(){
        return this.items.filter(item => !isNone(item.last_done_id)).length
      },
      total(){
        return this.items.length
      },
      percentage(){
        return Math.trunc(100*this.completed/this.total)
      },
      showText(){
        let txt = ''
        if (this.selectMultiple != 'normal') {
          txt = this.$filters.capitalize(this.$t('verif_machines.' + this.selectMultiple))
          if (this.showMode != 'all') txt = txt + ': '
        }
        switch (this.showMode) {
          case 'all': 
            break
          case 'done':
            txt = txt + this.$t('verif_machines.showing_only_done')
            break
          case 'pending':
            txt = txt + this.$t('verif_machines.showing_only_pending')
        }
        return txt
      },
    },

    methods: {
      handleRowClick(item, index, group) {
        if (this.selectMultiple === 'normal') {
          this.accessItem(item);
        } else if (this.selectMultiple === 'selectionMultiple') {
          this.toggleSelect(index, group);
        } else if (this.selectMultiple === 'editionMultiple') {
          this.toggleSelect(index, group);
        }
      },
        handleSelectMultiple(selection_multiple) {
          this.selectMultiple = selection_multiple;
        },
        reloadVerifDone() {
          this.$store.dispatch("verif_machines/getItemsPerProducer", {
            producer_id: this.producer_id,
            verif_category: this.dialogMode
          });
        },
        openVerifDialog(mode, item = null) {
          this.verifDialogMode = mode;
          this.selectedItem = item ? { ...item } : null;
          this.verifDialogIsOpen = true;
        },
        closeVerifDialog() {
          this.reloadVerifDone()
          this.verifDialogIsOpen = false;
        },
        clearKeeperInfo(item) {
          this.reloadVerifDone()
          this.selectedItem.keeper_info = null
        },
        getCompleteness(title){
          const inCategoryCount = this.items.filter(item =>
            item.category_name === title || item.category_name_en === title
          ).length;

          const doneInCategoryCount = this.items.filter(item =>
            (item.category_name === title || item.category_name_en === title) && item.last_done_date
          ).length;

          const localPluralCase = doneInCategoryCount > 1 ? 10 : 1
          switch (this.showMode) {
            case 'all':
              return `${doneInCategoryCount} / ${inCategoryCount} ` + this.$t('verif_machines.complete', localPluralCase)
              break
            case 'done':
              return `${doneInCategoryCount} / ${inCategoryCount} ` + this.$t('verif_machines.complete', localPluralCase)
              break
            case 'pending':
              return `${inCategoryCount - doneInCategoryCount} / ${inCategoryCount} ` + this.$t('verif_machines.still_to_do')
              break
          }
        },
        accessItem(item){
          if (Boolean(item.last_done_id)) this.viewItem(item)
          else this.addItem(item)
        },
        // TODO: refacto these three if all they do is
      //          call same function with different param
      viewItem(item) {
        this.openVerifDialog('view', item);
      },
        addItem(item) {
          this.openVerifDialog('add', item);
        },
        addItems() {
          this.openVerifDialog('multiple', this.selectedItems);
        },
        saveItem(item) {
          // TODO: move this to store?
            const date = new Date(item.done_at);
          const formattedDate = date.toISOString().split("T")[0];
          let v_item = {
            comment: item.comment,
            done_at: formattedDate,
            partner_ids: item.partner_ids,
            keep_info_after_closure: item.keep_info_after_closure,
            producer_id: this.producer_id,
            intervention_report_id: item.intervention_report_id,
            closure_report_id: item.closure_report_id,
          }
          let actionToDispatch = 'addItem'
          if (item.verif_id) {
            //single verif_done
            v_item.verif_id = item.verif_id
            // single edit
            if (item.pk) {
              v_item.pk = item.pk
              actionToDispatch= 'editItem'
            }
          }
          else {
            //multiple verif_done
            v_item.verif_ids = this.selectedItems.map(p => p.pk)
            // multiple edit
            if (this.selectedItems.every(item => item.last_done_id)) {
              v_item.pks = this.selectedItems.map(item => item.last_done_id)
              actionToDispatch= 'editMultiple'
            }
          }
          this.$store.dispatch(`verif_dones/${actionToDispatch}`, v_item).then(() => {
            this.$store.dispatch('snackbar/showSuccess', this.$t('snack.update_success'))
            this.selectedItems = [];
            this.reloadVerifDone()
          }).catch((e)=> {
            this.$store.dispatch("snackbar/showError",this.$t('error.default', e))
          })
        },
        toggleSelect(index, group) {
          if ((this.selectMultiple ==='selectionMultiple') && group.items[index].last_done_date) return
          if ((this.selectMultiple ==='editionMultiple') && !group.items[index].last_done_date) return

          const selected = this.selectedItems.findIndex(v => v.pk === group.items[index].pk)
          if (selected < 0 ) this.selectedItems.push(group.items[index])
          else this.selectedItems.splice(selected, 1);
        },
        isSelected(index, group) {
          const selected = this.selectedItems.findIndex(v => v.pk === group.items[index].pk)
          if (selected < 0) return false
          return true
        },
        getRowClass(item, index, group) {
          if (this.isSelected(index, group)) return 'selected-row';
          if (item.last_done_id) {
            return index % 2 === 0 ? 'dark-green-row' : 'light-green-row';
          } else {
            return index % 2 === 0 ? 'grey-row' : 'white-row';
          }
        },
        checkIsMobile() {
          this.isMobile = window.innerWidth < 900; // or use any breakpoint you consider as mobile
        },
        toggleCollapse(section) {
          const index = this.collapsedSections.indexOf(section);
          if (index === -1) {
            this.collapsedSections.push(section);
          } else {
            this.collapsedSections.splice(index, 1);
          }
        },
        isCollapsed(section) {
          return this.collapsedSections.includes(section);
        },
        formatList(list) {
          return Array.isArray(list) ? list.join("/") : "";
        },
        close() {
          this.$emit('close')
        },
        saveTodo(){
          this.$store.dispatch('todos/addItem', this.$store.getters.dataEdit('todos')).then(()=>{
            this.$store.dispatch("snackbar/showSuccess",this.$t('todos.label') + ' ' + this.$t("snack.create_success"))
          })
        },
        toggleShow() {
          const options = ['all', 'done', 'pending']
          if (this.selectMultiple == 'selectionMultiple') options.filter(option => option !== 'done')
            if (this.selectMultiple == 'editionMultiple') options.filter(option => option !== 'pending')
              const currentIndex = options.indexOf(this.showMode);
          const nextIndex = (currentIndex + 1) % options.length;
          this.showMode = options[nextIndex];
        },
    },
    watch: {
      groupedData(gr){
        if (Object.keys(gr).length == 1) {
          this.collapsedSections = Object.keys(gr)
        }
      },
        selectMultiple(value) {
          this.selectedItems = []
          switch (value) {
            case 'selectionMultiple':
              this.showMode = 'pending'
              break
            case 'editionMultiple':
              this.showMode = 'done'
              break
            case 'normal':
              this.showMode = 'all'
          }
        },
    },
    mounted() {
      this.checkIsMobile();
      window.addEventListener('resize', this.checkIsMobile);
      //  this.$store.dispatch('int rep / update edi', thing with producer id etc)
    },
    beforeDestroy() {
      window.removeEventListener('resize', this.checkIsMobile);
    },
    data() {
      return {
        selectedItems: [],
        isMobile: false,
        verifDialogIsOpen: false,
        collapsedSections: [],
        selectedItem: this.$store.state.verif_machines.struct,
        verifDialogMode: 'view', // 'view' or 'add'
        selectMultiple: 'normal',
        showMode: 'all'
      };
    },
  };
</script>

<style scoped>

.toolbar-scroll-group {
  display: flex;
  flex-direction: column;
  height: 100vh; /* Full viewport height */
}

.toolbar {
  display: flex;
  justify-content: space-around;
  background-color: #f8f9fa;
  padding: 1px;
  border-bottom: 1px solid #ddd;
}

.scrollable-content {
  flex: 1;
  overflow-y: auto;
  padding: 0px;
  background-color: #ffffff;
  overflow-x: hidden;
  max-height: calc(100vh - 215px)
}

.footer {
  flex-shrink: 0;
  background-color: #f8f9fa;
  padding: 0px;
  margin: 0px;
  border-top: 1px solid #ddd;
  text-align: center;
}

.table-container table {
  width: 100%;
  border-collapse: collapse;
}

.selected-row {
  background-color: #00BFFF;
}

.grey-row {
  background-color: #f2f2f2;
}

.white-row {
  background-color: #ffffff;
}

.dark-green-row {
  background-color: #D3F8D3;
}

.light-green-row {
  background-color: #E9FCE9;
}

.table tbody > tr:hover {
  background-color: #64B5F6; /* Change to your desired hover color */
}

.table {
  width: 100%;
  border-collapse: collapse;
}

.table th, .table td {
  text-align: left;
  padding: 5px;
  box-sizing: border-box; /* Ensures padding is included in the width calculation */
}

.group-text-container {
  display: flex;
  flex-direction: column; /* Ensures items stack */
}

.title-text {
  font-weight: 900; /* Equivalent to font-weight-black */
  font-size: 1.5rem; /* Adjust based on your needs */
  margin: 0;
  padding: 0;
  display: block; /* Ensures it stays on a separate line */
}

.subtitle-text {
  font-size: 1rem; /* Smaller font size */
  font-weight: normal;
  display: block; /* Forces it onto a new line */
  color: gray; /* Optional: lighter color */
}
</style>
