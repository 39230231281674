import moment from "moment";
import crud from "@/store/modules/crud";
import crud_service from "@/services/crud";
import i18n from "@/i18n.js";
import store from "@/store";

const path = "intervention_reports";
//TODO: add fields as in edition method
const struct = {
  date: moment().startOf("day").format("YYYY-MM-DD"),
  number: "",
  status: "",
  cause: "",
  action: "",
  todo: "",
  todo_is_risk: false,
  producer_id: null,
  alarm_code_id: null,
  partner_ids: [],
  affectation_ids: [],
  consumable_ids: [],
};
const edition_struct = {
  pk: struct.pk,
  date: struct.date,
  number: struct.number,
  status: struct.status,
  cause: struct.cause,
  action: struct.action,
  todo: struct.todo,
  producer_id: struct.producer_id,
  alarm_code_id: struct.alarm_code_id,
  partner_ids: struct.partner_ids,
  consumed_parts: [],
  affectations: [],
};
const generic = crud(path, struct);

const state = {
  ...generic.state,

  edition: Object.assign({}, edition_struct),
};

const getters = {
  ...generic.getters,

  edition: (state) => state.edition,
};

const mutations = {
  ...generic.mutations,

  cleanup_edition(state) {
    state.edition = Object.assign({}, edition_struct);
    state.edition.consumed_parts.splice(0, state.edition.consumed_parts.length);
    state.edition.affectations.splice(0, state.edition.affectations.length);
  },

  add_consumed(state, { id, stock_part_id, quantity, place_id }) {
    state.edition.consumed_parts.push({
      id: id,
      stock_part_id: stock_part_id,
      quantity: quantity,
      place_id: place_id,
    });
  },

  add_affectation(state, item){
    state.edition.affectations.push(item)
  },

  initEdition(state) {
    state.edition = Object.assign({}, edition_struct);
  },

  edition(state, response) {
    state.edition.pk = response.pk;
    state.edition.date = response.date;
    state.edition.number = response.number;
    state.edition.status = response.status;
    state.edition.cause = response.cause;
    state.edition.action = response.action;
    state.edition.todo = response.todo;
    state.edition.producer_id = response.producer_id;
    // instead of copying whole object maybe deal with it in views. assign producer to edit
    state.edition.producer = response.producer;
    // these two should not be sent to back
    state.edition.producer_type_id = response.producer.producer_type_id;
    state.edition.stockage_id = response.producer.stockage_id;
    state.edition.partner_ids = response.partner_ids;
    state.edition.alarm_code_id = response.alarm_code_id;
    state.edition.consumed_parts = response.consumables;
    state.edition.affectations = response.affectations;
  },

};

const actions = {
  ...generic.actions,

  initEdition({ commit }) {
    commit("initEdition");
  },

  getEdition({ commit }, pk) {
    // Get a single item (for edition)
    return new Promise((resolve) => {
      crud_service.fetch(`${path}/${pk}`).then((response) => {
        commit("edition", response);
        resolve(response);
      });
    });
  },

  delete({ commit }, pk) {
    // Cleanly delete an IR
    return new Promise((resolve) => {
      crud_service.delete(`${path}/edition`, pk).then(() => {
        commit("deleteItem", pk);
        resolve();
      });
    });
  },

  addAffectation({ commit }, item) {
    commit('add_affectation', item)
  },

  cleanupEdition({ commit }) {
    commit("cleanup_edition");
  },

  sendEdition({ state }, { pk, data }) {
    // Create/update a full report
    const payload = Object.assign({}, data);
    // prep data for back
    ['producer', 'producer_type_id', 'stockage_id'].forEach(key => {
        if (key in payload) delete payload[key];
      });
    let idsArray = []
    payload.partner_ids.forEach(item => {
      if (typeof item === 'object' && item !== null && item.id !== undefined) {
        idsArray.push(item.id);
      } else {
        idsArray.push(item);
      }
    });
    payload.partner_ids = idsArray
    payload.consumed_parts = payload.consumed_parts.map(function (i) {
      return { pk: i.pk, stock_part_id: i.stock_part_id, quantity: -Math.abs(i.quantity), place_id: i.place_id };
    });
    payload.affectations = payload.affectations.map(function (i) {
      let obj = {
        pk: i.pk,
        kind: i.kind,
        unit_id: i.unit_id,
        id_: i.id_,
        target_obj: i.target_obj,
      }
      // to handle edit, because I'm an idiot, #notProud
      if (i.target) obj.id_ = i.target

      switch (i.kind) {
        case 'p':
          if (!i.id_) obj.id_ = i.producer_id
          break
        case 'ss':
          obj.id_ = i.stock_state_id
          obj.target_obj = i.stock_state
          break
        case 'ir':
          obj.id_ = i.internal_repair_id
          obj.target_obj = i.internal_repair
          break
        case 'er':
          obj.id_ = i.external_repair_id
          obj.target_obj = i.external_repair
          break
        case 'ts':
          obj.id_ = 1
          obj.target_obj = 1
      };
      return obj;
    })

    var method_ = crud_service.post;
    var url = path + "/edition";
    if (pk) {
      method_ = crud_service.put;
    }
    return new Promise((resolve) => {
      method_(url, payload).then((response) => {
        resolve(response);
      });
    });
  },

  updateEdition({commit}, obj){
    commit("edition", obj);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
