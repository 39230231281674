<template>
  <v-dialog v-model="dialogVisible">
    <v-card>
      <SelectPendingRest
        v-if="showSelectPendingRestDialog && selectedItem"
        v-model="showSelectPendingRestDialog"
        :item="selectedItem"
        @close="onCloseEditorPendingRestDialog"
        @saved="fetchPendingRest"
      />

      <v-card-title class="headline">
        {{ dialogTitle }}
      </v-card-title>

      <v-card-text v-if="groupedPendingRest.length > 0">
        <v-expansion-panels focusable accordion multiple>
          <v-expansion-panel v-for="group in groupedPendingRest" :key="group.user?.id">
            <v-expansion-panel-title>
              <strong>{{ group.user.name }}</strong>
            </v-expansion-panel-title>
            <v-expansion-panel-text>
              <v-data-table
                :headers="appointmentHeaders"
                :items="group.appointments"
                :hide-default-footer="true"
                item-value="id"
                class="elevation-1"
                dense
              >
                <template v-slot:[`item.start`]="{ item }">
                  {{ $filters.formatDateTimeCustom(item.start) }}
                </template>

                <template v-slot:[`item.end`]="{ item }">
                  {{ $filters.formatDateTimeCustom(item.end) }}
                </template>

                <template v-slot:[`item.daysRemaining`]="{ item }">
                  <span :class="getDaysRemainingClass(item.daysRemaining)">
                    {{ item.daysRemaining }}
                  </span>
                </template>

                <template v-slot:[`item.actions`] = "{ item }">
                  <v-btn color="primary" elevation="3" class="mr-3" @click.prevent="openSelectPendingRestDialog(item)" :disabled="!canGenerateRest(item)">
                    {{ $t('scheduler.actions.generate_rest') }}
                  </v-btn>
                </template>

                <template v-slot:item.is_remote="{ item }">
                  <v-icon v-if="item.is_remote" color="green">mdi-check</v-icon>
                  <v-icon v-else color="red">mdi-close</v-icon>
                </template>
              </v-data-table>
            </v-expansion-panel-text>
          </v-expansion-panel>
        </v-expansion-panels>

        <v-alert v-if="fetchError" type="error" class="mt-3">
          {{ fetchError }}
        </v-alert>
      </v-card-text>

      <v-divider></v-divider>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn text @click="closeDialog">{{ $t("close") }}</v-btn>
        <v-btn small @click="fetchPendingRest">{{ $t("refresh") }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import SelectPendingRest from "./SelectPendingRest.vue";

export default {
  name: "DateRangeDialog",
  
  components: {
    SelectPendingRest,
  },

  props: {
    value: Boolean,
  },

  data() {
    return {
      appointmentHeaders: [
        { title: this.$t("scheduler.name"), key: "name" },
        { title: this.$t("scheduler.start"), key: "start" },
        { title: this.$t("scheduler.end"), key: "end" },
        { title: this.$t("scheduler.remote"), key: "is_remote" },
        { title: this.$t("scheduler.duration"), key: "duration" },
        { title: this.$t("scheduler.days_remaining"), key: "daysRemaining" },
        { title: this.$t("scheduler.half_day_count"), key: "half_day_count" },
        { title: this.$t("scheduler.generate_rest"), key: "actions" },
      ],
      dialogVisible: this.value,
      isLoading: false,
      fetchError: null,
      pendingRest: [],
      groupedPendingRest: [],
      showSelectPendingRestDialog: false,
      selectedItem: null,
    };
  },

  computed: {
    dialogTitle() {
      return this.$t("Date Ranges");
    },
  },

  watch: {
    value(newValue) {
      this.dialogVisible = newValue;
      if (newValue) {
        this.fetchPendingRest();
      }
  },
    dialogVisible(newValue) {
      if (newValue) this.fetchPendingRest();
      this.$emit("input", newValue);
    },
    showSelectPendingRestDialog(newValue) {
      if (!newValue) this.fetchPendingRest();
    },
  },

  methods: {

    closeDialog(){
      this.dialogVisible = false;
      this.$emit("close");
    },
    canGenerateRest(item) {
      return item.time_remaining >= 12;
    },
    onCloseEditorPendingRestDialog() {
      this.showSelectPendingRestDialog = false;
    },

    openSelectPendingRestDialog(item) {
      this.selectedItem = item;
      this.showSelectPendingRestDialog = true;
    },

    getDaysRemainingClass(daysRemaining) {
      if (daysRemaining === this.$t("Past Due")) return "text-error";
      return daysRemaining <= 5 ? "text-warning" : "text-success";
    },

    groupByUser(data) {
      return data.reduce((acc, item) => {
        if (!item || !item.user) return acc;

        let userGroup = acc.find((u) => u.user.name === item.user.name);
        
        const appointment = {
          id: item.id,
          name: item.name,
          start: item.start,
          end: item.end,
          duration: item.duration,
          time_remaining: item.time_remaining,
          is_remote: item.is_remote,
          user: item.user,
          half_day_count: item.half_day_count,
          daysRemaining: this.$store.getters["appointments/calculateDaysRemaining"](item.start),
        };

        if (userGroup) {
          userGroup.appointments.push(appointment);
        } else {
          acc.push({
            user: item.user,
            appointments: [appointment],
          });
        }

        return acc;
      }, []);
    },

    fetchPendingRest() {
      this.isLoading = true;
      this.fetchError = null;

      const payload = { users: this.$store.state.users.user_actual_regions_ids };

      this.$store
        .dispatch("appointments/getPendingRest", payload)
        .then((response) => {
          if (Array.isArray(response) && response.length > 0) {
            this.pendingRest = response.map((item) => ({
              ...item,
              userName: item.user?.name || "Unknown",
              daysRemaining: this.$store.getters["appointments/calculateDaysRemaining"](item.start),
            }));
            this.groupedPendingRest = [];
            this.$nextTick(() => {
              this.groupedPendingRest = this.groupByUser(this.pendingRest);
            });
          } else {
            this.pendingRest = [];
            this.groupedPendingRest = [];
            this.selectedItem = null;
          }
        })
        .catch((error) => {
          console.error("Error fetching pending rest:", error);
          this.fetchError = error?.message || this.$t("Failed to fetch pending rest");
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
  },
};
</script>

<style>
.text-error {
  color: red;
}
.text-warning {
  color: orange;
}
.text-success {
  color: green;
}
</style>
