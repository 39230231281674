<template>
        <v-icon
            color="red"
            @click="setCurrentStockToZero(item)"
            icon="mdi-alert"/>
</template>
<script>
  import { alertPopup } from "@/functions.js"
  export default {
  props: {
    item: Object,
  },
  methods: {
    setCurrentStockToZero(item) {
      alertPopup(this.$t("dialog.to_zero_confirm")).then((result) => {
        if (result) {
          this.$store
            .dispatch("parts/setStockToZero", {
              stock_part_id: item.stock_part_id,
              stockage_id: item.stockage_id,
            })
            .then(() => {
              this.$emit("success", item.pk)
            });
        }
      });
    },
  },
};

</script>
