<template>
  <v-container>
    <div style="display: none">
      <EditDialog
          v-bind:model="editAffectation"
          v-bind:moduleName="'affectations'"
          v-bind:btnName="''"
          v-bind:formTitle="$t('edit_item')"
          @save="saveEvent()"
          @close="cleanupDialog()"
          ref="editDialogAff"
          >
          <template v-slot:edit_content>
            <AffectationForm
                v-bind:askKind="false"
                @save="saveEvent()"
                v-bind:showSerial="false"
                ></AffectationForm>
          </template>
      </EditDialog>
      <SimpleDialog
          v-if='isFromStaff'
          v-bind:model="editNdS"
          v-bind:formTitle="$t('units.actions.edit_serial')"
          v-bind:btnName="''"
          @add="updateNdS()"
          @close="cleanupNdSDialog()"
          >
          <template v-slot:content>
            <v-text-field
                variant="solo"
                :label="$filters.capitalize($t('units.serial'))"
                v-model="$store.state.units.edit.serial"></v-text-field>
            <v-text-field
                variant="solo"
                :label="$filters.capitalize($t('units.code'))"
                v-model="$store.state.units.edit.qr_code"></v-text-field>
            <v-btn
                color="primary darken-1"
                class="mb-2"
                @click.stop="updateNdS"> {{ $filters.capitalize($t("save")) }}
            </v-btn>
          </template>
      </SimpleDialog>
    </div>

    <!-- ========================== End dialogs ======================== -->
    <v-toolbar ext color="white" elevation="1" class="my-3 px-4">
      <v-icon @click="routerBack()">mdi-arrow-left</v-icon>
      <v-spacer></v-spacer>
      <v-toolbar-title style="font-weight: bolder" class="center pa-2 pb-3">{{ $filters.capitalize(
        $t(pageTitle, 1))
        }}
      </v-toolbar-title>
      <v-spacer></v-spacer>
    </v-toolbar>
    <v-card>
      <StockPartInfo/>
    </v-card>

    <!-- unit information -->
    <div v-show=is_unit>
      <v-card class="pa-2 mt-2" color='light-blue'>
        <v-row density= 'compact' class="pa-2 mt-2" align='end'>
          <v-col cols='5' class='text-body-1'>
            {{ $filters.capitalize($t("part_view.serials")) }}:
            <b>{{ $store.state.units.edit.serial }}</b>
          </v-col>
          <v-col class='text-body-1'>
            <span v-show="isNone(qr_code)">
              <ScanCodeButton
                  v-bind:btnName="'Add code'"
                  v-bind:newCode="true"
                  @decoded="qrCodeDecodedEvent"
                  ></ScanCodeButton>
            </span>
            <span v-show="!isNone(qr_code)">
              {{$filters.capitalize($t('units.code'))}}:
              <b>{{ qr_code }}</b>
            </span>
          </v-col>
          <v-col class='text-right'>
            <v-icon
                v-show="isFromStaff"
                small
                class="mr-2 pl-2"
                @click="editNdS=true"
                >mdi-pencil
            </v-icon>
          </v-col>
        </v-row>
        <v-divider color="white">
        </v-divider>
        <v-row class='pa-2' align='end'>
          <v-col
              cols='6'
              class='text-h3'
              v-tooltip:start='$filters.capitalize($t("affectations.current"))'
              >
              {{$filters.capitalize($filters.affectationKindFilter(last_state))}}
          </v-col>
            <v-col cols='6'>

              <p class="text-h4"
                 v-if="$store.state.units.edit.place && last_state == 'ss'"
                 >
                 {{ $filters.capitalize($store.state.units.edit.place.stockage.name) }} -
                 {{ $filters.capitalize($store.state.units.edit.place.name) }}
                 <v-icon
                     v-show="toListEnabled"
                     v-tooltip:top="{text: toListTooltipText}"
                     @click="toListBtn()"
                     >mdi-city
                 </v-icon>
              </p>
              <p
                  v-if="$store.state.affectations.items[0]?.producer && last_state == 'p'"
                  class="text-h4"
                  >
                  {{ $filters.capitalize($store.state.affectations.items[0].producer.display_name) }}
                  <v-icon
                      v-show="toMachineEnabled"
                      v-tooltip:top="{text: toMachineTooltipText}"
                      @click="toMachineBtn()"
                      >mdi-lightning-bolt-circle
                  </v-icon>
              </p>
            </v-col>
        </v-row>
      </v-card>
      <v-card class="pa-2">
        <!-- new affectation buttons -->
        <v-card class="elevation-0  pa-2 d-flex flex-wrap align-center justify-space-around">
          <!-- TODO: make it neater, v-for with array -->
          <v-btn
              class="my-2"
              :disabled="btnsDisabled('ss')"
              @click="openDialog('ss')"
              >
              <v-icon>mdi-arrow-right-bold</v-icon>
              {{ $filters.capitalize($t("stockages.label", 1)) }}
          </v-btn>
          <v-btn
              class="my-2"
              :disabled="btnsDisabled('p')"
              @click="openDialog('p')"
              >
              <v-icon>mdi-arrow-right-bold</v-icon>
              {{ $filters.capitalize($t("units.machine", 1)) }}
          </v-btn>
          <v-btn
              class="my-2"
              :disabled="btnsDisabled('er')"
              @click="openDialog('er')"
              >
              <v-icon>mdi-arrow-right-bold</v-icon>
              {{ $filters.capitalize($t("external_repairs.label", 1)) }}
          </v-btn>
          <v-btn
              class="my-2"
              :disabled="btnsDisabled('ir')"
              @click="openDialog('ir')"
              >
              <v-icon>mdi-arrow-right-bold</v-icon>
              {{ $filters.capitalize($t("internal_repairs.label", 1)) }}
          </v-btn>
          <v-btn
              class="my-2"
              :disabled="btnsDisabled('ts')"
              @click="openDialog('ts')"
              >
              <v-icon>mdi-arrow-right-bold</v-icon>
              {{ $filters.capitalize($t("trash_state.name", 1)) }}
          </v-btn>
        </v-card>

        <!-- history and its reveal button -->
        <v-card-actions>
          <v-btn
              variant='tonal'
              block
              color='primary'
              v-tooltip:top="$filters.capitalize($t('affectations.history'))"
              @click="reveal = !reveal"
              :icon='reveal ? "mdi mdi-arrow-collapse-up" : "mdi mdi-arrow-expand-down" '
              >
          </v-btn>
        </v-card-actions>
        <v-expand-transition>
          <v-card
              v-if='reveal'
              flat
              >
              <v-card
                  class="my-1 px-2"
                  v-for="item in $store.state.affectations.items"
                  :key="item.pk"
                  flat
                  >
                  <v-row>
                    <v-col class="text-left pt-3">{{ $filters.formatDate(item.date) }}</v-col>
                    <v-col class="text-left pt-3">{{ $filters.capitalize($filters.affectationKindFilter(
                      item.kind))
                      }}
                    </v-col>
            <v-col class="text-left pt-3">
              {{ item.producer? $filters.capitalize(item.producer?.display_name) : ''}}
              {{ item.stock_state? $filters.capitalize(item.stock_state?.stockage.name) : ''}}
            </v-col>
            <v-col class="text-right" v-if='isFromStaff'>
              <v-icon class="px-4" medium @click="editItemBtn(item)"
                      >mdi-pencil
              </v-icon
              >
              <v-icon class="px-4" medium @click="deleteItemBtn(item)"
                      >mdi-delete-empty-outline
              </v-icon
              >
            </v-col>
                  </v-row>
                <v-divider></v-divider>
              </v-card>
          </v-card>
        </v-expand-transition>
      </v-card>
    </div>
    <div v-show='!is_unit && !is_empty'>
      <v-card class="pa-2 mt-2"
              :text="$t('units.not_a_unit',1)"
              >
      </v-card>
    </div>
    <!--- TODO: make it work!
      <div v-show="is_empty" class="mt-14 text-center">
      <ScanCodeButton
      v-bind:btnName="'Read code'"
      v-bind:newCode="false"
      @fetchedUnit="getQRCodeEvent"></ScanCodeButton>
      </div>
      --->
  </v-container>
</template>
<script>
  import filters from "@/mixins/filters";
  import { isNone, alertPopup } from "@/functions";
  import utils from "@/mixins/utils";
  import EditDialog from "@/components/EditDialog";
  import AffectationForm from "@/components/AffectationForm";
  import ScanCodeButton from "@/components/qrcode/ScanCodeButton";
  import StockPartInfo from "@/components/StockPartInfo";
  import SimpleDialog from "@/components/SimpleDialog.vue";

  export default {
    components: {
      EditDialog: EditDialog,
      AffectationForm,
      ScanCodeButton,
      SimpleDialog,
      StockPartInfo,
    },
    mixins: [filters, utils],
    methods: {
      isNone, alertPopup,
      toEditBtn() {
        let query = {};
        if (this.$store.state.units.edit.place){
          query["stockage_id"] = this.$store.state.units.edit.place.stockage_id;
        }
        query["stock_part_id"] = this.$store.state.units.edit.stock_part_id;
        this.$router.push({
          name: "part_edition",
          query: query
        });
      },

      toListBtn() {
        // button enabled only when producer_type exists and is unique
        let query = {};
        query["producer_type_id"] =
          this.$store.state.units.edit.stock_part.category.producer_type_ids[0];
        query["stockage_id"] = this.$store.state.units.edit.place.stockage_id;
        this.$router.push({ name: "part_view", query: query });
      },

      toMachineBtn() {
        let query = {};
        query["producer_id"] =
          this.$store.state.affectations.items[0].producer_id;
        this.$router.push({
          name: "intervention_reports",
          query: query
        });
      },
      routerBack(){
        this.$router.go(-1)
      },

      openDialog(kind) {
        this.cleanupDialog();
        this.updateSubFormStock(kind)
        let default_aff = {
          kind: kind,
          ...this.$store.state["affectations"].edit
        };
        default_aff["unit_id"] = this.part;
        default_aff["date"] = new Date().toISOString();
        this.$store.dispatch("affectations/updateEdit", default_aff).then(() => {
          this.$store
            .dispatch("updateAffectationTarget", {
              type: kind,
              id: 1
            })
            .then(() => {
              this.editAffectation = true;
            });
        });
      },

      saveEvent() {
        // cleaning object, #notProud
        let thing = this.$store.state.affectations.edit
        if (thing.producer_id && typeof(thing.producer_id)=='object') thing.producer_id = thing.producer_id.pk
        if (thing.stock_state && typeof(thing.stock_state)=='object') thing.stock_state_id = thing.stock_state.pk
        if (thing.stock_state_id && typeof(thing.stock_state_id)=='object') thing.stock_state_id = thing.stock_state_id.pk
        delete thing.internal_repair?.assigned_to
        delete thing.external_repair?.stockage
        this.$store
          .dispatch(`affectations/affect`, thing)
          .then(() => {
            this.getAffectations();
            this.$refs.editDialogAff.closeDialog();
          });
      },

      updateNdS(){
        let edit_object = this.$store.state.units.edit
        // TODO: check ids and take out objects before sending to back
        // TODO simple: take out created_by and stock_part (leave only ids)
        //        Object.keys(obj_clean).forEach(key=>{console.log(obj_clean[key], typeof(obj_clean[key]))})
        this.$store.dispatch('units/editItem', edit_object).then((item)=>{
          this.qr_code = item.qr_code;
          this.editNdS=false
        })
      },

      cleanupNdSDialog(){
        this.$store.dispatch('units/getItem', this.$store.state.units.edit.pk).then(()=>{this.editNdS=false})
      },

      cleanupDialog() {
        this.editAffectation = false;
        this.$store.dispatch(
          `affectations/updateEdit`,
          this.$store.state["affectations"].struct
        );
        this.$store.dispatch(
          `internal_repairs/updateEdit`,
          this.$store.state["internal_repairs"].struct
        );
        this.$store.dispatch(
          `external_repairs/updateEdit`,
          this.$store.state["external_repairs"].struct
        );
        this.$store.dispatch(
          `producers/updateEdit`,
          this.$store.state["producers"].struct
        );
        this.$store.dispatch(
          `stockages/updateEdit`,
          this.$store.state["stockages"].struct
        );
      },

      getAffectations() {
        this.$store
          .dispatch("affectations/getItems", { unit_id: this.part })
          .then((affs) => {
            if (affs && affs[0].stock_state) this.$store.dispatch('units/setEditPlace', affs[0].stock_state)
          });
        // TODO: every trackedpart should have at least one affectation.
      },

      editItemBtn(item) {
        this.$store.dispatch("affectations/updateEdit", item);
        switch (item.kind) {
          case "ir":
            this.$store
              .dispatch("internal_repairs/getItem", item.internal_repair_id)
              .then(() => {
                this.editAffectation = true;
              });
            break;
          case "er":
            this.$store
              .dispatch("external_repairs/getItem", item.external_repair_id)
              .then(() => {
                this.editAffectation = true;
              });
            break;
          case "p":
            this.$store
              .dispatch("producers/getItem", item.producer_id)
              .then(() => {
                this.editAffectation = true;
              });
            break;
          case "ss":
            this.$store.dispatch("stockages/updateEdit", item.stock_state.stockage)
            this.$store.dispatch("places/updateEdit", item.stock_state)
            this.editAffectation = true;
            break;
          default:
            this.editAffectation = true;
        }
      },

      deleteItemBtn(item) {
        alertPopup(this.$t("dialog.delete_confirm")).then((result) => {
          if (result) {
            this.$store.dispatch("affectations/deleteItem", item.pk).then(() => {
              this.$store.dispatch(
                "snackbar/showSuccess",
                this.$t("snack.update_success")
              );
            });
          }
        });
      },

      qrCodeDecodedEvent(code) {
        this.$store
          .dispatch("unit_codes/assign_code", {
            qr_code: code,
            unit_id: this.part
          })
          .then((r_) => {
            if (r_.qr_code != code) {
              this.$store.dispatch(
                "snackbar/showError",
                "unit already has a code assigned"
              );
              this.qr_code = "";
              return;
            }
            this.qr_code = code;
          });
      },

      getQRCodeEvent(item) {
        //this.qr_code = code;
        this.qr_code = item.qr_code;
        this.getAffectations();
      },
      loadQRCodeEvent(code) {
        this.qr_code = code;
        this.$store
          .dispatch("unit_codes/getItems", { qr_code: code })
          .then((d_) => {
            if (d_.length > 0) {
              this.part = d_[0].pk;
              this.loadUnitEvent();
            } else {
              this.$store.dispatch(
                "snackbar/showError",
                this.$t("snack.no_qr")
              );
            }
          });
      },
      loadUnitEvent() {
        this.$store.dispatch("units/getItem", this.part).then((item) => {
          this.qr_code = item.qr_code;
          this.$store.dispatch('stock_parts/updateEdit', item.stock_part);
          this.getAffectations();
        });
      },
      updateSubFormStock(kind) {
        let last_stock_state = this.affectations?.find(item => item.stock_state)?.stock_state
        switch (kind) {
          case 'er':
            let base_er = {
              ...this.$store.state.external_repairs.struct,
              stockage: last_stock_state?.stockage,
              stockage_id: last_stock_state?.stockage_id,
            }
            this.$store.dispatch('external_repairs/updateEdit',base_er)
            break;
          case 'ss':
            this.$store.dispatch('places/updateEdit',last_stock_state)
            this.$store.dispatch('stockages/updateEdit',last_stock_state?.stockage)
            break;

        }
      },
      btnsDisabled(btn_ref) {
        // now currently in use but functionality may be requested later
        return false
      },
    },

    mounted() {
      // load by QR code
      let qr = this.$route.query.qr_code;
      if (!isNone(qr)) {
        this.qr_code = qr;
        this.loadQRCodeEvent(qr);
        return;
      }
      // load by trackedPart id
      this.part = parseInt(this.$route.query.part) || undefined;
      if (!isNone(this.part)) {
        this.loadUnitEvent();
      }
    },

    computed: {
      unit: function(){
        return this.$store.state.units.edit
      },
      affectations: function(){
        return this.$store.state.affectations.items
      },
      last_state: function() {
        if (this.affectations.length > 0)
          return this.affectations[0].kind;
        return "";
      },
      last_stock_state: function() {
        return this.affectations.find(item => item.stock_state)?.stock_state
      },
      toMachineEnabled: function() {
        if (isNone(this.part)) return false;
        if (
          this.affectations?.length > 0 &&
          this.affectations[0].kind == "p"
        )
          return true;
        return false;
      },
      toMachineTooltipText: function() {
        if(!this.toMachineEnabled) return ''
        return this.$filters.capitalize(this.$t('intervention_reports.label', 10)) + ': ' +
          this.$store.state.affectations.items[0].producer.display_name
      },
      toListEnabled: function() {
        if (isNone(this.part)) return false;
        if (
          !isNone(this.unit) ||
          this.unit.stock_part.category.producer_type_ids.length === 1
        )
          return true;
        return false;
      },
      toListTooltipText: function() {
        if (!this.toListEnabled) return ''
        return this.$filters.capitalize(this.$t('stockages.label',1)) + ': ' +
          this.affectations[0].stock_state.stockage.name
      },
      external_repair_edit: function() {
        return this.$store.state["external_repairs"].edit;
      },
      internal_repair_edit: function() {
        return this.$store.state["internal_repairs"].edit;
      },
      producer_edit: function() {
        return this.$store.state["producers"].edit;
      },
      is_unit: function() {
        try {
          return this.unit.stock_part.unity == 'un';
        } catch {}
      },
      is_empty: function(){
        try {
          return isNone(this.unit.pk);
        } catch {}
      },
    },

    data() {
      return {
        pageTitle: "units.label",
        moduleName: "units",
        part: undefined,
        qr_code: "",
        editAffectation: false,
        editInternalRepair: false,
        editExternalRepair: false,
        editMachine: false,
        editStockage: false,
        editNdS:false,
        reveal:false,
        headers: [
          {
            title: this.$t("generic.date", 10),
            key: "date",
            sortable: false,
          },
          {
            title: this.$t("affectations.status.label", 10),
            key: "kind",
            sortable: false,
          },
          {
            title: this.$t("units.machine", 10),
            key: "producer.display_name",
            sortable: false,
          },
          // TODO: add stockage
          // TODO: add ref to intervention_report
          // TODO++: a column that shows relevant info
          // {
          //   title: this.$t("intervention_reports.label", 1),
          //   key: "affectation",
          //   sortable: false,
          // },
          {
            title: this.$t("actions.label"),
            key: "actions",
            sortable: false,
          }
        ]
      };
    }
  };
</script>
