<template>
              <v-list-item
                v-for="tem in docitem"
                active-class="secondary"
                :key="tem.title"
                :to="tem.path == '#' ? '' : tem.path"
                :prepend-icon="tem.icon"
                :title="$filters.capitalize($t(tem.title, 1))"
                density="compact"
                link
              >
              </v-list-item>

</template>
<script>
  export default {
    data() {
      return {
        docitem: [
          {
            title: "menu.conduite",
            icon: "mdi-book-open-page-variant",
            path: "/conduite_france",
          },
          {
            title: "menu.procedures",
            icon: "mdi-book-open-page-variant",
            path: "/procedures",
          },
          {
            title: "menu.inspections",
            icon: "mdi-book-open-page-variant",
            path: "/procedures",
          },
          {
            title: "menu.travaux",
            icon: "mdi-book-open-page-variant",
            path: "/travaux",
          },
        ]
      }
    },
  }
</script>
