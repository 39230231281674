<template>
  <v-container>
<InterventionReportEdition
    :prefilled='true'
    @saved='newRepCreated'
/>
  </v-container>
</template>

<script>
import InterventionReportEdition from "@/views/InterventionReportEdition";


export default {
  name: 'InterventionReportForVerif',
  components: {
    InterventionReportEdition,
  },
  props: {
    verif: {
    }
  },
  methods: {
    newRepCreated(rep) {
      let verif_ids = this.verif.map(p => p.last_done_id)
      this.$store.dispatch('verif_dones/assignIntRep', {
        verif_ids: verif_ids,
        intervention_report_id: rep.pk,
      })
      this.$emit('saved', rep)

    },
  },
  mounted(){
  },
}
</script>
