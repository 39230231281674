<template>
  <v-dialog
    v-model="editorShown"
    content-class="v-dialog--custom"
    @click:outside="clickOutside"
  >
    <v-card>
      <v-card-title
        :class="'headline ' + titleColorClass"
        primary-title
      ></v-card-title>

      <v-card-text class="pt-5">
        <v-row v-if="isAnticipatedRest">
          <v-col cols="12" md="6">
            <v-select
              v-model="selectedAnticipatedDay"
              :items="availableAnticipatedDays"
              item-title="anticipated_date"
              item-value="anticipated_date"
              :label="$filters.capitalize($t('scheduler.anticipated_rest_date'))"
              :loading="isFetchingAnticipatedDays"
              hide-details
              return-object
              @change="handleAnticipatedDayChange"
              @focus="fetchAvailableAnticipatedDay"
            ></v-select>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12" md="6">
            <v-select
              v-if="!isAnticipatedRest"
              v-show="!isOnCallDutyTriggered || isFromStaff"
              v-model="eventIlk"
              :disabled="isLocked"
              :items="ilks"
              item-title="label"
              item-value="value"
              :label="$filters.capitalize($t('scheduler.ilk.label', 1) )"
              @change="onIlkChoice($event)"
              variant="solo"
            ></v-select>
          </v-col>
          <v-col cols="12" md="6">
            <v-autocomplete
              v-if="!isAnticipatedRest"
              v-model="eventProducerId"
              v-show="showProducerSelect"
              :items="$store.state.producers.all"
              item-title="display_name"
              item-value="pk"
              :label="$filters.capitalize($t('producers.label', 1) )"
              :loading="$store.state.producers.isSearching"
              :filter="filterProducers"
              :clearable="true"
              append-icon="mdi-text-box-search"
              density="compact"
              hide-details
              @update:modelValue="computeEventName($event)"
              return-object
            >
              <template v-slot:no-data>
                <v-list-item>
                  <v-list-item-title>
                    {{
                      $t("search_for", { noun: $filters.capitalize($t('producers.label', 1) ) })
                    }}
                  </v-list-item-title>
                </v-list-item>
              </template>
            </v-autocomplete>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12" md="6">
            <v-text-field
              v-if="!isAnticipatedRest"
              v-model="eventName"
              :disabled="isLocked"
              :label="$filters.capitalize($t('scheduler.name', 1) )"
              class="pb-3 event-name"
            ></v-text-field>
          </v-col>
          <v-col cols="12" md="6" v-if="!isAnticipatedRest">
            <v-radio-group v-model="isRemote" :disabled="isLocked" row>
              <v-radio
                :label="$filters.capitalize($t('scheduler.is_remote.remote', 1))"
                :value="true"
                @input="onRemoteChange(true)"
              ></v-radio>
              <v-radio
                :label="$filters.capitalize($t('scheduler.is_remote.site', 1))"
                :value="false"
                @input="onRemoteChange(false)"
              ></v-radio>
            </v-radio-group>
          </v-col>
        </v-row>

        <v-textarea
          v-if="!isAnticipatedRest"
          v-model="eventDescription"
          :disabled="isLocked"
          :label="$filters.capitalize($t('scheduler.description', 1) )"
          :height="80"
          variant="solo"
          no-resize
          ></v-textarea>
          <v-row>
            <v-col>
              <DatePartPicker
                v-if="!isOnCallDutyTriggered && !isAnticipatedRest"
                :value="startDate"
                :isLocked="isLocked"
                :label="'scheduler.start'"
                :isStart='true'
                :isPart='true'
                @inputDateTimePart="handleDateInputStartDate"
              />
            </v-col>
            <v-col>
              <DatePartPicker
                v-if="!isOnCallDutyTriggered && !isAnticipatedRest"
                :value="endDate"
                :isLocked="isLocked"
                :label="'scheduler.end'"
                :isStart="false"
                @inputDateTimePart="handleDateInputEndDate"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <DateTimePicker
                v-if="isOnCallDutyTriggered"
                :value="startDate"
                :isLocked="isLocked"
                :label="'scheduler.start'"
                :isStart='true'
                @inputDateTimePicker="handleDateInputStartDate"
              />
            </v-col>
            <v-col>
              <DateTimePicker
                v-if="isOnCallDutyTriggered"
                :value="endDate"
                :isLocked="isLocked"
                :label="'scheduler.end'" 
                :isStart="false"
                @inputDateTimePicker="handleDateInputEndDate"
              />
            </v-col>
          </v-row>
          <v-row>
              <v-col cols="12">
                <div v-if="showAutocomplete">
                  <v-autocomplete
                    v-model="selectedUsers"
                    :items="$store.state.users.user_actual_regions"
                    item-title="name"
                    item-value="id"
                    :label="$filters.capitalize($t('scheduler.user_actual_regions', 1) )"
                    multiple
                    :clearable="true"
                    append-icon="mdi-account-multiple"
                    density="compact"
                    hide-details
                  ></v-autocomplete>
                </div>
              </v-col>
          </v-row>
        <v-checkbox
          v-show="!isOnCallDutyTriggered"
          v-model="isLocked"
          v-if="isFromStaff"
        >
          <template #label>
            <span v-if="isLocked">{{
              $filters.capitalize($t('scheduler.locked', 1) )
            }}</span>
            <span v-else>{{ $filters.capitalize($t('scheduler.locked_off', 1) )}}</span>

          </template>
        </v-checkbox>

        <v-container v-show="isLocked && !isFromStaff">
          <v-icon class="mr-2">mdi-pencil-lock</v-icon>
          {{ $filters.capitalize($t("scheduler.locked_event")) }}
        </v-container>
      </v-card-text>

      <v-divider></v-divider>

      <v-card-actions>
        <div class="flex-grow-1"></div>
        <v-btn
          color="primary"
          text
          @click="cancelEditor"
          >{{ $t("cancel") }}</v-btn
        >
        <v-btn
          :disabled="isLocked && !isFromStaff"
          color="primary"
          text
          @click="saveHandler"
          >{{ $t("save") }}</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import moment from "moment";
import DatePartPicker from '@/components/scheduler/DatePartPicker';
import DateTimePicker from '@/components/scheduler/DateTimePicker';
import filters from "@/mixins/filters";
import utils from "@/mixins/utils";
import { capitalize, isNone } from "@/functions.js";

export default {
  mixins: [utils, filters],
  name: "event-editor",

  components: {
    DatePartPicker,
    DateTimePicker
  },

  props: {
    value: [Boolean, Object],
    eventRecord: Object,
    eventStore: Object,
    resourceId: [String, Number],
  },

  mounted() {
    this.eventIlk = this.ilks[0]["value"];

  },

  watch: {
    eventIlk(eventIlk){
      this.checkAutocompleteVisibility();
      if(eventIlk == ""){
        this.selectedUsers = [this.resourceId];
      }
    },
    resourceId(resourceId) {
        for (const user of this.$store.state.users.user_actual_regions) {
          if (user.id === resourceId) {
            this.selectedUsers = [user.id];
            break;
          }
        }
    },
    eventRecord(value) {
      this.eventName = this.eventRecord ? this.eventRecord.name : '';
      this.eventDescription = this.eventRecord ? this.eventRecord.description : '';
      this.eventIlk = this.eventRecord ? this.eventRecord.ilk : '';
      this.isRemote = this.eventRecord ? this.eventRecord.is_remote : '';
      this.eventProducerId = this.eventRecord ? this.eventRecord.producer_id : '';
    },
  },

  methods: {
    fetchAvailableAnticipatedDay() {
      if (!this.isAnticipatedRest) return;
      if (!this.selectedUsers || this.selectedUsers.length === 0) {
        console.warn("No users selected.");
        return;
      }

      this.isFetchingAnticipatedDays = true;

      const payload = {
        user_ids: this.selectedUsers,
      };

      this.$store.dispatch('appointments/getDispoAnticipatedRest', payload)
        .then(response => {
            this.availableAnticipatedDays = response.anticipated_rest_days.map(day => ({
              anticipated_date: day.anticipated_date,
              on_call_duty_id: day.on_call_duty_id,
              users: day.users,
            }));
        })
        .catch(error => {
          this.availableAnticipatedDays = [];
          this.$store.dispatch("snackbar/showError", error.error);
        })
        .finally(() => {
          this.isFetchingAnticipatedDays = false;
        });
    },
    handleAnticipatedDayChange(day) {
      this.selectedAnticipatedDay = day;
    },
    checkAutocompleteVisibility() {
      this.showAutocomplete = this.eventIlk === "oncallduty" || this.eventIlk === "work" || this.isAnticipatedRest || this.eventIlk === "holiday";
    },
    computeEventName(event) {
      if (event !== undefined) {
        this.eventName = event.description + " " + this.eventName;
        this.eventProducerId = event.pk
        return;
      }
    },
    handleDateInputEndDate(event) {
      if (event instanceof Date) {
        this.endDate = event;
      } else {
        this.endDate = new Date(event.target.value);
      }
    },
    handleDateInputStartDate(event) {
      if (event instanceof Date) {
        this.startDate = event;
      } else {
        this.startDate = new Date(event.target.value);
      }
    },

    onRemoteChange(value) {
      this.isRemote = value;
    },

    cancelEditor() {
      this.canceled = true;
      this.editorShown = false;
      this.$emit("close", this.eventRecord, this.canceled);
    },

    saveHandler() {
      let start = moment(this.startDate);
      let end = moment(this.endDate);
      if(this.eventIlk.startsWith("oncallduty_rest_anticipated")){
        let date = moment(this.selectedAnticipatedDay['anticipated_date'], "YYYY-MM-DD");
        start = moment(date).startOf('day');
        end = moment(date).endOf('day');
      }
      this.canceled = false;

      if (isNone(this.eventName) || isNone(this.eventName.trim())) {
        this.$store.dispatch("snackbar/showError", this.$t("snack.no_name"));
        return false;
      }
      if (isNone(this.eventIlk)) {
        this.$store.dispatch("snackbar/showError", this.$t("snack.no_ilk"));
        return false;
      }
      if (isNone(this.eventDescription) && !this.isOnCallDutyEvent && !this.eventIlk.startsWith("oncallduty_rest_anticipated")) {
        this.$store.dispatch("snackbar/showError", this.$t("snack.no_description"));
        return false;
      }
      if (start >= end && !this.eventIlk.startsWith("oncallduty_rest_anticipated")) {
        this.$store.dispatch("snackbar/showError", this.$t("snack.start_after_end"));
        return false;
      }
      if (this.eventIlk.startsWith("oncallduty_triggered")) {
        if (start.hour() < 8) {
          this.$store.dispatch("snackbar/showError", this.$t("snack.too_early"));
          return false;
        }
        if (isNone(this.isRemote)) {
          this.$store.dispatch("snackbar/showError", this.$t("scheduler.snack.choose_remote"));
          return false;
        }
        if (!this.isRemote && (end - start) / (1000 * 60 * 60) < 1) {
          this.$store.dispatch("snackbar/showError", this.$t("snack.too_short"));
          return false;
        }
      }

      if (start <= moment().startOf("day").subtract(999, "days").toDate()) {
        //TODO not block the create
        this.$store.dispatch("snackbar/showError", this.$t("snack.start_too_old"));
        if (!this.isFromStaff) {
          return false;
        }
      }

      if (this.selectedUsers.length > 1) {
        this.selectedUsers.forEach(user => {
          const independentObject = {
            name: this.eventName.trim(),
            description: this.eventDescription,
            ilk: this.eventIlk,
            locked: this.isLocked,
            is_remote: this.isRemote,
            producer_id: this.eventProducerId,
            resourceId: user,
            startDate: start.toDate(),
            endDate: end.toDate(),
          } 
          this.eventStore.add(independentObject);
        });
        this.$emit("close", this.selectedUsers.map(user => this.eventRecord), this.canceled);
      } else {
        this.eventRecord.set({
          name: this.eventName.trim(),
          description: this.eventDescription,
          ilk: this.eventIlk,
          locked: this.isLocked,
          is_remote: this.isRemote,
          producer_id: this.eventProducerId,
          resourceId: this.resourceId,
          startDate: start.toDate(),
          endDate: end.toDate(),
        });
        if (!this.eventRecord.originalData.id) {
          const independentObject = {
            name: this.eventName.trim(),
            description: this.eventDescription,
            ilk: this.eventIlk,
            locked: this.isLocked,
            is_remote: this.isRemote,
            producer_id: this.eventProducerId,
            resourceId: this.selectedUsers[0],
            startDate: start.toDate(),
            endDate: end.toDate(),
          }
          this.eventStore.add(independentObject);
          this.$emit("close", independentObject, this.canceled);

        } else {
          this.eventStore.add(this.eventRecord);
          this.$emit("close", this.eventRecord, this.canceled);
        }
      }

      this.editorShown = false;
      this.selectedUsers = [this.resourceId];
      this.availableAnticipatedDays = [];
      this.selectedAnticipatedDay = null;
    },

    onIlkChoice(value) {
      this.isVacation = ["rest", "holiday"].includes(value);
      if (this.isVacation) {
        this.eventName = capitalize(this.$t(`scheduler.ilk.${value}`));
      }
      // Force isOnCallDutyTriggered recalculation to adapt form
      this.eventRecord.ilk = value;

      if (this.eventRecord.ilk != "oncallduty_triggered") {
        const start_ = moment(this.eventRecord.startDate)
          .startOf("day")
          .toDate();
        const end_ = moment(this.eventRecord.endDate).endOf("day").toDate();
        this.$store.dispatch("scheduler/setStart", start_);
        this.$store.dispatch("scheduler/setEnd", end_);
      }
    },

    clickOutside() {
      this.canceled = true;
    },
  },

  computed: {
    ...utils.isFromStaff,
    isOnCallDutyTriggered() {
      return (
        !isNone(this.eventRecord) &&
        this.eventRecord.ilk.startsWith("oncallduty_triggered")
      );
    },
    isOnCallDutyEvent() {
      return !isNone(this.eventRecord) && this.eventIlk === "oncallduty";
    },
    isAnticipatedRest(){
      return !isNone(this.eventRecord) && this.eventIlk === "oncallduty_rest_anticipated";
    },
    isWindProfil() {
      return this.$store.getters.isWindProfil;
    },
    showProducerSelect() {
      return !this.isVacation;
     // return this.isWindProfil && !this.isVacation;
    },
    titleColorClass() {
      if (!isNone(this.eventRecord) && !isNone(this.eventIlk)) {
        return this.eventIlk;
      }
      return "grey lighten-1";
    },
    startDate: {
      get() {
        return this.$store.state.scheduler.start;
      },
      set(value) {
        this.$store.dispatch("scheduler/setStart", value);
      },
    },
    endDate: {
      get() {
        return this.$store.state.scheduler.end;
      },
      set(value) {
        this.$store.dispatch("scheduler/setEnd", value);
      },
    },

    ilks() {
      var data = [
        {
          value: "work",
          label: capitalize(this.$t("scheduler.ilk.work")),  
        },
        {
          value: "holiday",
          label: capitalize(this.$t("scheduler.ilk.holiday")),
        },
        {
          value: "rest",
          label: capitalize(this.$t("scheduler.ilk.rest")),
        },
        {
          value: "trip",
          label: capitalize(this.$t("scheduler.ilk.trip")),
        },
        {
          value: "int_trip",
          label: capitalize(this.$t("scheduler.ilk.int_trip")),
        },
      ];
      if (this.isOnCallDutyTriggered || this.isFromStaff) {
        data = data.concat([
          {
            value: "oncallduty",
            label: capitalize(this.$t("scheduler.ilk.oncallduty")),
          },
          {
            value: "oncallduty_triggered",
            label: capitalize(
              this.$t("scheduler.ilk.oncallduty_triggered"),
            ),
          },
          {
            value: "oncallduty_rest",
            label: capitalize(this.$t("scheduler.ilk.oncallduty_rest")),
          },
        ]);
      }
      return data;
    },

    editorShown: {
      get() {
        const eventRecord = this.eventRecord;

        // we're opening so initialize data
        if (true === this.value) {
          if (eventRecord) {
            if (isNone(this.eventName) || isNone(this.eventName.trim())) {
              Object.assign(this, { eventName: eventRecord.data.name });
            }
            if (isNone(this.eventDescription)) {
              Object.assign(this, {
                eventDescription: eventRecord.data.description,
              });
            }
            if (isNone(this.eventProducerId)) {
              Object.assign(this, {
                eventProducerId: eventRecord.data.producer_id,
              });
            }
            if (isNone(this.isRemote)) {
              Object.assign(this, { isRemote: eventRecord.data.is_remote });
            }
            Object.assign(this, {
              eventIlk: eventRecord.data.ilk,
              isLocked: eventRecord.data.locked,
              isVacation: ["rest", "holiday"].includes(eventRecord.data.ilk), // consistency
              once: true,
            });
          }
        } else if (false === this.value) {
          if (this.once) {
            Object.assign(this, {
              eventName: "",
              eventDescription: "",
              eventProducerId: null,
              once: false,
            });
            this.$emit("close", eventRecord, this.canceled);
          }
        }
        // return always Boolean
        return !!this.value;
      },
      // only runs on close
      set(value) {
        const { eventRecord, eventStore } = this;
        if (eventRecord.isCreating) {
          eventStore.remove(eventRecord);
          eventRecord.isCreating = false;
        }
        this.$emit("input", value);
      },
    },
  }, // eo computed

  data() {
    return {
      availableAnticipatedDays: [],
      selectedAnticipatedDay: null,
      isFetchingAnticipatedDays: false,
      eventName: "",
      eventDescription: "",
      eventIlk: "",
      eventProducerId: null,
      selectedUsers: [this.resourceId],
      isLocked: false,
      isRemote: null,
      isVacation: false,
      once: true,
      canceled: false,
      showAutocomplete: false,
    };
  },
};
</script>


<style lang="scss">
.work {
  background-color: #018e48; // $ilk_work
}
.holiday {
  background-color: #f5db14; // $ilk_holiday
}
.rest {
  background-color: #dcf65a; // $ilk_rest;
}
.trip {
  background-color:#4b939b;  // $ilk_trip;
}
.int_trip {
  background-color: #1c77c3; // $ilk_int_trip;
}
.oncallduty {
  background-color: #bd6fef; // $ilk_oncallduty;
}
.oncallduty_triggered {
  background-color: #f23cb9;  // $ilk_oncallduty_triggered;
}
.oncallduty_rest {
  background-color: #755ddb; // $ilk_oncallduty_rest;
}
.oncallduty_rest_anticipated {
  background-color: #c21e1e; // $oncallduty_rest_anticipated;

}
:deep( .v-dialog--custom) {
  width: 80vw;
}
@media screen and (max-width: 600px) {
  :deep( .v-dialog--custom) {
    width: 100vw;
  }
}
/* Desktop */
@media screen and (min-width: 768px) {
  :deep( .v-dialog--custom) {
    width: 50vw;
  }
}
@media screen and (min-width: 1264px) {
  :deep( .v-dialog--custom) {
    width: 40vw;
  }
}
</style>
