<template>
  <v-container style="width: auto">
    <v-dialog
        v-model="showDialog"
        :content-class="content_class"
        :fullscreen="xs"
        @click:outside="closeDialog"
        >
        <template v-slot:activator="{ on, attrs }">
          <v-btn
              color="primary-darken-1"
              v-bind="attrs"
              :disabled="disabled"
              @click.stop="addItemBtn"
              >
              {{ $filters.capitalize(btnName) }}
              <v-progress-circular
                  class="ml-3"
                  v-show="loadingAddItem"
                  :indeterminate="true"
                  ></v-progress-circular>
          </v-btn>
        </template>

      <v-card>
        <v-card-title>
          <span class="headline">{{ $filters.capitalize(formTitle) }}</span>
        </v-card-title>

        <v-card-text>
          <v-container>
            <slot name="edit_content" />
          </v-container>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="secondary lighten-2" variant="text" @click="closeDialog">{{
            $filters.capitalize($t("cancel"))
            }}</v-btn>
          <v-btn color="success" @click="saveItem">{{
            $filters.capitalize($t("save"))
            }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
  import { useDisplay } from "vuetify";

  export default {
    props: {
      model: { type: Boolean, required: true },
      disabled: { type: Boolean, required: false, default: false },
      moduleName: { type: String, required: true },
      formTitle: { type: String, required: true },
      btnName: { type: String, required: false, default: "" },
      dataValidation: { type: Function, required: false },
      smallWidth: { type: Boolean, required: false, default: false },
    },

    computed: {
      showDialog: {
      get() {
        return this.model;
      },
      set(newValue) {
        // to avoid readonly warning
      },
    },
      content_class() {
        if (this.smallWidth) {
          return "v-dialog--small";
        }
        return "v-dialog--large";
      },
      xs() {
        return useDisplay().xs.value;
      },
    },

    methods: {
      addItemBtn() {
        this.$emit("add");
      },

      closeDialog() {
        this.loadingAddItem = false;
        this.$emit("close");
      },

      saveItem() {
        // Data validation hook
        if (typeof this.dataValidation === "function") {
          const message = this.dataValidation();
          if (message !== undefined) {
            this.$store.dispatch("snackbar/showWarning", message);
            return;
          }
        }
        this.loadingAddItem = true;
        this.$emit("save");
      },
    },

    data() {
      return {
        loadingAddItem: false,
      };
    },
  };
</script>

<style lang="scss">
:deep( .v-dialog--large) {
  width: 75vw;
  max-height: 100vh;
}
:deep( .v-dialog--small) {
  width: 75vw;
  max-height: 100vh;
}

@media screen and (max-width: 600px) {
  :deep( .v-dialog--large) {
    width: 100vw;
  }
  :deep( .v-dialog--small) {
    width: 100vw;
  }
}
/* Desktop */
@media screen and (min-width: 768px) {
  :deep( .v-dialog--small) {
    width: 40vw;
  }
}
@media screen and (min-width: 1264px) {
  :deep( .v-dialog--small) {
    width: 30vw;
  }
}
</style>
