<template>
  <v-container>
      <v-dialog
        v-model="restAdvice"
        max-width="35%"
        @click:outside="restAdvice = false"
      >
        <v-card>
          <v-card-title>
            <span class="headline">{{$filters.capitalize(
              $t("scheduler.rest_advice.title") )
            }}</span>
          </v-card-title>
  
          <v-card-text>
            <v-container>
              {{$filters.capitalize( $t("scheduler.rest_advice.text") ) }}<br />
              {{$filters.capitalize(
                $t("scheduler.rest_advice.conclusion", { time: this.restEnd })
                  )
              }}
            </v-container>
          </v-card-text>
  
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="success" @click="restAdvice = false">{{$filters.capitalize(
              $t("actions.i_understand") )
            }}</v-btn>
            <v-spacer></v-spacer>
          </v-card-actions>
        </v-card>
      </v-dialog>
  
      <v-toolbar elevation="2" class="pa-1 white">
        <div id="previousWeek" ref="previousWeek" class="mr-1"></div>
        <div id="nextWeek" ref="nextWeek" class="mr-5"></div>
        <!-- TODO FIX spam button crash [object Object] -->
        <v-select
          class="mt-3"
          v-model="regions"
          :items="$store.state.stockages.regions"
          item-value="id"
          item-title="name"
          :hint="$filters.capitalize($t('actions.choose_region') )"
          :multiple="true"
        ></v-select>
        <v-spacer></v-spacer>
  
        <v-toolbar-title style="font-weight: bolder">{{$filters.capitalize(
          $t(`scheduler.label`, 10) )
        }}</v-toolbar-title>
        <v-spacer></v-spacer>
  
        <v-btn
          v-if="isFromStaff"
          color="primary"
          elevation="3"
          variant="outlined"
          size="small"
          class="mr-3 hidden-sm-and-down"
          @click.prevent="monthly_report()"
          >{{$filters.capitalize( $t(`scheduler.actions.monthly_report`) ) }}</v-btn
          >
          
          <v-btn
          v-if="isFromStaff"
          color="primary"
          elevation="3"
          variant="outlined"
          size="small"
          class="mr-3 hidden-sm-and-down"
          @click.prevent="export_xlsx()"
          >{{$filters.capitalize( $t(`scheduler.actions.export`) ) }}</v-btn
          >
          
          <v-btn
          v-if="isFromStaff"
          color="primary"
          elevation="3"
          variant="outlined"
          size="small"
          class="mr-3 hidden-sm-and-down"
          @click.prevent="exportStats()"
          >{{$filters.capitalize( $t(`scheduler.actions.export_stats`) ) }}</v-btn
        >
        <v-spacer></v-spacer>
  
        <v-btn id="menu-activator" class="hidden-md-and-up" v-if="isFromStaff"
          ><v-icon>mdi-menu</v-icon>
        </v-btn>
        <v-menu activator="#menu-activator">
          <v-list class="d-flex flex-column">
            <v-btn
              color="primary"
              class="mb-2 mx-2"
              @click.prevent="monthly_report()"
              >{{$filters.capitalize( $t(`scheduler.actions.monthly_report`) ) }}</v-btn
            >
  
            <v-btn
              color="primary"
              class="mb-2 mx-2"
              @click.prevent="export_xlsx()"
              >{{$filters.capitalize( $t(`scheduler.actions.export`) ) }}</v-btn
            >
  
            <v-btn color="primary" class="mx-2" @click.prevent="exportStats()">{{$filters.capitalize(
              $t(`scheduler.actions.export_stats`) )
            }}</v-btn>
          </v-list>
        </v-menu>
        <v-tooltip location="bottom" :text="$filters.capitalize( $t(`scheduler.week_view`) )">
          <template v-slot:activator="{props}">
            <v-btn
              v-if="isFromStaff"
              :variant="viewModes['dayAndWeek']['outlined']? 'outlined' : 'elevated'"
              :color="viewModes['dayAndWeek']['color']"
              elevation="4"
              icon="true"
              class="ml-5"
              @click.prevent="switchView('dayAndWeek')"
              v-bind="props"
            >
              <v-icon>mdi-calendar-week</v-icon>
            </v-btn>
          </template>
        </v-tooltip>
  
        <v-tooltip location="bottom" :text="$filters.capitalize( $t('scheduler.month_view'))">
          <template v-slot:activator="{props}">
            <v-btn
              v-if="isFromStaff"
              :variant="viewModes['weekAndMonth']['outlined']? 'outlined':'elevated'"
              :color="viewModes['weekAndMonth']['color']"
              elevation="4"
              icon="true"
              class="ml-5"
              @click.prevent="switchView('weekAndMonth')"
              v-bind="props"
            >
              <v-icon>mdi-calendar-month</v-icon>
            </v-btn>
          </template>
        </v-tooltip>
      </v-toolbar>
  
      <scheduler
        ref="scheduler" 
        :config="schedulerConfig"
        :columns="columns"
        :listeners_="listeners"
        :loadMask="loadMask"
        :non-working-time-feature="schedulerConfig.nonWorkingTimeFeature"
        @schedulerInstance="schedulerInstance = $event"
        @timeaxischange="updateScheduler"
      />
      <SchedulerEventEditor
        v-model="showEditor"
        :eventRecord="eventRecord"
        :eventStore="eventStore"
        :resourceId="resourceId"
        @close="onCloseEditor"
      ></SchedulerEventEditor>

      <AllPendingRest
        ref="pendingRestDialog"
        v-model="showPendingRestDialog"
        @close="closePendingRestDialog"
      />

      <v-row>
        <v-col cols="12" align="center">
          <v-btn
            :disabled="!gotARegion"
            color="primary"
            elevation="3"
            class="mr-3"
            @click.prevent="createNewEvent"
            >{{$filters.capitalize( $t(`scheduler.actions.add_event`) ) }}</v-btn
          >
          <v-btn
            :disabled="!gotARegion"
            color="primary"
            elevation="3"
            class="mr-3"
            @click.prevent="declareOnCallDuty"
            >{{$filters.capitalize( $t(`scheduler.actions.trigger_oncallduty`) ) }}</v-btn
          >
          <v-btn
            :disabled="!gotARegion || !isFromStaff"
            color="primary"
            elevation="3"
            class="mr-3"
            @click.prevent="declareOnCallDutyRestAnticipated"
            >{{$filters.capitalize( $t(`scheduler.actions.rest_oncallduty_anticipated`) ) }}</v-btn
          >
          <v-btn
            :disabled="!gotARegion || !isFromStaff"
            color="primary"
            elevation="3"
            class="mr-3"
            @click.prevent="openPendingRestDialog"
          >
            {{$t('scheduler.actions.generate_rest')}}
          </v-btn>
        </v-col>
      </v-row>
  
      <v-row v-if="user_stats_state">
        <v-col cols="12" align="center">
          <v-card class="pa-4">
            <h3 class="text-h5 mb-4">{{$filters.capitalize($t('scheduler.stats.title'))}}</h3>
            <v-list dense>
                  <v-list-item-title>
                    {{$filters.capitalize($t('scheduler.stats.number_of_days'))}} :
                    {{ user_stats.number_of_days }} {{ $t('generic.day', user_stats.number_of_days) }}
                    ({{ $t('generic.saturday', user_stats.sat_triggers.length) }}: {{ user_stats.sat_duty }};
                    {{ $t('generic.sunday', user_stats.sun_triggers.length) }}: {{ user_stats.sun_duty }})
                  </v-list-item-title>
                  <v-list-item-title>
                    {{$filters.capitalize( $t(`scheduler.stats.days`) ) }}&nbsp;:
                    {{ user_stats_days }}
                  </v-list-item-title>
            </v-list>
              <v-list dense>
                <v-list-item-title>
              {{$filters.capitalize( $t(`scheduler.stats.duration`) ) }}&nbsp;:
              {{ user_stats.sat_triggers_total + user_stats.sun_triggers_total }}
              {{
                $t(
                  `generic.hour`,
                  user_stats.sat_triggers_total + user_stats.sun_triggers_total
                )
              }}
              ({{ $t(`generic.saturday`, user_stats.sat_triggers_total) }}&nbsp:
              {{ user_stats.sat_triggers_total }} ;
              {{ $t(`generic.sunday`, user_stats.sun_triggers_total) }}&nbsp:
              {{ user_stats.sun_triggers_total }})
                </v-list-item-title>
                <v-list-item-title>
              {{$filters.capitalize( $t(`scheduler.stats.rest_clock`) ) }}&nbsp;:
              {{ user_stats.rest_clock }}
              {{ $t(`generic.hour`, user_stats.rest_clock) }} ({{
                $t(`scheduler.stats.rest_clock_explained`)
              }})
                </v-list-item-title>
            </v-list>
          </v-card>
        </v-col>
      </v-row>
      <v-row>
      <v-col cols="12">
        <v-card class="pa-4">
          <h3 class="text-h5 mb-4">{{ $t('scheduler.pending_rest.title') }}</h3>
          <v-btn
            :disabled="!gotARegion"
            color="primary"
            elevation="3"
            class="mr-3"
            @click="fetchPendingRest(resourceId)"
          >
            Refresh
          </v-btn>
          <v-data-table
            :headers="headers"
            :items="pendingRest ?? []"
            item-value="name"
            dense
          >
            <template v-slot:item.is_remote="{ item }">
              <v-icon v-if="item.is_remote" color="green">mdi-check</v-icon>
              <v-icon v-else color="red">mdi-close</v-icon>
            </template>
            <template v-slot:[`item.start`]="{ item }">
              {{ $filters.formatDateTimeCustom(item.start) }}
            </template>

            <template v-slot:[`item.end`]="{ item }">
              {{ $filters.formatDateTimeCustom(item.end) }}
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
  </template>
  
  <script>
  import Cookies, { get } from "js-cookie";
  import moment from "moment";
  import { capitalize, isNone, endCorrection } from "@/functions.js";
  import Appointment from "@/components/scheduler/Appointment";
  import AppointmentStore from "@/components/scheduler/AppointmentStore";
  import Scheduler from "@/components/scheduler/Wrapper";
  import SchedulerEventEditor from "@/components/scheduler/EventEditor";
  import AllPendingRest from "@/components/scheduler/AllPendingRest";
  import utils from "@/mixins/utils";
  
  import "@bryntum/scheduler/scheduler.material.css";
  import "@/locales/scheduler.locale.Fr.js";
  import { WidgetHelper, StateTrackingManager } from "@bryntum/scheduler";
  
  export default {
    mixins: [utils],
  
    components: {
      Scheduler,
      SchedulerEventEditor,
      AllPendingRest
    },
  
    props: {
      year: { type: String, required: false },
      week: { type: String, required: false },
      modelValue: Boolean, // This replaces the v-model prop
   //   eventRecord: Object,
   //   eventStore: Object,
   //   resourceId: [String, Number],
    },
  
    computed: {
      ...utils.isFromStaff,
      user_stats: function() {
        return this.$store.state["appointments"].user_stats;
      },
      user_stats_state: function() {
        return this.$store.state["appointments"].user_stats_state;
      },
      user_stats_days: function() {
        var output = [];
        for (var i = 0; i < this.user_stats.days.length; i++) {
          var val = moment(this.user_stats.days[i]).format("ddd D MMM");
          output.push(val);
        }
        return output.join(", ");
      },
      isWeeklyView: function() {
        return this.viewModes["dayAndWeek"]["outlined"];
      },
  
      columns: function() {
        const currentUser = isNone(this.$store.getters.user)
          ? undefined
          : this.$store.getters.user.id;
        const columnTitle = this.$t("scheduler.week") + ': ' + this.weekNumber
        if(!this.$vuetify.display.mobile) {
          return [
            {
              type: "resourceInfo",
              showImage: false,
              showEventCount: false,
              text: columnTitle,
              field: "name",
              readOnly: true,
              minWidth: "27ex",
              align: "center",
              expandedFolderIconCls: null,
              collapsedFolderIconCls: null,
              leafIconCls: null,
              htmlEncode: false,
              renderer: ( {record, value, size} ) => {
                var resource = `${value}`;
                if (record.data.id === currentUser) {
                  resource = `<i aria-hidden="true" class="v-icon mr-2 notranslate mdi mdi-calendar-arrow-right" style="color: #4C4C4C;"></i><i>${value}</i>`;
                }
                resource = `${resource}<br>(${record.data.counts.work}/${record.data.counts.holiday}/${record.data.counts.rest}/${record.data.counts.trip}/${record.data.counts.int_trip})`;
  
                return resource;
              },
              responsiveLevels: {
                small: { hidden: true },
                medium: { hidden: true },
                "*": { hidden: false },
              },
            },
        ]}
      else{
        return [
            {
              type: "resourceInfo",
              showImage: false,
              showEventCount: false,
              text: columnTitle,
              field: "nick",
              readOnly: true,
              autoWidth: false,
              width: "4ex",
              align: "center",
              // Hide default tree icons
              expandedFolderIconCls: null,
              collapsedFolderIconCls: null,
              leafIconCls: null,
              htmlEncode: false,
              responsiveLevels: {
                small: { hidden: false },
                medium: { hidden: false },
                "*": { hidden: true },
              },
            },
          ];
        }
      }
    },
  
    watch: {
      user_stats(value) {
        this.resourceId = value.user.id
        this.fetchPendingRest();
      },
      regions(value) {
        this.updateScheduler();
        this.fetchUsers();
        this.gotARegion = this.regions.length > 0;
      },
      showPendingRestDialog(newValue) {
        if (newValue) {
          this.$nextTick(() => {
            this.$refs.pendingRestDialog?.fetchPendingRest();
          });
        }
      },
    },
  
    methods: {
      fetchPendingRest() {
        this.isLoading = true;
        this.fetchError = null;

        const payload = { users: [this.resourceId] };

        this.$store.dispatch("appointments/getPendingRest", payload)
          .then((response) => {
            if (Array.isArray(response) && response.length > 0) {
              this.pendingRest = response.map((item) => ({
                ...item,
                userName: item.user?.name || "Unknown",
                daysRemaining: this.$store.getters["appointments/calculateDaysRemaining"](item.start),
              }));
            } else {
              this.pendingRest = [];
            }
          })
          .catch((error) => {
            console.error("Error fetching pending rest:", error);
            this.fetchError = error?.message || this.$t("Failed to fetch pending rest");
          })
          .finally(() => {
            this.isLoading = false;
          });
      },

      openPendingRestDialog() {
        this.showPendingRestDialog = true;
      },
      closePendingRestDialog(updatedEvent) {
        this.showPendingRestDialog = false;
      },
      fetchUsers() {
        this.$store
          .dispatch("users/fetchUsersByRegions", { regions: Object.values(this.regions) })
          .then(() => {
            this.$store.dispatch("snackbar/showSuccess", "Users fetched successfully.");
          })
          .catch((error) => {
            console.error("Error fetching users:", error);
            this.$store.dispatch("snackbar/showError", "Failed to fetch users. Please try again.");
          });
      },
      token() {
        return localStorage.getItem("token");
      },
  
      initViewSpan() {
        const startDate = moment();
        this.updateCurrentTime(startDate);
      },
  
      currentViewEnd() {
        const date_ = this.currentViewStart.clone();
        if (this.viewMode === "weekAndMonth") {
          return date_.endOf("month");
        }
        return date_.endOf("isoWeek");
      },
  
      updateCurrentTime(date_) {
        if (this.viewMode === "weekAndMonth") {
          date_ = date_.startOf('months');
        } else if(this.viewMode === "dayAndWeek"){
          date_ = date_.weekday(1);
        }
        this.currentViewStart = date_;
        this.currentYear = date_.isoWeekYear();
        this.currentWeek = date_.isoWeek();
        this.weekNumber = date_.isoWeek();
        this.schedulerInstance.setTimeSpan(
          this.currentViewStart.toDate(),
          this.currentViewEnd().toDate()
        );
      },
  
      swipeView(next) {
        const span = this.viewMode === "dayAndWeek" ? "w" : "M";
        if (next) {
          this.updateCurrentTime(this.currentViewStart.add(1, span));
        } else {
          this.updateCurrentTime(this.currentViewStart.subtract(1, span));
        }
  
      },
  
      switchView(mode) {
        this.viewMode = mode;
        Object.keys(this.viewModes).forEach((key) => {
          if (key === mode) {
            this.viewModes[key] = { outlined: true, color: "primary" };
          } else {
            this.viewModes[key] = { outlined: false, color: "secondary" };
          }
        });
  
        this.schedulerInstance.setConfig({
          viewPreset: {
            base: mode,
            timeResolution: {
              unit: "hour",
            },
          },
        });
        let dateInit = moment();
        this.updateCurrentTime(dateInit);
      },
  
      createNewEvent(event) {
        const resource = this.schedulerInstance.resourceStore.getById(
          this.$store.getters.user.id
        );
        const startDate = moment().toDate();
        const endDate = moment().toDate();
  
        var eventRecord = new Appointment({
          name: "",
          startDate: startDate,
          endDate: endDate,
          description: "",
          ilk: "work",
          locked: false,
          is_remote: null,
          producer_id: null,
        });
        
        this.$emit('update:modelValue', true);
        this.$emit('open');
        
        this.openEditor({
          source: this.schedulerInstance,
          resourceRecord: resource,
          eventRecord: eventRecord,
        });
      },
  
      declareOnCallDuty(event) {
        const resource = this.schedulerInstance.resourceStore.getById(
          this.$store.getters.user.id
        );
  
        const startDate = moment().toDate();
        const endDate = moment().toDate();
  
        var eventRecord = new Appointment({
          name: capitalize(this.$t("scheduler.ilk.oncallduty_triggered")),
          startDate: startDate,
          endDate: endDate,
          description: "",
          ilk: "oncallduty_triggered",
          locked: false,
          is_remote: null,
          producer_id: null,
          durationUnit: "m",
        });
        this.openEditor({
          source: this.schedulerInstance,
          resourceRecord: resource,
          eventRecord: eventRecord, 
        });
      },
  
      declareOnCallDutyRestAnticipated(event) {
        const resource = this.schedulerInstance.resourceStore.getById(
          this.$store.getters.user.id
        );
  
        const startDate = moment().toDate();
        const endDate = moment().toDate();
  
        var eventRecord = new Appointment({
          name: "Repos anticipée",
          startDate: startDate,
          endDate: endDate,
          description: "",
          ilk: "oncallduty_rest_anticipated",
          locked: false,
          is_remote: null,
          producer_id: null,
          durationUnit: "m",
        });
        this.openEditor({
          source: this.schedulerInstance,
          resourceRecord: resource,
          eventRecord: eventRecord, 
        });
      },
  
      beforeEventEditHandler(event) {
        this.openEditor(event);
        return false;
      },
  
      onScheduleDblClick({ date, resourceRecord }) {
        const startDate = moment(date).startOf('day').add(0, 'hours').toDate();
        const endDate = moment(date).startOf('day').add(23, 'hours').toDate();
  
        const eventRecord = new Appointment({
          name: '',
          startDate: startDate,
          endDate: endDate,
          description: '',
          ilk: 'work',
          locked: false,
          is_remote: null,
          producer_id: null,
        });
  
        this.openEditor({
          source: this.schedulerInstance,
          resourceRecord: resourceRecord,
          eventRecord: eventRecord,
        });
    },
  
      openEditor({ source, resourceRecord, eventRecord }) {
        if (isNone(resourceRecord)) {
          this.$store.dispatch(
            "snackbar/showError",
            this.$t(`scheduler.snack.user_not_in`)
          );
          return false;
        }
        
        this.eventStore = source.eventStore;
        this.resourceId = resourceRecord.id;
        this.fetchPendingRest(this.resourceId);
        this.eventRecord = eventRecord;
        this.showEditor = true;
  
        // TODO: set event editor to today&
        this.$store.dispatch("scheduler/setStart", eventRecord.startDate);
        this.$store.dispatch("scheduler/setEnd", eventRecord.endDate);
      },
  
      onCloseEditor(records, canceled) {
        this.showEditor=false;
        if (canceled) {
          return;
        }
        const start = moment.utc(this.$store.state.scheduler.start);
        const end = moment.utc(this.$store.state.scheduler.end);

        if (!isNone(this.eventRecord)) {
            this.eventRecord.setStartEndDate(start.toDate(), end.toDate());
            var delta = end - start;
            this.eventRecord.setDuration(delta / 1000, "s");
          }
          const engine = this.schedulerInstance;
          if (engine) {
            engine.refresh();
          }
      },
  
      isOnCallDutyTriggered(record) {
        return (
          !isNone(record) &&
          !isNone(record.ilk) &&
          record.ilk.startsWith("oncallduty_triggered")
        );
      },
  
      get_color(ilk) {
        if (ilk === "work") {
          return "#018e48";
        } else if (ilk === "holiday") {
          return "#f5db14";
        } else if (ilk === "rest") {
          return "#dcf65a";
        } else if (ilk === "trip") {
          return "#4b939b";
        } else if (ilk === "int_trip") {
          return "#1c77c3";
        } else if (ilk === "oncallduty") {
          return "#bd6fef";
        } else if (ilk === "oncallduty_triggered") {
          return "#f23cb9";
        } else if (ilk === "oncallduty_rest") {
          return "#755ddb";
        }
  
        return "grey";
      },
  
    //TODO error EventEditor input nothing showed
    async updateScheduler() {
      if (this.isUpdatingScheduler) {
        console.log("An update is already in progress. Please wait.");
        return; 
      }
      
      this.isUpdatingScheduler = true;
  
      try {
        
        await this.$store.dispatch("ensure_connected");
        await this.schedulerInstance.crudManager.load({
          regions: this.regions,
          events: this.getSchedulerDate(),
        });
      } catch (error) {
        console.error("Error update scheduler : ", error);
      } finally {
        this.isUpdatingScheduler = false;
      }
    },
  
    getSchedulerDate() {
      const events_ = { year: this.currentYear };
      if (this.isWeeklyView) {
        events_["week"] = this.weekNumber;
      } else {
        events_["month"] = this.currentViewStart.month() + 1;
        if(events_["month"] >= 13){
          events_["month"] -= 12;
          events_["year"]++;
        }
      }
      return events_;
    },
  
  
    processCrudError(event) {
      const response = JSON.parse(event.responseText);
      var message =
        (response && response.message) ||
        capitalize(this.$t("snack.unknown"));
      if (response.code !== undefined) {
        if (response.code == "appointment.oncallduty.user_need_rest"){
          message = capitalize(this.$t("scheduler.snack.need_rest"));
          this.stm.undoAll();
          this.stm.resetQueue();
        }
        else if (response.code === "appointment.oncallduty.triggered.no_cover") {
          message = capitalize(this.$t("scheduler.snack.no_cover"));
          this.stm.undoAll();
          this.stm.resetQueue();
        } else if (
          response.code === "appointment.oncallduty.triggered.no_consecutive"
        ) {
          message = capitalize(this.$t("scheduler.snack.no_consecutive"));
          this.stm.undoAll();
          this.stm.resetQueue();
        } else if (
          response.code === "appointment.oncallduty.triggered.too_much_weeks"
        ) {
          message = capitalize(this.$t("scheduler.snack.too_much_weeks"));
          this.stm.undoAll();
          this.stm.resetQueue();
        }
      }
      this.$store.dispatch("snackbar/showError", message);
    },
  
    _export(path, filename, type_, responseType) {
      const payload = {
        regions: this.regions,
        start: this.currentViewStart.toDate(),
        end: this.currentViewEnd().toDate(),
      };
      this.getFile(path, filename, type_, responseType, payload);
    },
  
  
      monthly_report() {
        return this._export(
          "appointments/monthly_report",
          "Monthly_Report.csv",
          "text/csv",
          "arraybuffer"
        );
      },
  
      export_xlsx() {
        return this._export(
          "appointments/export",
          "All_Report.xlsx",
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          "blob"
        );
      },
  
      exportStats() {
        return this._export(
          "appointments/export_stats",
          "Report.csv",
          "text/csv",
          "arraybuffer"
        );
      },
    },
  
    mounted() {
      console.log("Ressource Record", this.resourceRecord);
      WidgetHelper.createWidget({
        type: "button",
        appendTo: this.$refs.previousWeek,
        icon: "b-fa b-fa-chevron-left",
        tooltip: capitalize(this.$t("scheduler.previous_week")),
        cls: "primary b-raised",
        onClick: () => {
          this.swipeView(false);
        },
      });
  
      WidgetHelper.createWidget({
        type: "button",
        appendTo: this.$refs.nextWeek,
        icon: "b-fa b-fa-chevron-right",
        tooltip: capitalize(this.$t("scheduler.next_week")),
        cls: "primary b-raised",
        onClick: () => {
          this.swipeView(true);
        },
      });
  
      this.$store.dispatch(`stockages/getRegions`).then(() => {
        //Preload a certain region
        if (this.$route.query.regions !== undefined) {
          var regions = this.$route.query.regions;
          if (!Array.isArray(regions)) {
            regions = [regions];
          }
          this.regions = regions;
        }
      });
  
  
      this.$store.dispatch('appointments/getUserStats').then(() => {
      }).catch((error) => {
        console.error('Échec', error);
      });
  
      const scheduler = this.$refs.scheduler.schedulerInstance;
      scheduler.crudManager.on({
        beforeSend({ params, type, requestConfig }) {
          if (requestConfig.headers === undefined) {
            requestConfig.headers = {};
          }
          requestConfig.headers.Authorization = `Bearer ${this.token()}`;
        },
        sync: () => {
          this.updateScheduler();
        },
        thisObj: this,
      });
  
      this.stm = new StateTrackingManager({
        autoRecord: true,
      });
      this.stm.addStore(scheduler.eventStore);
      this.stm.enable();
      this.initViewSpan();
      
      this.switchView('dayAndWeek');
    },
  
    data() {
      const this_ = this;
      return {
        pendingRest: [],
        headers: [
          { title: this.$t("scheduler.name"), key: "name" },
          { title: this.$t("scheduler.start"), key: "start" },
          { title: this.$t("scheduler.end"), key: "end" },
          { title: this.$t("scheduler.remote"), key: "is_remote" },
          { title: this.$t("scheduler.duration"), key: "duration" },
          { title: this.$t("scheduler.days_remaining"), key: "daysRemaining" },
          { title: this.$t("scheduler.half_day_count"), key: "half_day_count" },
        ],
        showPendingRestDialog: false,
        selectedEventRecord: null,
        weekNumber : 0,
        loadMask: capitalize(this.$t("loading") + "..."),
        restAdvice: false,
        restEnd: "",
        showEditor: false,
        eventRecord: null,
        eventStore: null,
        resourceId: null,
        stm: null,
        isUpdatingScheduler: false,
        listeners: {
          beforeEventEdit: this.beforeEventEditHandler,
          beforeEventDrag: ({ eventRecords }) => {
            return eventRecords.every(eventRecord => !eventRecord.locked);
          },
          beforeEventResize: ({ eventRecord }) => {
            return !eventRecord.locked;
          },
          scheduleDblClick: this.onScheduleDblClick,
        },
        schedulerInstance: null,
        currentYear: this.year,
        currentWeek: this.week,
        regions: [],
        gotARegion: false,
        viewMode: "dayAndWeek",
        viewModes: {
          dayAndWeek: { outlined: true, color: "primary",
            timeResolution: {
              unit: "hour",
              increment: 1,
            },
          },
          weekAndMonth: { outlined: false, color: "secondary" },
        },
        schedulerConfig: {
          createEventOnDblClick: false,
          eventResizeFeature: false,
          allowOverlap: false,
          autoHeight: true,
          eventColor: "indigo",
          eventStyle: "rounded",
          features: {
            timeRanges: true,
            stripe: true,
            group: false,
            eventResizeFeature: false,
            eventTooltip: {
              template: (data) => `
              <dl>
                <dd>
                  ${data.eventRecord.description}
                </dd>
              </dl>`,
            },
            eventDrag: {
              constrainDragToResource: true,
            },
            eventMenu: {
              items : {
                lock : {
                  text   : capitalize(this.$t('scheduler.actions.lock')),
                  icon   : 'b-fa b-fa-fw b-fa-lock',
                  icon   : 'mdi mdi-pencil-lock',
                  cls    : 'b-separator',
                  weight : 503,
                  onItem({ eventRecord }) {
                    if (this_.isFromStaff) {
                      eventRecord.locked = !eventRecord.locked
                    }
                  }
                },
              },
  
            },
          },
          eventRenderer: ({ eventRecord }) => {
            var classes = [];
            var icon = "";
            if (["holiday", "rest"].includes(eventRecord.ilk)) {
              classes.push("eventTitleInverted");
            }
            if (eventRecord.locked) {
              classes.push("eventIsLocked");
              icon =
                '<i aria-hidden="true" class="v-icon notranslate mdi mdi-pencil-lock cursor-not mr-1" style="color: #4C4C4C;"></i>';
            }
            return `${icon}<div class="${classes.join(
              " "
            )}" style="display: inline-flex;">${eventRecord.name}</div>`;
          },
  
  
          weekStartDay: 1,
          nonWorkingTimeFeature: true,
          //scrollable: false,
  
          barMargin: 1,
          rowHeight: 50,
  
  
          useInitialAnimation: "slide-from-left",
  
          crudManager: {
            //autoLoad  : true,
            autoSync: true,
            eventStore: { storeClass: AppointmentStore },
            transport: {
              load: {
                url: `${process.env.VUE_APP_API_URL}/api/appointments/schedules/`,
                headers: {
                  "X-CSRFToken": Cookies.get("csrftoken"),
                },
                credentials: "omit",
              },
              sync: {
                url: `${process.env.VUE_APP_API_URL}/api/appointments/scheduler/`,
                headers: {
                  "X-CSRFToken": Cookies.get("csrftoken"),
                },
                credentials: "omit",
              },
            },
            listeners: {
              loadFail: this.processCrudError,
              syncFail: this.processCrudError,
              beforeSync: ({ pack }) => {
                if (Array.isArray(pack.events.added)) {
                  pack.events.added = endCorrection(pack.events.added);
                }
                if (Array.isArray(pack.events.updated)) {
                  pack.events.updated = endCorrection(pack.events.updated);
                }
                return true;
              },
            },
          },
        }
      };
    },
  };
  </script>
  
  <style>
  .eventTitleInverted {
    color: #212121;
  }
  
  .cursor-not {
    cursor: not-allowed;
  }
  
  .eventIsLocked {
    cursor: not-allowed;
  }
  
  /* https:agithub.com/bryntum/support/issues/1739 */
  .b-sch-event-wrap.b-sch-style-rounded .b-sch-event:not(.b-milestone) {
    color: white;
  }
  </style> 
  