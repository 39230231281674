import crud from "./crud";
import crud_service from "@/services/crud";

const path = "parts";
const struct = {
  serial: null,
  quantity: null,
  place_id: null,
  stock_part_id: null,
  min_quantity: 0,
  max_quantity: undefined,
};

const generic = crud(path, struct);

const state = {
  ...generic.state,
};

const getters = {
  ...generic.getters,
};

const mutations = {
  ...generic.mutations,
};

const actions = {
  ...generic.actions,

  getView({ commit }, options) {
    var payload = {};
    if (options) {
      if (options.size !== undefined) {
        payload["size"] = options.size;
      }
      if (options.stockage !== undefined) {
        payload["stockage_id"] = options.stockage;
      }
      if (options.producer_type !== undefined) {
        payload["producer_type_id"] = options.producer_type;
      }
      if (options.lang !== undefined) {
        payload["lang"] = options.lang;
      }
    }
    commit("setIsLoading", true);

    return new Promise((resolve) => {
      crud_service
        .fetch(`${path}/real_list`, payload)
        .then((items) => {
          commit("setItems", items.results);
          commit("setPagination", items);
          commit("setIsLoading", false);
          resolve();
        })
        .catch(() => {
          commit("setIsLoading", false);
        });
    });
  },

  getPlaceTotals({ commit }, options) {
    var payload = {};
    if (options) {
      if (options.stockage_id !== undefined) {
        payload["stockage_id"] = options.stockage_id;
      }
      if (options.stock_part_id !== undefined) {
        payload["stock_part_id"] = options.stock_part_id;
      }
    }
    commit("setIsLoading", true);

    return new Promise((resolve) => {
      crud_service
        .fetch(`${path}/real_places`, payload)
        .then((items) => {
          commit("setIsLoading", false);
          resolve(items);
        })
        .catch(() => {
          commit("setIsLoading", false);
        });
    });
  },

  squashPlace({ commit }, { stock_part_id, place_id }) {
    // sets all parts for stock_part and place so as to not show in part list
    const payload = {
      stock_part: stock_part_id,
      place: place_id,
    };
    commit("setIsLoading", true);

    return new Promise((resolve) => {
      crud_service
        .post(`${path}/squash_place`, payload)
        .then((items) => {
          commit("setIsLoading", false);
          resolve(items);
        })
        .catch(() => {
          commit("setIsLoading", false);
        });
    });
  },
  setStockToZero({ commit }, payload) {
    // given a stock_part_id and stockage_id it changes total to zero
    // meant to be used for the negative totals we get sometimes
    return new Promise((resolve) => {
      crud_service
        .post(`${path}/set_stock_to_zero`, payload)
        .then((response) => {
          resolve(response);
        })
    })
  },

  saveFullPart({ commit }, payload) {
    // Create a new full part and stock_part (and stockpartlimit)
    commit("setIsLoading", true);

    return new Promise((resolve) => {
      crud_service
        .post(`${path}/full`, payload)
        .then((resp) => {
          commit("setIsLoading", false);
          resolve(resp);
        })
        .catch(() => {
          commit("setIsLoading", false);
        });
    });
  },

  saveFullPlace({ commit }, payload) {
    // Create a new full part and stock_part (and stockpartlimit)
    commit("setIsLoading", true);

    return new Promise((resolve) => {
      crud_service
        .post(`${path}/full_place`, payload)
        .then((resp) => {
          commit("setIsLoading", false);
          resolve(resp);
        })
        .catch(() => {
          commit("setIsLoading", false);
        });
    });
  },

  updateFullPart({ commit }, payload) {
    // Update a full part and stock_part (and stockpartlimit)
    commit("setIsLoading", true);

    return new Promise((resolve) => {
      crud_service
        .put(`${path}/full`, payload)
        .then((resp) => {
          commit("setIsLoading", false);
          resolve(resp);
        })
        .catch(() => {
          commit("setIsLoading", false);
        });
    });
  },

  deleteFullPart({ commit }, pk) {
    // Cleanly delete a full part+stock_part
    return new Promise((resolve) => {
      crud_service.delete(`${path}/full`, pk).then(() => {
        commit("deleteItem", pk);
      });
    });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
