<template>
  <v-card v-if="isExistingPart" class="pa-2 mt-3 mx-0">
    <div style="display:none">
      <v-dialog v-model="showPeekPurchase">
        <PeekPurchase/>
      </v-dialog>
    <div style="display:none">
      <v-dialog v-model="showPeekIntRep">
        <PeekIntRep/>
      </v-dialog>
    </div>
    </div>
    <v-card-title
        >{{$filters.capitalize( $t("parts.history") ) }}
        <v-icon small right @click="loadList()">mdi-refresh</v-icon>
    </v-card-title>
    <v-col>
      <v-data-table
          class="ma-4"
          :headers="headers"
          :items="partHistory"
          :options.sync="optionsTable"
          :hide-default-footer="partHistory.length < 10"
          :loading="$store.state.parts.isLoading"
          :footer-props="footerProps"
          density= 'compact'
          >
          <template v-slot:loading>
            <v-skeleton-loader type="table-row@5"></v-skeleton-loader>
          </template>
          <template v-slot:item.created_at="{ item }">
            {{$filters.formatDate( item.created_at ) }}
          </template>
          <template v-slot:item.place="{ item }">
            {{$filters.formatPlace( item.place ) }}
          </template>
          <template v-slot:item.actions="{ item }">
            <v-icon
              v-if="item.purchase_id !== null"
              icon="mdi-shopping-outline"
              @click="goToPurchase(item.purchase_id)"
            />
            <v-icon
              v-if="item.intervention_report_id !== null"
              @click="goToIntRep(item.intervention_report_id)"
              icon="mdi-clipboard-file-outline"
            />
          </template>
      </v-data-table>
    </v-col>
  </v-card>
</template>

<script>
  import PeekPurchase from "@/components/PeekPurchase";
  import PeekIntRep from "@/components/PeekIntRep";
  import filters from "@/mixins/filters";
  import utils from "@/mixins/utils";
  import { isNone } from "@/functions";
  export default {
    mixins: [filters, utils],
    components: {
      PeekPurchase,
      PeekIntRep,
    },
    props: {
      stock_part_id: {type: Number, required: false},
      stockage_id: {type: Number, required: false},
    },
    computed: {
      isExistingPart() {
        return !(this.$store.state.stock_parts.edit.unity === 'un' ||
          isNone(this.stock_part_id) || isNone(this.stockage_id))
      },
    },
    methods: {
      getPartHistory(stock_part_id, stockage_id){
        let options = this.genPaginationPayload(this.optionsTable);
        this.$store.dispatch("parts/getItems", {
          stock_part_id:stock_part_id,
          stockage_id: stockage_id,
          options: options,
        }).then((items)=>{
          this.partHistory = items;
        });
      },
      loadList() {
        if (!isNone(this.stock_part_id) && !isNone(this.stockage_id)) {
          this.getPartHistory(this.stock_part_id, this.stockage_id);
        }
      },
      goToPurchase(pk) {
        this.$store.dispatch('purchases/getItem', pk).then(()=>{
        this.showPeekPurchase = true;
        })
      },
      goToIntRep(pk) {
        this.$store.dispatch('intervention_reports/getItem', pk).then(()=>{
        this.showPeekIntRep = true;
        })
      },
    },
    watch: {
      stock_part_id(val) {
        if (val) this.loadList();
        else this.partHistory= [];
      },
      stockage_id(val) {
        if (val) this.loadList();
        else this.partHistory = [];
      },
    },
    data() {
      return {
        moduleName: 'parts',
        partHistory: [],
        optionsTable: {},
        headers : [
          {
            title: this.$t("generic.created_at"),
            key:"created_at",
            sortable: true,
          },
          {
            title: this.$t("generic.created_by"),
            key:"created_by.name",
            sortable: true,
          },
          {
            title: this.$t("consumables.quantity"),
            key:"quantity",
            sortable: true,
          },
          {
            title: this.$t("parts.place"),
            key:"place",
            sortable: true,
          },
          {
            title: "",
            key: "actions",
            sortable: false
          },
        ],
        footerProps: {
          "items-per-page-options": [10, 20, 50, -1],
          "items-per-page-text": this.$t('rows_per_page'),
        },
        showPeekPurchase: false,
        showPeekIntRep: false,
      }
    }
  }
</script>
