<template>
  <v-row class="text-center">
    <div style="display: none">
      <EditDialog
        v-bind:model="editRisk"
        v-bind:moduleName="'risks'"
        v-bind:btnName="''"
        v-bind:formTitle="$t('edit_item')"
        @save="saveRiskEvent"
        @close="cleanupRiskDialog"
        ref="editDialogRisk"
      >
        <template v-slot:edit_content>
          <RiskForm :newRisk="false"></RiskForm>
        </template>
      </EditDialog>
    </div>

    <v-col class="mb-5" cols="12">
      <v-toolbar text color="white" elevation="1" density= 'compact'>
        <v-spacer></v-spacer>
        <v-toolbar-title style="font-weight: bold">
          {{ title }}
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <RiskButton :newRisk="false" @reload="loadList"></RiskButton>
      </v-toolbar>
      <v-toolbar color="white" elevation="3" compacti class="pl-2">
        <v-autocomplete
          v-if="askProducer"
          v-model="producerId"
          :items="$store.state.producers.all"
          item-title="display_name"
          item-value='pk'
          :label="$filters.capitalize($t('producers.label', 1))"
          :loading="$store.state.producers.isSearching"
          :filter="filterProducers"
          append-icon="mdi-text-box-search"
          :clearable="true"
          variant="solo"
          cache-items
          class="mr-3"
          density= 'compact'
          hide-details
          @update:modelValue="loadList"
        >
          <template v-slot:no-data>
            <v-list-item>
              <v-list-item-title>
                {{
                  $filters.capitalize(
                    $t("search_for", { noun: $t("producers.label", 1) })
                  )
                }}
              </v-list-item-title>
            </v-list-item>
          </template>
        </v-autocomplete>
        <v-select
          v-model="closedkey"
          :items="closedFilter"
          :label="$filters.capitalize($t('risks.filter.label'))"
          variant="solo"
          :clearable="true"
          class="mr-3"
          density= 'compact'
          hide-details
          @update:modelValue="loadList"
        ></v-select>
      </v-toolbar>
      <v-data-table-server
        :headers="headers"
        :items="items"
        item-value="pk"
        :items-length="pagination.size || 0"
        :items-per-page="pagination.page_size"
        :items-per-page-options='itemsPerPageOptions'
        :page="pagination.current"
        @update:options="loadOptions"
        :no-data-text="$filters.capitalize($t('no_data_text'))"
        :no-results-text="$filters.capitalize($t('no_results_text'))"
        :loading="$store.state.risks.isLoading"
        elevation="2"
      >
        <template v-slot:item.closed="{ item }">
          <v-checkbox-btn
            v-model="item.closed"
            @update:modelValue='toggleClosed(item)'
            class="mr-2"
            small
          >
          </v-checkbox-btn>
        </template>
        <template v-slot:item.actions="{ item }">
          <v-tooltip location="top" :text="$filters.capitalize($t('actions.edit'))">
            <template v-slot:activator="{ props }">
              <v-icon
                v-show="canEdit"
                small
                v-bind="props"
                class="mr-2"
                @click="openEditRisk(item)"
                >mdi-pencil
              </v-icon>
            </template>
          </v-tooltip>
          <v-tooltip location="top" :text=" $filters.capitalize($t('actions.delete'))">
            <template v-slot:activator="{ props }">
              <v-icon
                v-show="isFromStaff"
                small
                v-bind="props"
                @click="deleteBtn(item.pk)"
                >mdi-delete
              </v-icon>
            </template>
          </v-tooltip>
        </template>
      </v-data-table-server>
    </v-col>
  </v-row>
</template>

<script>
import i18n from "@/i18n";
import utils from "@/mixins/utils";
import filters from "@/mixins/filters";
import { isNone, slicyUpdate, capitalize, alertPopup } from "@/functions";

import RiskButton from "@/components/RiskButton";
import RiskForm from "@/components/RiskForm";
import EditDialog from "@/components/EditDialog";

export default {
  mixins: [utils, filters],

  components: { RiskButton, RiskForm, EditDialog },

  props: {
    askProducer: { type: Boolean, required: false, default: true },
  },
  created() {
    this.producerId = parseInt(this.$route.query.producer_id) || undefined;
    this.loadList();
  },

  computed: {
    risk_edit: function () {
      return this.$store.state["risks"].edit;
    },
    ...utils.computed,
  },

  methods: {
    loadList(val) {
    if (this.$store.state.risks.isLoading) return
      let options2 = {
        producer_id: this.producerId,
        closed: this.closedkey,
      };
      const payload = Object.assign({}, this.paginationOptions, options2);
      this.$store.dispatch(`${this.moduleName}/getItems`, payload);
    },
    loadOptions({page, itemsPerPage, sortBy}){
        this.paginationOptions = this.genPaginationPayload(
            {"page": page, "size": itemsPerPage, "sortBy":sortBy})
        this.loadList()
    },
    canEdit() {
      return this.isFromStaff;
    },
    toggleClosed(item) {
        this.$store.dispatch('risks/editItem', item) 
    },
    openEditRisk(item) {
      if (item) {
        this.$store.dispatch(`risks/updateEdit`, item).then(() => {
          this.$emit("editedItem");
        });
      } else {
        this.$store.dispatch(
          `risks/updateEdit`,
          this.$store.state["risks"].struct
        );
      }
      this.editRisk = true;
    },
    saveRiskEvent() {
      return this.$store.dispatch(`risks/editItem`, this.risk_edit).then(() => {
        this.$refs.editDialogRisk.closeDialog();
      });
    },
    cleanupRiskDialog() {
      this.editRisk = false;
      this.$store.dispatch(
        `risks/updateEdit`,
        this.$store.state["risks"].struct
      );
    },
    deleteBtn(pk) {
      alertPopup(this.$t("dialog.delete_confirm")).then((result) => {
        if (result) {
          this.$store.dispatch(`${this.moduleName}/delete`, pk);
          this.loadList();
        }
      });
    },
  },

  data() {
    return {
      moduleName: "risks",
      itemsPerPageOptions: [10, 20, 50, 100],
      headers: [
        {
          title: this.$t("producers.label"),
          key: "producer.display_name",
        },
        { title: this.$t("generic.created_at"), key: "created_at" },
        {
          title: this.$t("risks.reported_by"),
          key: "created_by.name",
// not sortable because back functionality is broken
          sortable: false,
        },
        {
          title: this.$t("risks.description"),
          key: "description",
        },
        {
          title: this.$t("risks.resolved"),
          key: "closed",
          sortable: false,
        },
        {
          title: this.$t("actions.label"),
          key: "actions",
          sortable: false,
        },
      ],
      paginationOptions: {
        "page": 1,
        "size":10
      },

      footerProps: {
        "items-per-page-options": [10, 20, 50],
        "items-per-page-text": this.$t("rows_per_page"),
      },
      title: capitalize(this.$t("risks.title", 10)),
      producerId: undefined,
      closedFilter: [
        {
          title: capitalize(this.$t("risks.filter.active")),
          value: false,
        },
        {
          title: capitalize(this.$t("risks.filter.reported")),
          value: "",
        },
        {
          title: capitalize(this.$t("risks.filter.archived")),
          value: true,
        },
      ],
      closedkey: undefined,
      editRisk: false,
    };
  },
};
</script>
