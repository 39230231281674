<template>
  <v-container class="mb-5">
    <div style="display: none">
      <EditDialog
          v-bind:model="showAddReport"
          v-bind:moduleName="'preventive_maintenances'"
          v-bind:btnName="''"
          v-bind:formTitle="$t('add_item') +': ' +this.producer_name"
          @save="saveAddReportEvent"
          @close="cleanupAddReportDialog"
          ref="editDialogAddReport"
          >
          <template v-slot:edit_content>
            <v-form ref='form'>
              <VueDatePicker
                  v-model="editedItem.done_at"
                  :teleport="true"
                  :enable-time-picker="false"
                  :rules="[v => !!v || $filters.capitalize($t('generic.required') )]"
                  format="yyyy-MM-dd"
                  placeholder="Date"
                  required
                  />
      <v-select
          v-if='reportType=="bl"'
          :rules="[v => !!v || $filters.capitalize($t('generic.required') )]"
          v-model="editedItem.verif_subtype"
          :items="['skyvisor', 'jumelles']"
          />
          <v-file-input
              v-model="report_file"
              @update:modelValue="on_select_file"
              :label="$filters.capitalize($t('verif_reports.blades.report'))"
              show-size
              ></v-file-input>
          <v-text-field
              v-model="editedItem.link"
              type="url"
              />
            <v-textarea
                v-model="editedItem.comment"
                />
            </v-form>
          </template>
      </EditDialog>
    </div>
    <v-toolbar-title style="font-weight: bolder">{{$filters.capitalize(
      $t(pageTitle, 10, { target: this.producer_name }))
      }} {{this.producer_name}}</v-toolbar-title>
    <v-row align="center" justify="center">
      <v-col cols="12" md="6">
        <v-table class="my-2">
          <template v-slot:default>
            <thead>
              <tr>
                <th class="text-left">
                  {{ $filters.capitalize($t("preventive_maintenances.done_at") ) }}
                </th>
                <th  v-if='reportType=="bl"' class="text-left">
                  {{ $filters.capitalize($t("verif_reports.subtype") ) }}
                </th>
                <th class="text-left">
                  {{ $filters.capitalize($t("verif_reports.comment") ) }}
                </th>
                <th class="text-left">
                  {{ $filters.capitalize($t("preventive_maintenances.report") ) }}
                </th>
                <th class="text-left">
                  {{ $filters.capitalize($t("actions.label") ) }}
                </th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="maint in maint_reports" :key="maint.pk">
                <td>{{$filters.formatDate(maint.done_at) }}</td>
                <td v-if='reportType=="bl"'>{{maint.verif_subtype}}</td>
                <td>{{maint.comment}}</td>
                <td>
                  <v-tooltip location="top" :text= "$filters.capitalize($t('actions.download') )">
                    <template v-slot:activator="{ props }">
                      <v-icon
                          v-if='maint.report'
                          v-bind="props"
                          class="mr-auto"
                          @click="openLink(maint.report)"
                          >mdi-file-outline</v-icon
                        >
                    </template>
                  </v-tooltip>
                </td>
                <td>
                  <v-tooltip location="top" :text="$filters.capitalize($t('actions.delete') )">
                    <template v-slot:activator="{ props }">
                      <v-icon
                          v-bind="props"
                          class="mr-2"
                          @click="deleteItemBtn(maint)"
                          >mdi-delete</v-icon>
                    </template>
                  </v-tooltip>
                </td>
              </tr>
            </tbody>
          </template>
        </v-table>
      </v-col>
      <v-btn
          @click='openDialog'
          :text='$t("add")'
          >
      </v-btn>
    </v-row>
  </v-container>
</template>

<script>
  import { alertPopup } from "@/functions";
  import EditDialog from "@/components/EditDialog";

  export default {
    props: {
      producer_id: {},
      producer_name: {},
      pageTitle: {},
      reportType: {},
    },
    components: {
      EditDialog,
    },
    mounted() {
      this.loadList()
    },
    computed: {
      editedItem: function () {
        return this.$store.getters.dataEdit(this.moduleName);
      },
    },

    methods: {
      loadList() {
        this.$store.dispatch(`${this.moduleName}/getItems`, {
          producer_id: this.producer_id,
          verif_type: this.reportType,
        }).then((reps)=>{
          this.maint_reports = reps
        })
      },
      deleteItem(id_) {
        return this.$store.dispatch(`${this.moduleName}/deleteItem`, id_);
      },

      deleteItemBtn(item) {
        var index = this.maint_reports.findIndex(
          (i) => i.pk === item.pk,
        );
        alertPopup(this.$t("dialog.delete_confirm")).then((result) => {
          if (result && this.maint_reports.splice(index, 1)) {
            this.deleteItem(item.pk);
          }
        });
      },

      openLink(url) {
        window.open(url, "_blank");
      },
      openDialog(){
        // set producer id and verif_type
        this.editedItem.producer_id = this.producer_id
        this.editedItem.verif_type = this.reportType
        this.editedItem.done_at = new Date()
        this.report_file = []
        this.showAddReport = true
      },
      on_select_file() {
        this.editedItem.report = this.report_file;
      },
      genFile(path) {
        const infos = this.editedItem.report_infos;
        return new File([path], infos.name, { type: infos.type });
      },
      async validate () {
        const { valid } = await this.$refs.form.validate()
        return valid
      },
      async saveAddReportEvent() {
        const isValid = await this.validate();
        if (!isValid) return


        this.editedItem.done_at = this.editedItem.done_at.toJSON().substring(0,10)
        const report = this.editedItem?.report?.[0] || null;
        this.$store
          .dispatch(`${this.moduleName}/addItem`, this.editedItem)
          .then((item) => {
            if (report) {
              this.$store
                .dispatch(`${this.moduleName}/sendFile`, {
                  pk: item.pk,
                  file: report,
                  name: report.name,
                }).then(()=>{
                  this.loadList()
                  this.$store.dispatch(`${this.moduleName}/cleanupEdit`);
                })
            } else {
              this.loadList()
              this.$store.dispatch(`${this.moduleName}/cleanupEdit`);
            }
          })
              this.$refs.editDialogAddReport.closeDialog();
            },
      cleanupAddReportDialog() {
        this.showAddReport = false;
        this.$store.dispatch(`${this.moduleName}/cleanupEdit`);
      },
    },

    data() {
      return {
        moduleName: "verif_reports",
        maint_reports: [],
        showAddReport: false,
        report_file: [],
      };
    },
  };
</script>
