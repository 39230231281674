<template>
  <v-container ma-0 pa-0>
    <!-- First row with DatePicker -->
    <v-row>
      <v-col cols="12">
        <DatePicker
          :label="label"
          :value="dateValue"
          :showIcon="false"
          @input="handleDateChanges"
        />
      </v-col>
    </v-row>
    <!-- Second row with v-radio-group -->
    <v-row>
      <v-col cols="12">
        <v-radio-group
          v-model="timeValuePart"
          row
          @update:modelValue="handleTimeChanges"
        >
          <v-radio
            v-bind:label="$filters.capitalize($t('scheduler.datetime.morning', 1))"
            value="morning"
          />
          <v-radio
            v-bind:label="$filters.capitalize($t('scheduler.datetime.afternoon', 1))"
            value="afternoon"
          />
        </v-radio-group>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import moment from "moment";
import DatePicker from "../DatePicker.vue";
import { th } from "vuetify/locale";

export default {
  name: "DatePartPicker",

  props: {
    label: { type: String, required: true },
    value: { type: [String, Date], default: "" },
    isStart: { type: Boolean, default: true },
    moduleName: { type: String, default: 'datePart' },
  },

  components: {
    DatePicker
  },

  created(){
    const { date_, time_ } = this.extractDateAndTime(this.value);
    this.dateValue = date_;
    this.setTimeValue(this.value);
    this.combinedDateTime = this.value;
    this.setCombinedDateTime();
  },

  data() {
    return {
      timeValuePart: null,
      dateValue: null,
      combinedDateTime: null
    };
  },

  watch: {
    value(newVal) {
      if (!newVal || newVal.getTime() === this.combinedDateTime?.getTime()) {
        return;
      }
      const { date_, time_ } = this.extractDateAndTime(newVal);
      this.dateValue = date_;
      this.setTimeValue(newVal);
      this.setCombinedDateTime();
    },
  },


  methods: {
    extractDateAndTime(dateTimeObj) {
      if (!(dateTimeObj instanceof Date)) {
        console.error("bad type date");
        console.log(typeof(dateTimeObj));
        return { date_: null, time_: null };
      }
      const year = dateTimeObj.getFullYear();
      const month = dateTimeObj.getMonth() + 1; // getMonth() is zero-based
      const day = dateTimeObj.getDate();

      const monthPadded = month < 10 ? `0${month}` : month.toString();
      const dayPadded = day < 10 ? `0${day}` : day.toString();

      let date_ = `${year}-${monthPadded}-${dayPadded}`;

      const hours = dateTimeObj.getHours();
      const minutes = dateTimeObj.getMinutes();
      const seconds = dateTimeObj.getSeconds();

      const hoursPadded = hours < 10 ? `0${hours}` : hours.toString();
      const minutesPadded = minutes < 10 ? `0${minutes}` : minutes.toString();
      const secondsPadded = seconds < 10 ? `0${seconds}` : seconds.toString();

      let time_ = `${hoursPadded}:${minutesPadded}:${secondsPadded}`;

      return { date_, time_ };
    },

    handleDateChanges(val) {
      this.dateValue = val;
      this.handleChange();
    },

    handleTimeChanges(val) {
      this.timeValuePart = val;
      this.handleChange();
    },

    handleChange() {
      if (!this.dateValue || !this.timeValuePart) {
        console.error("Date or Time values are not set");
        return;
      }

      const newCombinedDateTime = this.createDate(this.dateValue, this.readTimeString(this.timeValuePart));

      if (this.combinedDateTime && newCombinedDateTime.getTime() === this.combinedDateTime.getTime()) {
        return;
      }

      this.combinedDateTime = newCombinedDateTime;
      this.$emit("inputDateTimePart", this.combinedDateTime);
    },

    setCombinedDateTime() {
      const _time = this.readTimeString(this.timeValuePart);
      const _date = this.dateValue;
      const dateTimeString = `${_date}T${_time}`;

      this.combinedDateTime = new Date(dateTimeString);

      if (isNaN(this.combinedDateTime.getTime())) {
        console.error("Invalid combined date and time");
        return;
      }
      this.$emit('inputDateTimePart', this.combinedDateTime);
    },

    createDate(date, time) {
      const dateTimeString = `${date}T${time}`;
      return new Date(dateTimeString);
    },

    setTimeValue(value) {
      //check 14 timezone !
      this.timeValuePart = value.getHours() <= 12 ? "morning" : "afternoon";
    },

    readTimeString(value) {
      var time = "";
      if (value === "morning") {
        if (this.isStart) {
          time = "00:00";
        } else {
          time = "12:00";
        }
      } else {
        if (this.isStart) {
          time = "12:00";
        } else {
          time = "23:59";
        }
      }
      return time;
    },
  },
};
</script>
