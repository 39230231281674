<template>
  <v-container class="mb-5 pa-3">
    <v-toolbar flat color="white" class="my-3 px-2" elevation="1">
      <v-tooltip
          location="top"
          :text="$filters.capitalize( $t(`${moduleName}.actions.back`) )">
        <template v-slot:activator="{ on }">
          <v-icon
              v-bind="on"
              class="mr-2"
              icon='mdi-arrow-left-drop-circle-outline'
              @click="backBtn()"
              />
        </template>
      </v-tooltip>
      <v-spacer style="flex-grow: 0;"></v-spacer>
      <v-toolbar-title 
        class="page_title"
        :text="$filters.capitalize($t(pageTitle, 1))"
        />
      <v-spacer style="flex-grow: 0;"></v-spacer>
      <v-btn
          color="success"
          :text='$filters.capitalize($t("save") )'
          @click="saveItem()"
          v-if="mdAndUp"
          />
      <v-icon
        v-else
          color='success'
          icon='mdi-content-save'
        @click="saveItem()"
        />
    </v-toolbar>

    <v-form ref='form'>
      <v-row>
        <v-col cols="6" md="4">
          <v-autocomplete
              v-model="editedItem.producer_id"
              :items="$store.state.producers.all"
              item-title="display_name"
              item-value="pk"
              :label="$filters.capitalize($t('producers.label', 1) )"
              :loading="$store.state.producers.isSearching"
              :filter="filterProducers"
              :custom-filter="customFilter"
              append-icon="mdi-text-box-search"
              :clearable="!disabledOnEdit"
              :disabled="disabledOnEdit || prefilled"
              :rules="[v => !!v || $filters.capitalize($t('generic.required') )]"
              variant="solo"
              return-object
              @update:modelValue="selectProducer"
              @click:clear="clearProducer"
              @click="checkProducerList"
              >
              <template v-slot:no-data>
                <v-list-item>
                  <v-list-item-title>
                    {{
                    $filters.capitalize($t("search_for", { noun: $t("producers.label", 1) })
                    )
                    }}
                  </v-list-item-title>
                </v-list-item>
              </template>
          </v-autocomplete>
        </v-col>
        <v-col cols="6" md="4">
          <DatePicker
              v-bind:label="'intervention_reports.date'"
              v-bind:value="editedItem.date"
              @input="updateDate"
              >
          </DatePicker>
        </v-col>
        <v-col cols="6" md="4">
          <v-select
              v-model="editedItem.status"
              :items="reportStatusItems"
              :rules="[v => !!v || $filters.capitalize($t('generic.required') )]"
              item-title="name"
              item-value="id"
              :label="$filters.capitalize($t('intervention_reports.status.label') )"
              variant="solo"
              >
          </v-select>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="5" md="2">
          <v-text-field
              v-model="editedItem.number"
              :label="$filters.capitalize($t('intervention_reports.number') )"
              ></v-text-field>
        </v-col>
        <v-col cols="7" md="5">
          <v-autocomplete
              v-model="editedItem.alarm_code_id"
              :items="$store.state.alarm_codes.search"
              :item-props="alarmProps"
              item-title="name"
              item-value="pk"
              :label="$filters.capitalize($t('alarm_codes.label', 1) )"
              v-model:search="autocompleteSearchAlarmCode"
              :loading="$store.state.alarm_codes.isSearching"
              append-icon="mdi-text-box-search"
              :disabled="!showItemEdit"
              :clearable="true"
              variant="solo"
              no-filter
              >
            <template v-slot:no-data>
              <v-list-item>
                <v-list-item-title>
                  {{
                  $filters.capitalize($t("search_for", { noun: $t("alarm_codes.label", 1) })
                  )
                  }}
                </v-list-item-title>
              </v-list-item>
            </template>
          </v-autocomplete>
        </v-col>

        <v-col cols="12" md="5">
          <v-autocomplete
              v-model="editedItem.partner_ids"
              :items="$store.state.users.all"
              item-title="name"
              item-value="id"
              :label="$filters.capitalize($t('intervention_reports.partner', 10) )"
              :custom-filter="customFilter"
              append-icon="mdi-text-box-search"
              clearable
              variant="solo"
              cache-items
              chips
              deletable-chips
              multiple
              return-object
              >
              <template v-slot:no-data>
                <v-list-item>
                  <v-list-item-title>
                    {{
                    $filters.capitalize($t("search_for", { noun: $t("users.label", 1) }) )
                    }}
                  </v-list-item-title>
                </v-list-item>
              </template>
          </v-autocomplete>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12" md="4">
          <v-textarea
              v-model="editedItem.cause"
              :disabled='prefilled'
              :label="$filters.capitalize($t('intervention_reports.cause') )"
              :row-height="18"
              :rules="[v => !!v || $filters.capitalize($t('generic.required') )]"
              append-outer-icon="mdi-chevron-right"
              auto-grow
              variant="solo"
              ></v-textarea>
        </v-col>
        <v-col cols="12" md="4">
          <v-textarea
              v-model="editedItem.action"
              :label="$filters.capitalize($t('intervention_reports.action') )"
              :row-height="18"
              :rules="[v => !!v || $filters.capitalize($t('generic.required') )]"
              append-outer-icon="mdi-chevron-right"
              auto-grow
              variant="solo"
              ></v-textarea>
        </v-col>
        <v-col cols="12" md="4">
          <v-textarea
              :disabled="disabledOnEdit"
              v-model="editedItem.todo"
              :label="$filters.capitalize($t('intervention_reports.todo') )"
              :row-height="18"
              auto-grow
              variant="solo"
              ></v-textarea>
          <v-checkbox
              :disabled="disabledOnEdit"
              color="red"
              v-model="editedItem.todo_is_risk"
              :label="$filters.capitalize($t(`risks.is_risk`) )"
              ></v-checkbox>
        </v-col>
      </v-row>

      <v-row class="text-center" v-show="!showItemEdit">
        <v-col
            class="service_message"
            v-show="['', null, undefined].includes(editedItem.producer_id)"
            >
            {{ $filters.capitalize($t("intervention_reports.edition.select_producer") ) }}
        </v-col>
      </v-row>
      <div v-show="showItemEdit">
        <ConsumedList
            />
        <AffectationList
            />
        <v-row class="d-flex justify-end pr-7 mt-2">
          <v-btn color="success" @click="saveItem()">{{
            $filters.capitalize($t("save") )
            }}</v-btn>
        </v-row>
      </div>
    </v-form>
  </v-container>
</template>

<script>
  import moment from "moment";
  import { useDisplay } from "vuetify";
  import DatePicker from "@/components/DatePicker";
  import ConsumedList from "@/components/intervention_report/ConsumedList";
  import AffectationList from "@/components/intervention_report/AffectationList";
  import { capitalize, isNone, customFilter } from "@/functions.js";
  import filters from "@/mixins/filters";
  import utils from "@/mixins/utils";
  import { alarmProps } from "@/mixins/props"

  export default {
    mixins: [utils, filters],

    components: {
      DatePicker: DatePicker,
      ConsumedList: ConsumedList,
      AffectationList: AffectationList,
    },

    props: {
      pk: { type: [Number, String], required: false, default: undefined },
      prefilled: { type: Boolean, required: false, default: false },
    },

    computed: {
      editedItem: function () {
        return this.$store.state[`${this.moduleName}`].edition;
      },
      isCreation: function () {
        return this.pk? false:true;
      },
      pageTitle: function () {
        if (this.isCreation) {
          return "intervention_reports.edition.creation_label";
        }
        return "intervention_reports.edition.edition_label";
      },
      disabledOnEdit: function () {
        return this.pk? true:false;
      }, // TODO: temporary
      minimalValue: function () {
        if (!this.isFromStaff) { return this.limit_15.format('YYYY-MM-DD') }
        return undefined;
      },
      mdAndUp() { return useDisplay().mdAndUp.value }
    },

    methods: {
      alarmProps,
      customFilter,
      async validate () {
        const { valid } = await this.$refs.form.validate()
        return valid
      },
      async saveItem() {
        const isValid = await this.validate();

        if (!isValid) {
          this.$store.dispatch(
            "snackbar/showWarning",
            this.$t('intervention_reports.edition.missing_information')
            )
          return
        }
        this.$store
          .dispatch(`${this.moduleName}/sendEdition`,
            { pk: this.pk, data: this.editedItem }
          ).then((new_rep) => {
            this.$emit('saved', new_rep) 
            if (this.prefilled) return
            this.$router.push({ 
              name: "intervention_reports",
              query: this.$route.query});
          });
      },
      backBtn() {
        this.user_id = parseInt(this.$route.query.user_id) || undefined;
        var query = {};
        if (!isNone(this.producer_id)) {
          query["producer_id"] = this.producer_id;
        }
        if (!isNone(this.user_id)) {
          query["user_id"] = this.user_id;
        }
        this.$store.dispatch('intervention_reports/cleanupEdition')
        this.$router.push({ name: "intervention_reports", query: query });
      },
      selectProducerById(producer_id){
        let all = this.$store.state.producers.all;
        let prod = all.find(p => p.pk == producer_id)
        this.selectProducer(prod);
      },
      selectProducer(producer) {
        if (!isNone(producer)) {
          this.editedItem.producer_id = producer.pk;
          this.editedItem.producer = producer;
          this.$store.dispatch('alarm_codes/setEmptySearch')

          this.showItemEdit = !isNone(producer.stockage_id);
          this.selectStockageById(producer.stockage_id);
          this.getAffectations('producer_id', producer.pk);
          this.getAffectations('stockage_id', producer.stockage_id);
        }
      },
      clearProducer(){
        this.$store.dispatch('alarm_codes/setEmptySearch')
        this.editedItem.stockage_id = undefined;
        this.$store.state.affectations.latest = [];
        this.showItemEdit = false;
      },
      checkProducerList() {
        if (this.$store.state.producers.all.length > 0) return
        this.$store.dispatch("producers/getAll");
      },
      selectStockageById(stockage_id){
        this.editedItem.stockage_id = stockage_id;
        let stock_obj = this.$store.state.stockages.all.find(
          st => st.pk == stockage_id
        );
        this.$store.dispatch('stockages/updateEdit', stock_obj);
        this.getStock();
      },
      getStock() {
        let options = {
          size: -1,
          stockage: this.editedItem.producer.stockage_id,
          producer_type: this.editedItem.producer.producer_type_id,
          lang: this.$store.getters.language,
          is_unit: false,
        };
        this.$store.dispatch("real_parts/getView", options)
      },
      getAffectations(queryType, queryValue){
        this.$store.dispatch('affectations/getLatest',
          {queryType: queryType, queryValue: queryValue})
      },
      updateDate(date_){
        if (date_ < this.minimalValue) {
          this.editedItem.date = new Date()
          this.$store.dispatch(
            "snackbar/showWarning",
            this.$t('intervention_reports.edition.minimal_date')
            )
        } else {
          this.editedItem.date = date_
        }
      }
    },

    created() {
      if (this.prefilled) {
        this.selectProducerById(this.editedItem.producer_id)
        return
      }
      if (this.isCreation) {
        this.$store.dispatch("intervention_reports/cleanupEdition")
        // Preload a certain producers
        let producer_id = parseInt(this.$route.query.producer_id) || undefined;
        this.selectProducerById(producer_id);
        this.$store.dispatch("users/setEmptySearch");
      } else {
        this.$store
          .dispatch("intervention_reports/getEdition", this.pk)
          .then((ir) => {
            if (!isNone(ir.alarm_code_id)) {
              this.$store.dispatch("alarm_codes/initSearch", {
                item_ids: ir.alarm_code_id,
              });
            }
            this.selectProducer(ir.producer)
            this.showItemEdit = !isNone(ir.producer.stockage_id);
          });
      }
      if (this.$store.state.users.all.length <= 0) this.$store.dispatch('users/getAll')
    },

    watch: {
      // second condition in each to avoid redundant request on selection,
      // open to suggestions on how to improve
      autocompleteSearchAlarmCode(val) {
        if (!this.$store.getters.canSearch("alarm_codes")) return;
        if (this.editedItem.alarm_code_id) return;
        this.delayedSearch(val, "alarm_codes/searchItems",{
          'value':val,
          'producer_type_id': this.editedItem.producer.producer_type_id,
        });
      },
    },

    data() {
      return {
        moduleName: "intervention_reports",
        autocompleteSearchAlarmCode: null,
        showItemEdit: false,
        showAddConsumedPartDialog: false,
        showEditConsumedPart: false,
        showAffectationDialog: false,
        showEditAffectation: false,
        limit_15: moment().startOf("day").subtract(15, "days"),
        reportStatusItems: [
          {
            name: capitalize(this.$t("intervention_reports.status.final")),
            id: "final",
          },
          {
            name: capitalize(this.$t("intervention_reports.status.tmp")),
            id: "tmp",
          },
          {
            name: capitalize(
              this.$t("intervention_reports.status.maintenance"),
            ),
            id: "maintenance",
          },
          {
            name: capitalize(this.$t("intervention_reports.status.none")),
            id: "none",
          },
        ],
      };
    },
  };
</script>

<style>
.option_line {
  min-height: 2em;
}

.v-select.v-input--is-disabled > .v-input__control > .v-input__slot {
  cursor: not-allowed;
}

.service_message {
  font-style: italic;
}

.page_title {
  font-weight: bold;
  font-size: 1.25rem;
  white-space: nowrap;
}
</style>
