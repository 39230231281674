<template>
    <div class="pa-4">
    <v-container>
      <v-table>
        <thead>
          <tr>
            <th class="text-left">Site</th>
            <th class="text-left">Progress</th>
            <th class="text-left">Total (MWh)</th>
            <th class="text-left">Objectif (MWh)</th>
            <th class="text-left">prix spot pondéré</th>

          </tr>
        </thead>
        <tbody>
          <tr v-for="(production, site) in hourly_productions_current" :key="site">
            <td>{{ site }}</td>
            <td class="w-50">
              <v-progress-linear
                :model-value="production.percent * 100"
                height="20"
                :color="production.percent * 100 > 100 ? 'red' : 'blue'"
                background-color="grey-lighten-2"
              ></v-progress-linear>
              <div class="text-caption mt-1">{{ (production.percent * 100).toFixed(2) }}%</div>
            </td>
            <td>
              {{ production.total !== null ? (production.total / 1000).toFixed(2) : 'N/A' }}
            </td>
            <td>
              {{ production.announced_production !== null ? production.announced_production.toFixed(2) : 'N/A' }}
            </td>
            <td>
              {{ production.announced_production !== null ? production.price.toFixed(2) : 'N/A' }} €/MWh
            </td>
          </tr>
        </tbody>
      </v-table>
    </v-container>
  </div>
</template>

<script>

export default {

  methods: {
    updateData() {
      this.$store
        .dispatch(`hourly_production/getCurrent`)
        .then((items) => {
          this.hourly_productions_current = items;
        });
    },
  },

  mounted() {
    this.updateData();
    this.$nextTick(function() {
      this.intervalId = window.setInterval(
        () => {
          this.updateData();
        },
        10 * 60 * 1000
      );
    });
  },

  unmounted() {
    window.clearInterval(this.intervalId);
  },

  data() {
    return {
      intervalId: undefined,
      hourly_productions_current: {},
    };
  }
};
</script>

<style scoped>
td.over_1h {
  color: #ebcc3d;
}

td.over_3h {
  color: #ca8109;
}

td.over_5h {
  color: #eb0a0a;
}
</style>
