<template>
  <v-table>
    <thead>
      <tr>
        <td>
          producer
        </td>
        <td>
         closed at
        </td>
        <td>
         closed by
        </td>
        <td>
         file
        </td>
        <td>
         validation
        </td>
      </tr>
    </thead>
    <tbody>
        <td>
          producer
        </td>
        <td>
         closed at
        </td>
        <td>
         closed by
        </td>
        <td>
         file
        </td>
        <td>
         (validate button XOR validated at + by) ?
        </td>
    </tbody>
  </v-table>
</template>

 <script>
import { defineComponent } from 'vue';
   export default defineComponent({
  data() {
    return {
    };
  }
   })
 </script>
