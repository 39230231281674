import crud from "@/services/crud";
import { isNone, slicyUpdate } from "@/functions";

// Generic crud store module
// Take api base path has parameter

export default function (path, struct) {
  return {
    namespaced: true,
    state: {
      items: [], // list of retrieved items
      local_items: [], // list of locally defined items
      search: [], // list of searched items
      pagination: {
        last_page: undefined,
        size: undefined,
        page_size: undefined,
        current: undefined,
        next: undefined,
        previous: undefined,
      }, // pagination informations
      isLoading: false, // data is currently updated
      inProgressCount: 0, // Number of ongoing API calls
      isSearching: false, // a search is in progress
      new_item: undefined, // inserted items
      struct: struct, // empty object
      edit: Object.assign({}, struct), // modified object
    },

    getters: {
      items: (state) => state.items, // usefull ?
      pk: (state) => state.edit.pk,
      canSearch: (state) => state.search.length <= 0 || !state.isSearching,
    },

    actions: {
      getItems({ commit }, options) {
        // options: page, size, ordering, ids, and specific ones...
        var payload = {};
        if (options) {
          if (!isNone(options.page)) {
            payload["page"] = options.page;
          }
          if (!isNone(options.size)) {
            payload["size"] = options.size;
          }
          if (!isNone(options.ordering)) {
            payload["ordering"] = options.ordering;
          }
          if (!isNone(options.ids)) {
            payload["id"] = options.ids;
          }
          if (!isNone(options.search)) {
            payload["search"] = options.search;
          }
          if (!isNone(options.stockage_id)) {
            payload["stockage_id"] = options.stockage_id;
          }
          if (!isNone(options.stock_part_id)) {
            payload["stock_part_id"] = options.stock_part_id;
          }
          if (!isNone(options.alarm_code_id)) {
            payload["alarm_code_id"] = options.alarm_code_id;
          }
          if (!isNone(options.producer_types)) {
            payload["producer_types"] = options.producer_types;
          }
          if (!isNone(options.producer_type_id)) {
            payload["producer_type_id"] = options.producer_type_id;
          }
          if (!isNone(options.power_station_id)) {
            payload["power_station_id"] = options.power_station_id;
          }
          if (!isNone(options.producer_id)) {
            payload["producer_id"] = options.producer_id;
          }
          if (!isNone(options.category_id)) {
            payload["category_id"] = options.category_id;
          }
          if (!isNone(options.user_id)) {
            payload["user_id"] = options.user_id;
          }
          if (!isNone(options.unit_id)) {
            payload["unit_id"] = options.unit_id;
          }
          if (!isNone(options.criticality)) {
            payload["criticality"] = options.criticality;
          }
          if (!isNone(options.level)) {
            payload["level"] = options.level;
          }
          if (!isNone(options.start)) {
            payload["start"] = options.start;
          }
          if (!isNone(options.end)) {
            payload["end"] = options.end;
          }
          if (!isNone(options.closed)) {
            payload["closed"] = options.closed;
          }
          if (!isNone(options.unity)) {
            payload["unity"] = options.unity;
          }
          if (!isNone(options.is_unit)) {
            payload["is_unit"] = options.is_unit;
          }
          if (!isNone(options.include_generic)) {
            payload["include_generic"] = options.include_generic;
          }
          if (!isNone(options.place_id)) {
            payload["place_id"] = options.place_id;
          }
          if (!isNone(options.spot_price)) {
            payload["spot_price"] = options.spot_price;
          }
          if (!isNone(options.origin_options)) {
            payload["origin_options"] = options.origin_options;
          }
          if (!isNone(options.qr_code)) {
            payload["qr_code"] = options.qr_code;
          }
          if (!isNone(options.obsolete)) {
            payload["include_obsolete"] = options.obsolete;
          }
          if (!isNone(options.verif_type)) {
            payload["verif_type"] = options.verif_type;
          }
          if (!isNone(options.verif_id)) {
            payload["verif_id"] = options.verif_id;
          }
          // THIS IS KIND OF CHEATING, but it makes life easy and the front not as buggy or heavy
          if (!isNone(options.value)) {
            payload["search"] = options.value;
          }
        }
        commit("setIsLoading", true);

        return new Promise((resolve, reject) => {
          crud
            .fetch(path, payload)
            .then((items) => {
              commit("setItems", items.results);
              commit("setPagination", items);
              commit("setIsLoading", false);
              resolve(items.results);
            })
            .catch(() => {
              commit("setIsLoading", false);
              reject();
            });
        });
      },
      searchItems(
        { commit },
        {
          value,
          action,
          page,
          size,
          category_id,
          stockage_id,
          producer_id,
          producer_types,
          producer_type_id,
          unity,
          curve_type,
          path_,
          with_alarm_compatible,
          is_unit,
          regions
        },
      ) {
        // Get all items corresponding to a search pattern
        commit("setIsSearching", true);
        let payload = {
          search: value,
          size: 50,
        };
        if (isNone(action)) {
          action = "setSearch";
        }
        if (!isNone(size)) {
          if(size == false ){
            delete payload["size"];
          }else {
            payload["size"] = size;
          }
        }
        if (!isNone(page)) {
          payoad['page'] = page;
        }
        if (!isNone(stockage_id)) {
          payload["stockage_id"] = stockage_id;
        }
        if (!isNone(producer_types)) {
          payload["producer_types"] = producer_types;
        }
        if (!isNone(producer_type_id)) {
          payload["producer_type_id"] = producer_type_id;
        }
        if (!isNone(producer_id)) {
          payload["producer_id"] = producer_id;
        }
        if (!isNone(category_id)) {
          payload["category_id"] = category_id;
        }
        if (!isNone(unity)) {
          payload["unity"] = unity;
        }
        if (!isNone(curve_type)) {
          payload["curve_type"] = curve_type;
        }
        if (!isNone(with_alarm_compatible)) {
          payload["with_alarm_compatible"] = with_alarm_compatible;
        }
        if (!isNone(is_unit)) {
          payload["is_unit"] = is_unit;
        }        
        if (!isNone(regions)) {
          payload["regions"] = regions;
        }

        const final_path = path_ === undefined ? path : path_;
        return new Promise((resolve, reject) => {
          crud
            .fetch(final_path, payload)
            .then((items) => {
              commit(action, items.results);
              commit("setIsSearching", false);
              resolve(items.results);
            })
            .catch(() => {
              commit("setIsSearching", false);
              reject();
            });
        });
      },
      initSearch({ commit }, { item_ids, action, path_ }) {
        // Init the search field
        if (isNone(action)) {
          action = "setSearch";
        }
        if (item_ids === undefined) {
          console.error(`missing ids in ${path}`);
          return {};
        }
        commit("setIsSearching", true);
        var final_path = path_ === undefined ? path : path_;
        var payload = {};
        if (Array.isArray(item_ids)) {
          payload = { id: item_ids };
        } else {
          final_path = `${final_path}/${item_ids}`;
        }

        return new Promise((resolve, reject) => {
          crud
            .fetch(final_path, payload)
            .then((item) => {
              var array_ = [item];
              if (item.results !== undefined) {
                // ie. Array.isArray(item_ids)
                array_ = item.results;
              }
              commit(action, array_);
              commit("setIsSearching", false);
              resolve(item);
            })
            .catch(() => {
              commit("setIsSearching", false);
              reject();
            });
        });
      },
      setSearch({ commit }, item) {
        commit("setSearch", [item]);
      },
      setEmptySearch({ commit }) {
        commit("setSearch", []);
      },
      setItems({ commit }, items) {
        commit("setItems", items);
      },
      getItem({ commit }, pk) {
        // Get a single item (for edition)
        return new Promise((resolve) => {
          crud.fetch(`${path}/${pk}`).then((response) => {
            commit("updateEdit", response);
            resolve(response);
          });
        });
      },
      addItem({ commit }, item) {
        // Create a new item
        return new Promise((resolve, reject) => {
          crud.post(path, item)
            .then((response) => {
              commit("updateEdit", response);
              commit("addItem", response);
              resolve(response);
            })
            .catch((error) => {
              // Check if the error is a 400 Bad Request
              if (error.response && error.response.status === 400) {
                // Handle the 400 Bad Request error
                console.error('Bad Request error:', error.response.data);
                reject(new Error('Bad Request: ' + error.response.data));
              } else {
                // Handle other errors
                console.error('An error occurred while adding the item:', error);
                reject(error);
              }
            });
        });
      },
      
      
      addLocalItem({ commit }, item) {
        // Add a full item (for ex already retreive from the api)
        commit("addLocalItem", item);
      },
      editItem({ commit }, item) {
        // Update an existing item
        return new Promise((resolve) => {
          crud.put(path, item).then((response) => {
            commit("updateEdit", response);
            commit("editItem", response);
            resolve(response);
          });
        });
      },
      deleteItem({ commit }, msgId) {
        // Delete an existing item
        crud.delete(path, msgId).then(()=>{
        commit("deleteItem", msgId);
        })
      },
      updateEdit({ commit }, item) {
        commit("updateEdit", item);
      },
      cleanupItems({ commit }) {
        // Empty items cache
        commit("cleanupItems");
      },
      cleanupEdit({ commit, state }) {
        commit("updateEdit", state.struct);
      },
      patch({ commit }, { id_, payload }) {
        // Patch an existing item
        return new Promise((resolve) => {
          crud.patch(path, id_, payload).then((response) => {
            commit("updateEdit", response);
            commit("editItem", response);
            resolve();
          });
        });
      },

      sendFile({ commit }, { pk, file, name }) {
        commit("setIsLoading", true);
        const name_ = name.replace("’", "'");

        return new Promise((resolve, reject) => {
          crud
            .put_file(`${path}/${pk}/file`, file, name_)
            .then((resp) => {
              commit("setIsLoading", false);
              resolve(resp);
            })
            .catch((error) => {
              commit("setIsLoading", false);
              reject(error);
            });
        });
      },

      deleteFile({ commit }, pk) {
        commit("nothing");
        return new Promise((resolve) => {
          crud.delete_without_id(`${path}/${pk}/file`).then((resp) => {
            resolve(resp);
          });
        });
      },

      setPaginationSize({ commit }, size) {
        commit("setPaginationSize", size);
      },
    },

    mutations: {
      nothing() {},
      setItems(state, items) {
        if (!Array.isArray(items)) {
          console.error("setItems mutation: 'items' is not an array", items, path);
          items = [];
        }
        state.items = items.concat(state.local_items);
      },
      setSearch(state, items) {
        state.search.splice(0, state.search.length, ...items);
        // slice is needed to force update (while emptying for example)
      },
      setIsLoading(state, loading) {
        if (loading) {
          state.inProgressCount++;
        } else {
          state.inProgressCount--;
        }
        if (state.inProgressCount === 0) state.isLoading = false
        else state.isLoading = true
      },
      setIsSearching(state, searching) {
        state.isSearching = searching;
      },
      setPagination(state, items) {
        if (items) {
          state.pagination = {
            last_page: items.last_page,
            size: items.size,
            page_size: items.page_size,
            current: items.current,
            next: items.next,
            previous: items.previous,
          };
        }
      },
      setPaginationSize(state, size) {
        state.pagination.size = size;
      },
      addItem(state, item) {
        state.new_item = item;
        state.items.push(item);
      },
      addLocalItem(state, item) {
        state.local_items.push(item);
      },
      editItem(state, item) {
        slicyUpdate(state.items, item);
      },
      deleteItem(state, msgId) {
        state.items = state.items.filter((obj) => obj.pk !== msgId);
      },
      cleanupItems(state) {
        state.items = [];
      },
      updateEdit(state, item) {
        state.edit = Object.assign({}, item);
      },
    },
  };
}
