<template>
  <v-container fluid class="ma-0 pa-0 bg-blue-lighten-5">
    <v-row dense>
      <v-col cols="5">
        <v-btn
            prepend-icon="mdi-checkbox-multiple-marked-outline"
            @click="toggleState"
            :text="xs? null : currentStateText"
            :style="btnStyle"
            />
      </v-col>
      <v-col cols="5">
        <span
            v-show="areItemsSelected"
          >
        <v-btn
            @click="$emit('addItems')"
            v-show="currentState=='selectionMultiple'"
            :text="xs? null : $t('actions.send_multiple')"
            prepend-icon="mdi-playlist-check"
            />
        <v-btn
            @click="$emit('addItems')"
            v-show="currentState=='editionMultiple'"
            :text="xs? null : $t('actions.edit_multiple')"
            prepend-icon="mdi-playlist-check"
            />
        </span>
      </v-col>
    </v-row>
    <v-row dense justify="start" class="bordered-row">
      <v-col xs="3" md="5">
        <ReusableDialog
            :value='$t("todos.actions.add_item")'
            :withSave='true'
            @save="saveTodo"
            v-bind:iconXS='"mdi-clipboard-check-multiple"'
            @click='openTodo'
            >
            <template v-slot:content>
              <v-card
                  flat
                  :title='$filters.capitalize($t("todos.actions.add_item"))'
                  />
                <TodoForm
                    :askProducer='false'
                    :askInterventionReport='false'
                    :askFullfilledAt='false'
                    ></TodoForm>
            </template>
        </ReusableDialog>
      </v-col>
      <v-col xs="3" md="5">
        <ReusableDialog
            ref='intRepButton'
            v-bind:value='$t("verif_machines.actions.add_intervention_report")'
            v-bind:iconXS='"mdi-clipboard-file-outline"'
            v-bind:disableBtn='disableAddReport'
            @click = "openIntRep"
            >
            <template v-slot:content>
              <InterventionReportForVerif
                  :verif = "verifWithoutReport"
                  @saved = "closeIntRep"
                  >
              </InterventionReportForVerif>
            </template>
        </ReusableDialog>
      </v-col>
      <v-col xs="3" md="5" v-show='$store.getters.isUserFromStaff'>
        <v-btn
            @click='requestClosureForYear'
            size='small'
            variant='flat'
            :text="xs? null : $t('verif_machines.actions.close_for_year')"
            :prepend-icon='"mdi-text-box-check-outline"'
            ></v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
  import { useDisplay } from "vuetify";
  import { isNone, alertPopup } from "@/functions";
  import i18n from "@/i18n";
  import ReusableDialog from "@/components/ReusableDialog";
  import TodoForm from '@/components/TodoForm';
  import InterventionReportForVerif from '@/components/maintenance/InterventionReportForVerif';

  export default {
    components: {
      ReusableDialog,
      TodoForm,
      InterventionReportForVerif,
    },
    props: {
      verifWithoutReport: {},
      areItemsSelected: {},
      producer_id: {},
      someDone: {}
    },
    computed: {
      disableAddReport() {
        return this.verifWithoutReport.length <= 0
      },
      btnStyle() {
        switch (this.currentState) {
          case 'selectionMultiple':
            return {
              backgroundColor: 'blue',
              color: 'white',
            };
          case 'editionMultiple':
            return {
              backgroundColor: 'green',
              color: 'white',
            };
          case 'normal':
          default:
            return {
              backgroundColor: null,
              color: null,
            };
        }
      },
    xs() {
      return useDisplay().xs.value
    },
    currentStateText() {
      switch (this.currentState) {
        case 'normal':
          return this.$t('actions.switch.selection_multiple');
        case 'selectionMultiple':
          if (this.someDone) {
          return this.$t('actions.switch.edition_multiple');
          } else {
          return this.$t('actions.switch.normal');
          }
        case 'editionMultiple':
          return this.$t('actions.switch.normal');
        default:
          return 'Unknown State';
      }
    },
  },
    methods: {
      toggleState() {
        switch (this.currentState) {
          case 'normal':
            this.currentState = 'selectionMultiple';
            break;
          case 'selectionMultiple':
            if (this.someDone) {
                this.currentState = 'editionMultiple';
            } else {
                this.currentState = 'normal';
            }
            break;
          case 'editionMultiple':
                this.currentState = 'normal';
            break;
          default:
            this.currentState = 'normal';
        }
        this.$emit('select_multiple', this.currentState);
      },
        openTodo() {
          let prefilled = this.$store.state.todos.struct
          prefilled.assigned_to = this.$store.getters.user
          prefilled.assigned_to_id = this.$store.getters.user.id
          prefilled.producer_id = this.producer_id
          prefilled.producer = this.$store.state.producers.all.find(a => a.pk==this.producer_id)
          this.$store.dispatch("todos/updateEdit", prefilled)
        },
      saveTodo(){
        this.$emit('saveTodo');
      },
        closeIntRep() {
          this.$refs.intRepButton.handleClose();
          this.$store.dispatch('intervention_reports/cleanupEdition');
          this.$emit('reloadVerifDone');
        },
        openIntRep() {
          // to avoid overwriting edition on every conso/aff click
          if (this.$store.state.intervention_reports.edition.cause) return
          let prefilled = this.$store.state.intervention_reports.struct
          prefilled.consumables = [];
          prefilled.affectations = [];
          prefilled.producer_id = this.producer_id
          prefilled.producer = this.$store.state.producers.all.find(a => a.pk==this.producer_id)
          prefilled.status = 'maintenance'
          prefilled.partner_ids = [...new Set(this.verifWithoutReport.flatMap(p => p.last_done_by_ids))]
          prefilled.action = 'Maintenance Annuelle'
          prefilled.cause = this.verifWithoutReport.map(p => '- ' +p.category_name+": " + p.verification_title + '\n')
          prefilled.cause = prefilled.cause.join(' ')
          this.$store.dispatch("intervention_reports/updateEdition", prefilled)
        },
        requestClosureForYear() {
          alertPopup(this.$t("verif_reports.close_confirm")).then((result) => {
            if (result) {
              this.$store.dispatch("verif_reports/closeForYear", this.producer_id).then(()=>{
                this.$emit('reloadVerifDone');
                this.$emit('close')
              })
            }
          })
        },
    },
    data() {
      return {
        currentState: 'normal',
      }
    }
}
</script>
<style>
.bordered-row {
  border-top: 1px dashed #000000;  /* Dashed blue top border */
  border-bottom: 1px solid #000000;  /* Solid green bottom border */
}
</style>
