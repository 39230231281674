<template>
  <v-dialog
      v-model="isActive"
      :fullscreen="xs"
      @click:outside="handleClose"
      >
      <template v-slot:activator="{ props: activatorProps }">
        <v-btn
            @click="handleClick"
            v-bind="activatorProps"
            :color="btnColor(value)"
            :text="frmttdText(value)"
            variant="flat"
            ></v-btn>
      </template>

    <template v-slot:default>
      <v-card>
          <slot
              name="content"
              v-bind='$props'
              />
            <v-btn class='super_footer' :text="$t('close')" @click="handleClose"></v-btn>
      </v-card>
    </template>
  </v-dialog>
</template>

<script>
  import { useDisplay } from "vuetify";
  import { isNone } from "@/functions.js";

  export default {
    props: {
      value: {
        type: String,
        required: false,
      },
      limit_orange: {type: Number, required: false, default: 6},
      limit_red: {type: Number, required: false, default: 12},
    },
    computed: {
      xs() {
        return useDisplay().xs.value
      },
      limit_red_date() {
        let ref = new Date()
        ref.setMonth(ref.getMonth() - this.limit_red)
        return ref
      },
      limit_orange_date() {
        let ref = new Date()
        ref.setMonth(ref.getMonth() - this.limit_orange)
        return ref
      },
    },
    data() {
      return {
        isActive: false
      };
    },
    methods: {
      frmttdText (value) {
        if (isNone(value)) return value
        return this.$filters.formatDate(value.substring(0,10));
      },
      btnColor (item){
        if (item == undefined || item =='') return 'grey'
        let refDate = new Date(item.substring(0,10))
        if (refDate <= this.limit_red_date) return 'red'
        if (refDate <= this.limit_orange_date) return 'orange'
        return 'green'
      },
      handleClick(){
        this.$emit('click');
      },
      handleClose(){
        this.isActive = false;
        this.$emit('close');
      },
    },
  };
</script>
<style>
.super_footer {
  flex-shrink: 0;
}
</style>
