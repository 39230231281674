<template>
  <v-card>
  <v-card-text>
        <v-row>
          <v-col
            v-for="header in headers"
            :key="header.key"
            cols="6" md="3"
          >
            <v-sheet class="pa-4" outlined>
              <v-row v-if="header.title!='content'">
                <v-col cols="12">
                  <strong>{{ header.title }}:</strong>
                </v-col>
                <v-col cols="12">
                  <span>{{ purchasesEdit[header.key] }}</span>
                </v-col>
              </v-row>
              <v-row v-if="header.title=='content'">
                <v-col cols="12">
                  <strong>{{ header.title }}:</strong>
                </v-col>
          <ul>
            <li v-for="line in purchasesEdit['lines']" v-bind:key="line.pk">
              <span
                v-if="
                  ($store.getters.language === 'en' &&
                    line.stock_part.name_en !== '') ||
                  ($store.getters.language === 'fr' &&
                    line.stock_part.name === '')
                "
              >
                {{ line.quantity }}
                {{$filters.unityFilter( line.stock_part.unity ) }} &times;
                {{$filters.capitalize( line.stock_part.name_en ) }} &ndash;
                {{$filters.capitalize( line.stock_part.ref ) }}
              </span>
              <span v-else>
                {{ line.quantity }}
                {{$filters.unityFilter( line.stock_part.unity ) }} &times;
                {{$filters.capitalize( line.stock_part.name ) }} &ndash;
                {{$filters.capitalize( line.stock_part.ref ) }}
              </span>
            </li>
          </ul>
              </v-row>
            </v-sheet>
          </v-col>
        </v-row>
      </v-card-text>
  </v-card>
</template>
<script>
  export default {
    computed: {
      purchasesEdit() {
        return this.$store.state.purchases.edit
      },
    },
    data() {
      return {
      headers: [
        { title: this.$t("purchases.comment"), key: "comment" },
        // { title: this.$t("stockages.label"), key: "stockage.address" },
        { title: this.$t("purchases.ordered_at"), key: "ordered_at" },
        { title: this.$t("purchases.received_at"), key: "received_at" },
        { title: this.$t("purchases.lines"), key: "lines" },
      ],
      }
    }
  }
</script>
