<template>
  <v-dialog v-model="showSelectPendingRestDialog" max-width="600px">
    <v-card>
      <v-card-title class="headline">
        {{ $t("scheduler.selected_rest_period") }}
      </v-card-title>

      <v-card-text>
        <div class="mb-4">
          <strong>{{ $t("ID") }} : </strong> {{ item.id }}<br>
          <strong>{{ $t("name") }} : </strong>{{ item.name }}<br>
          <strong>{{ $t("scheduler.duration_rest") }} : </strong> {{ item.time_remaining }} hours<br>
          <strong>{{ $t("scheduler.start") }} : </strong> {{ formatDateTime(item.start) }}<br>
          <strong>{{ $t("scheduler.end") }} : </strong> {{ formatDateTime(item.end) }}<br>
          <strong>{{ $t("scheduler.remote") }} : </strong> {{ item.is_remote }}<br>
          <strong>{{ $t("user") }} : </strong> {{ item.user.name }}
        </div>

        <v-row>
          <v-col>
            <DatePartPicker
              :value="start"
              :label="'scheduler.start'"
              :isStart="true"
              :isPart="true"
              @inputDateTimePart="updateStart"
            />
          </v-col>
          <v-col>
            <DatePartPicker
              :value="end"
              :label="'scheduler.end'"
              :isStart="false"
              :isPart="true"
              @inputDateTimePart="updateEnd"
            />
          </v-col>
        </v-row>

        <div v-if="errorMessage" class="text-error">{{ errorMessage }}</div>
      </v-card-text>

      <v-divider></v-divider>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn text @click="closeDialog">{{ $t('Cancel') }}</v-btn>
        <v-btn text color="primary" @click="saveSelection">{{ $t('Save') }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import DatePartPicker from "@/components/scheduler/DatePartPicker";
import { formatDateTime } from "@/functions.js";

export default {
  name: "SelectPendingRest",
  components: { DatePartPicker },
  props: {
    value: Boolean,
    item: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      showSelectPendingRestDialog: this.value,
      start: new Date(),
      end: new Date(),
      errorMessage: null,
    };
  },
  watch: {
    value(newValue) {
      this.showSelectPendingRestDialog = newValue;
    },
    showSelectPendingRestDialog(newValue) {
      this.$emit("input", newValue);
      if (newValue) {
        this.resetForm();
      }
    }
  },
  methods: {
    formatDateTime,
    updateStart(newStart) {
      if (newStart &&
          (this.start.getFullYear() !== newStart.getFullYear() ||
          this.start.getMonth() !== newStart.getMonth() ||
          this.start.getDate() !== newStart.getDate())
      ) {
        this.start = newStart;
        this.end = newStart;
      }else if(newStart && this.start.getTime() !== newStart.getTime()){
        this.start = newStart;
      }
    },
    updateEnd(newEnd) {
        if(newEnd){
          this.end = newEnd;
        }
    },
    resetForm() {
      this.start = new Date();
      this.end = new Date();
      this.errorMessage = null;
    },
    closeDialog() {
      this.showSelectPendingRestDialog = false;
      this.$emit("close");
    },
    convertToUTC(date) {
      const localDate = new Date(date);
      return new Date(localDate.getTime() - localDate.getTimezoneOffset() * 60000).toISOString();
    },
    saveSelection() {
      if (!this.start || !this.end) {
        this.errorMessage = this.$t("Please fill out all fields");
        return;
      }
      if (this.start >= this.end) {
        this.errorMessage = this.$t("Start time must be before end time");
        return;
      }

      const itemCopy = { ...this.item };
      itemCopy.start = this.convertToUTC(this.start);
      itemCopy.end = this.convertToUTC(this.end);
      this.$store.dispatch("appointments/addPendingRest", itemCopy)
        .then(() => {
          this.$emit("saved");
          this.showSelectPendingRestDialog = false;
          this.$emit("close");
        })
        .catch((error) => {
          console.error("Failed to save pending rest:", error);
          this.errorMessage = error.error;
        });
    }
  }
};
</script>

<style scoped>
.mb-4 {
  margin-bottom: 16px;
}
.text-error {
  color: red;
}
</style>
